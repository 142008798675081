import { Card, CardContent } from "@mui/material";
import CardTitle from "../CardTitle/CardTitle";
import {
  CustomLinearProgressForCompanyAverage,
  CustomLinearProgressForHighestValue,
} from "../../ApplicantsDashboard/widgets";
import "../ApplicantsNewDashboard.css";
import React from "react";

// WidgetCard Component
const InformationWidgetCard = ({
  card,
  editMode,
  cardTitleChangeId,
  handleSaveCardTitle,
  handleDeleteCard,
  handleEditCard,
  handleCardTitle,
}) => {
  const data = card.data;
  const getImageUrl = (type) => {
    switch (type) {
      case "resumecontributed":
      case "resume":
        return `${process.env.PUBLIC_URL + "/assests/TotalContributed.svg"}`;
      case "submission":
        return `${process.env.PUBLIC_URL + "/assests/TotalSubmissions.svg"}`;
      case "interview":
        return `${process.env.PUBLIC_URL + "/assests/TotalInterview.svg"}`;
      case "selected":
        return `${process.env.PUBLIC_URL + "/assests/TotalSelected.svg"}`;
      default:
        return card?.config?.defaultImg;
    }
  };

  return (
    <Card className="dashboard__card">
      <CardTitle
        card={card}
        editMode={editMode}
        handleSaveCardTitle={handleSaveCardTitle}
        handleDeleteCard={handleDeleteCard}
        handleEditCard={handleEditCard}
        handleCardTitle={handleCardTitle}
        cardTitleChangeId={cardTitleChangeId}
      />
      <CardContent style={{ position: "relative" }}>
        <div className="dashboard__card-content">
          <div className="dashboard__card-row">
            <h1 className="dashboard__card-contribution-value">
              {data?.currentValue || 0}
            </h1>
            <img
              className="dashboard__card-contribution-img"
              src={getImageUrl(card.type)}
              alt={card?.title}
            />
          </div>
          <p className="dashboard__card-labels dashboard__card-my-contribution">
            {card?.config?.currentTitle}
          </p>
          <div className="progressbarSection">
            <p className="dashboard__card-labels">{card?.config?.avgTitle}</p>
            <section className="dashboard__card-row">
              <div className="dashboard__card-progress-bar">
                <CustomLinearProgressForCompanyAverage
                  id={`avg-value-${card?.type}`}
                  variant="determinate"
                  value={data?.avgPercentage || 0}
                />
              </div>
              <h3>{data?.avgValue || 0}</h3>
            </section>
          </div>
          <div className="progressbarSection">
            <p className="dashboard__card-labels">
              {card?.config?.highestTitle}
            </p>
            <section className="dashboard__card-row">
              <div className="dashboard__card-progress-bar">
                <CustomLinearProgressForHighestValue
                  id={`highest-value-${card?.type}`}
                  variant="determinate"
                  value={data?.highestValue > 0 ? 100 : 0}
                />
              </div>
              <h3>{data?.highestValue || 0}</h3>
            </section>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default React.memo(InformationWidgetCard);
