import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { countries } from "../../API";
import { useDispatch } from "react-redux";
import { updateSnackbar } from "../../_redux/SnackbarSlice";
import './CustomerView/CustomerView.css'



const CustomerComponent = ({
  componentType,
  input,
  iniValues,
  iniValue,
  actions,
  onValidData,
  disableSubmitBtn
}) => {
  const [countriesList, setCountriesList] = useState([]);
  const [customers, setCustomers] = useState(initialDetails);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [isDomain, setIsDomain] = useState(false);
  const [domainList, setDomainList] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(disableSubmitBtn);

  const onRemoveHandler = (item, index) => {
    let details = JSON.parse(JSON.stringify(domainList));
    details.splice(index, 1);
    setDomainList(details);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    let userData = JSON.parse(JSON.stringify(customers));
    formik.values[name] = value;
    userData[name] = value;
    if (name === "portal") {
      let isCloud = value.includes("3");
      let isEmployee = value.includes("4");
      if (!isCloud) {
        userData.flatFee = "";
        userData.paymentperHire = "";
        delete userData["employeeportal_domains"];
        formik.values.flatFee = "";
        formik.values.paymentperHire = "";
      }
      if (!isEmployee) {
        delete userData["employeeportal_domains"];
        setIsDomain(false)
      } else if (isEmployee) {
        setIsDomain(domainList && domainList.length > 0 ? false : true);
        userData["employeeportal_domains"] = '';
      }
      console.log(userData);
    }
    setCustomers(userData);
  };

  const applicationNames = [
    { id: "1", name: "ATS", },
    { id: "2", name: "Vendor Portal", },
    { id: "3", name: "Cloud Sourcing", },
    { id: "4", name: "Employee Portal", }
    // { id: "4", name: "Interview Portal", },
    // { id: "5", name: "Resource Management", },
  ]

  useEffect(() => {
    if (input !== undefined && countriesList.length > 0) {
      let inputObj = JSON.parse(JSON.stringify(input));
      formik.values.companyName = inputObj.companyName;
      formik.values.registerdAddress = inputObj.registerdAddress;
      formik.values.website = inputObj.website;
      formik.values.contactDetails = inputObj.contactDetails;
      formik.values.contactPersonName = inputObj.contactPersonName;
      formik.values.onBoardingDate = inputObj.onBoardingDate.toString();
      formik.values.portal = inputObj.portal;
      formik.values.country = inputObj.country;
      formik.values.flatFee = inputObj.flatFee;
      formik.values.employeeportal_domains = '';
      formik.values.flatFeeCountryId = parseInt(inputObj.flatFeeCountryId);
      formik.values.paymentperHire = inputObj.flatFee ? "Flat Fee" : "";
      inputObj['paymentperHire'] = inputObj.flatFee ? "Flat Fee" : "";
      setDomainList(inputObj.employeeportal_domains ? inputObj.employeeportal_domains : []);  //TODO
      setSelectedCurrency(parseInt(inputObj.flatFeeCountryId));
      inputObj.employeeportal_domains = '';
      setCustomers(inputObj);
    } else {
      formik.values.onBoardingDate = new Date().toLocaleDateString();
      let obj = JSON.parse(JSON.stringify(customers));
      obj.onBoardingDate = new Date().toLocaleDateString();
      setCustomers(obj);
    }
    // eslint-disable-next-line
  }, [input, countriesList]);

  useEffect(() => {
    async function fetchCountiesList() {
      const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}${countries}`);
      const data = await res.json();
      let baseref = window.location.href.split(window.location.pathname);
      const ListofCountries = data.client;
      ListofCountries.forEach((x) => {
        x["name"] = x.countryName;
        x["id"] = x.id.toString();
        x["Id"] = parseInt(x.id);
        x["image"] = `${baseref[0]}${process.env.PUBLIC_URL}/assests/img/${x.image}`
        x["currencyName"] = x.countryName === "Canada" ? "CAD" : x.countryName === "India" ? "IND" : x.countryName === "USA" ? "USD" : x.countryName === "Malaysia" ? "MYR" : x.countryName === "Singapore" ? "SGD" : x.countryName === "UAE" ? "UAE" : "";
      });
      setCountriesList(ListofCountries);
      if (componentType === "Create") {
        setSelectedCurrency(parseInt(ListofCountries[0].Id));
      }
    }
    fetchCountiesList();
  }, []);

  const onChangeHandler = (value, type) => {
    setDisableBtn(false);
    let obj = JSON.parse(JSON.stringify(customers));
    if (type === "onBoardingDate") {
      formik.values[type] = new Date(value).toLocaleDateString();
      obj[type] = new Date(value).toLocaleDateString();
    }
    else if (type === "flatFee") {
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        obj[type] = value.trimStart();
        formik.values[type] = value.trimStart();
      }
    }
    else if (type === "contactDetails") {
      const regex = /^[0-9()+-\s\b]*$/;
      if (regex.test(value)) {
        obj[type] = value.trimStart();
        formik.values[type] = value.trimStart();
      }
    }
    else if (type === "website") {
      value = value.toLowerCase();
      obj[type] = value.toLowerCase();
      formik.values[type] = value.trimStart();
    }
    else {
      formik.values[type] = value.trimStart();
      obj[type] = value.trimStart();
    }
    setCustomers(obj);
  };

  const phoneRegExp =
    // /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    //todo: working
    // /(?:(?:(\s*\(?([0-9]1[0-9]|[0-9][0-9]1|[0-9][0-9][0-9])\s*)|([0-9]1[0-9]|[0-9][0-9]1|[0-9][0-9][0-9]))\)?\s*(?:[.-]\s*)?)([0-9]1[0-9]|[0-9][0-9]1|[0-9][0-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/
    // /(?:(?:(\s*\(?([1-9]1[01-9]|[1-9][01-9]1|[1-9][01-9][01-9])\s*)|([1-9]1[01-9]|[1-9][01-9]1|[1-9][01-9][01-9]))\)?\s*(?:[.-]\s*)?)([1-9]1[01-9]|[1-9][01-9]1|[1-9][01-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/
    // /^[0-9 ( ) - - +\b]+$/
    // /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    /^[0-9()+-\s\b]*$/

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .min(2, "Must be min 2 characters")
      .max(50, "Must be less than 50")
      .required("Please enter company name"),
    registerdAddress: Yup.string()
      .min(20, "Must be min 20 characters")
      .max(300, "Must be less than 300 ")
      .required("Please provide the registered address"),
    website: Yup.string().matches(
      // /((https):\/\/)?(www.)[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      /^((ftp|http|https):\/\/)?www\.([A-z0-9]+)\.([A-z0-9]{2,})/,

      // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/,
      "Invalid website format"
    )
      .min(5, "Must be 5 characters")
      .max(50, "Must be less than 50 ")
      .required("Please provide valid website"),
    employeeportal_domains: Yup.string().matches(/^((?!-)(\*\.)?[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z0-9-]{1,63}/, "invalid domain")
      .min(5, "Must be 5 characters")
      .max(50, "Must be less than 50 "),
    contactDetails: Yup.string()
      .required("Please enter contact number ")
      .min(10, "Contact number should be at least 10 characters")
      .max(20, "Contact number should be at most 20 characters")
      .matches(phoneRegExp, "Enter Valid Contact number"),
    contactPersonName: Yup.string()
      .min(5, "Must be min 5 characters")
      .max(50, "Must be less than 50 ")
      .required("Please enter name"),
    country: Yup.array().min(1, "Please select a country").required(), //.of(Yup.string().required("Please select Country")),   
    portal: Yup.array().min(1, "Select at least one application").required(),  //.of(Yup.string().required("Please select portal")),
    paymentperHire: Yup.string().when("portal", {
      is: (portal) => portal.includes("3"),
      then: Yup.string().min(1, "Payment per Hire info is required").required("Payment per Hire info is required"),
    }),
    flatFee: Yup.string().when("paymentperHire", {
      is: (paymentperHire) => paymentperHire === "Flat Fee",
      then: Yup.string().min(1, "Must be 1 characters").max(10, "Must be less than 10").required("Flat fee is required"),
    }),
    flatFeeCountryId: Yup.number()
  });

  const formik = useFormik({
    initialValues: {
      companyName: "",
      registerdAddress: "",
      website: "",
      contactDetails: "",
      contactPersonName: "",
      country: [],
      onBoardingDate: "",
      portal: [],
      paymentperHire: "",
      flatFee: '',
      flatFeeCountryId: 0,
      employeeportal_domains: ''
    },
    validationSchema,
    onSubmit: async (values) => {

      if (isDomain === false) {
        setDisableBtn(true);
        values.country = customers.country;
        values.portal = customers.portal;
        values.flatFeeCountryId = selectedCurrency;
        if (domainList.length > 0) {
          values.employeeportal_domains = domainList;
        } else {
          delete values.employeeportal_domains;
        }
        await onValidData(values);
      } else {
        dispatch(updateSnackbar({ type: "error", message: "domain atleast 1" }))
      }
    },
  });

  useEffect(() => {
    if (customers.portal.includes("4")) {
      if (domainList && domainList.length > 0) {
        setIsDomain(false);
        return;
      }
      setIsDomain(true)
    }
  }, [domainList])


  const onCurrencyTypeChangeHandler = (e) => {
    setSelectedCurrency(parseInt(e.target.value));
    let obj = JSON.parse(JSON.stringify(customers));
    obj.flatFeeCountryId = parseInt(e.target.value);
    setCustomers(obj);
  }

  return (
    <React.Fragment>
      {/* {componentType !== "Create" ? (
        <div className="actionBtnRtl">
          {actions &&
            actions.map((item, i) => {
              if (item.type === "back") {
                return (
                  <Button
                    key={i}
                    variant={item.variant}
                    className={item.class}
                    style={item.style}
                    onClick={() => navigate(item.navigateTo)}
                  >
                    {item.label}{" "}
                  </Button>
                );
              } else if (item.type === "submit") {
                return (
                  <Button
                    key={i}
                    variant={item.variant}
                    className={item.class}
                    disabled={disableBtn}
                    style={item.style}
                    onClick={formik.handleSubmit}
                  >
                    {item.label}{" "}
                  </Button>
                );
              }
            })}
        </div>
      ) : (
        ""
      )} */}
      <FocusError formik={formik} />
      <div
        style={{ width: "98%", margin: "0 auto" }}
        className={componentType === "view" ? "viewSection" : ""}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              marginTop: "60px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "31.9%" }}>
              <TextField
                className="boldlabel"
                autoComplete="off"
                error={
                  formik.errors.companyName && formik.touched.companyName
                    ? true
                    : false
                }
                helperText={
                  formik.errors.companyName && formik.touched.companyName
                    ? formik.errors.companyName
                    : ""
                }
                InputLabelProps={{ shrink: true }}
                id="outlined-basic"
                variant="outlined"
                autoFocus={formik.errors.companyName !== "" ? true : false}
                label="Company's Name*"
                style={{ width: "100%" }}
                name="companyName*"
                value={customers.companyName}
                disabled={componentType !== "Create" ? true : false}
                onChange={(e) => onChangeHandler(e.target.value, "companyName")}
                onKeyUp={formik.handleBlur}
              />
            </div>

            <div style={{ width: "31.9%" }}>
              <TextField
                className="boldlabel"
                error={
                  formik.errors.registerdAddress &&
                    formik.touched.registerdAddress
                    ? true
                    : false
                }
                helperText={
                  formik.errors.registerdAddress &&
                    formik.touched.registerdAddress
                    ? formik.errors.registerdAddress
                    : ""
                }
                InputLabelProps={{ shrink: true }}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                label="Registered Address (HQ)*&nbsp;"
                style={{ width: "100%" }}
                name="registerdAddress*"
                value={customers.registerdAddress}
                disabled={componentType === "view" ? true : false}
                onChange={(e) =>
                  onChangeHandler(e.target.value, "registerdAddress")
                }
                onKeyUp={formik.handleBlur}
              />
            </div>

            <div style={{ width: "31.9%" }}>
              <TextField
                className="boldlabel"
                error={
                  formik.errors.website && formik.touched.website ? true : false
                }
                helperText={
                  formik.errors.website && formik.touched.website
                    ? formik.errors.website
                    : ""
                }
                InputLabelProps={{ shrink: true }}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                label="Website*"
                style={{ width: "100%" }}
                name="website*"
                value={customers.website}
                disabled={componentType === "view" ? true : false}
                onChange={(e) => onChangeHandler(e.target.value, "website")}
                onKeyUp={formik.handleBlur}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              marginTop: "60px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "31.9%" }}>
              <TextField
                className="boldlabel"
                error={
                  formik.errors.contactDetails && formik.touched.contactDetails
                    ? true
                    : false
                }
                helperText={
                  formik.errors.contactDetails && formik.touched.contactDetails
                    ? formik.errors.contactDetails
                    : ""
                }
                InputLabelProps={{ shrink: true }}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                label="Contact Number*"
                style={{ width: "100%" }}
                name="contactDetails*"
                value={customers.contactDetails}
                disabled={componentType === "view" ? true : false}
                onChange={(e) =>
                  onChangeHandler(e.target.value, "contactDetails")
                }
                onKeyUp={formik.handleBlur}
              />
            </div>

            <div style={{ width: "31.9%" }}>
              <TextField
                className="boldlabel"
                error={
                  formik.errors.contactPersonName &&
                    formik.touched.contactPersonName
                    ? true
                    : false
                }
                helperText={
                  formik.errors.contactPersonName &&
                    formik.touched.contactPersonName
                    ? formik.errors.contactPersonName
                    : ""
                }
                InputLabelProps={{ shrink: true }}
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                label="Representative's Name*&nbsp;"
                style={{ width: "100%" }}
                name="contactPersonName*"
                value={customers.contactPersonName}
                disabled={componentType === "view" ? true : false}
                onChange={(e) =>
                  onChangeHandler(e.target.value, "contactPersonName")
                }
                onKeyUp={formik.handleBlur}
              />
            </div>

            <div style={{ width: "31.9%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="customPicker"
                  error={
                    formik.errors.onBoardingDate &&
                      formik.touched.onBoardingDate
                      ? true
                      : false
                  }
                  helperText={
                    formik.errors.onBoardingDate &&
                      formik.touched.onBoardingDate
                      ? formik.errors.onBoardingDate
                      : ""
                  }
                  name="onBoardingDate*&nbsp;"
                  variant="outlined"
                  autoComplete="off"
                  value={dayjs(customers.onBoardingDate)}
                  onChange={(date) =>
                    onChangeHandler(dayjs(date), "onBoardingDate")
                  }
                  onKeyUp={formik.handleBlur}
                  label="Onboarding Date*&nbsp;&nbsp;"
                  disabled={componentType === "view" ? true : false}
                  style={{
                    width: "100%",
                    height: "3.5rem",
                    borderRadius: "4px",
                  }}
                />
              </LocalizationProvider>
              <p>
                {formik.errors.onBoardingDate && formik.touched.onBoardingDate
                  ? true
                  : false}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              marginTop: "60px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "32%" }}>
              <FormControl sx={{ width: "100%" }}
                error={formik.errors.portal && formik.touched.portal ? true : false}
              >
                <InputLabel className="boldSelectlabel" shrink={true} >
                  Applications Required*
                </InputLabel>
                <Select
                  className="applicationfieldcss"
                  name="portal"
                  autoComplete="off"
                  labelId="demo-multiple-chip-label1"
                  id=""
                  multiple
                  value={customers.portal}
                  onChange={handleChange}
                  onFocus={formik.handleBlur}
                  disabled={componentType === "view" ? true : false}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip1"
                      label=" Application required*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        let obj = applicationNames.filter((x) => x.id === value)[0];
                        return <Chip key={obj.name} label={obj.name} />;
                      })}
                    </Box>
                  )}
                >
                  {applicationNames.map(list => (
                    <MenuItem
                      className="selectOptions"
                      key={list.id}
                      value={list.id}
                    >
                      {list.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.errors.portal && formik.touched.portal
                    ? formik.errors.portal
                    : ""}
                </FormHelperText>
              </FormControl>
            </div>
            <div style={{ width: "32%" }}>
              <FormControl sx={{ width: "100%" }}
                error={formik.errors.country && formik.touched.country ? true : false}
              >
                <InputLabel className={componentType !== "Edit" ? "boldSelectlabel" : "boldSelectlabelEdit"} shrink={true} >Country*</InputLabel>
                <Select
                  className="countryfieldcss"
                  name="country"
                  labelId="demo-multiple-chip-label"
                  id=""
                  multiple
                  value={customers.country}
                  onChange={handleChange}
                  onFocus={formik.handleBlur}
                  disabled={componentType !== "Create" ? true : false}
                  input={
                    <OutlinedInput id="select-multiple-chip"
                      label="Country&nbsp;&nbsp;&nbsp;" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        let obj = countriesList.filter((x) => parseInt(x.id) === parseInt(value))[0];
                        return <Chip key={obj.name} label={obj.name} />;
                      })}
                    </Box>
                  )}
                >
                  {countriesList &&
                    countriesList.map((name) => (
                      <MenuItem
                        className="selectOptions"
                        key={name.id}
                        value={name.id}
                      >
                        {name.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {formik.errors.country && formik.touched.country
                    ? formik.errors.country
                    : ""}
                </FormHelperText>
              </FormControl>
            </div>

            <div style={{ width: "32%" }}></div>
          </div>
          {customers.portal &&
            customers.portal.includes("3") && (
              <>
                <h4 style={{ marginTop: "60px", display: "flex" }}><img src={process.env.PUBLIC_URL + "/assests/img/credit-card.svg"} alt="" style={{ marginRight: "10px" }} /> Payment per Hire Info:</h4>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "32%" }}>
                    <FormControl
                      fullWidth
                      error={formik.errors.paymentperHire && formik.touched.paymentperHire ? true : false}
                    >
                      <InputLabel className={
                        formik.errors.paymentperHire && formik.touched.paymentperHire
                          ? "text-danger boldSelectlabel"
                          : "boldSelectlabel"
                      }>Payment per Hire Info *&nbsp;
                      </InputLabel>
                      <Select
                        value={customers.paymentperHire}
                        name="Payment per Hire Info"
                        disabled={componentType === "view" ? true : false}
                        label="Payment per Hire Info *&nbsp;&nbsp;"
                        onChange={(e) => onChangeHandler(e.target.value, "paymentperHire")}
                        onFocus={formik.handleBlur}
                        autoComplete="off"
                        style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                      >
                        <MenuItem value="Flat Fee"> Flat Fee</MenuItem>
                      </Select>
                      <FormHelperText>
                        {formik.errors.paymentperHire && formik.touched.paymentperHire ? formik.errors.paymentperHire : ""}
                      </FormHelperText>
                    </FormControl>
                  </div>
                  {customers.paymentperHire &&
                    customers.paymentperHire === "Flat Fee" && (

                      <div style={{ width: "32%" }}>
                        <div style={{ position: "relative" }}>
                          <TextField
                            className="boldlabel"
                            error={formik.errors.flatFee && formik.touched.flatFee ? true : false}
                            helperText={formik.errors.flatFee && formik.touched.flatFee ? formik.errors.flatFee : ""}
                            id="outlined-basic"
                            variant="outlined"
                            autoComplete="off"
                            label="Flat Fee"
                            style={{ width: "100%" }}
                            name="flatFee"
                            value={customers.flatFee}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Select
                                    id="outlined-basic"
                                    className="innerDropdown"
                                    value={selectedCurrency}
                                    size="small"
                                    name="module"
                                    onChange={onCurrencyTypeChangeHandler}
                                    disabled={componentType === "view" ? true : false}
                                  >
                                    {countriesList &&
                                      countriesList.map((name) => (
                                        <MenuItem className="selectOptions"
                                          key={name.Id} value={name.Id}>
                                          <img src={name.image} alt={name.name} width="20px" style={{ paddingRight: "7px" }} />{name.currencyName}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  {selectedCurrency === 1 ? <span className="text-secondary">&#8377;</span>
                                    : selectedCurrency === 4 ? <span className="text-secondary">&#82;&#77;</span> 
                                    : selectedCurrency === 5 ? <span className="text-secondary">&#83;&#36;</span> 
                                    : selectedCurrency === 6 ? <span className="text-secondary">&#65;&#69;&#68;</span> : <span className="text-secondary">&#36;</span>}
                                    
                                </InputAdornment>
                              ),
                            }}
                            disabled={componentType === "view" ? true : false}
                            onChange={(e) => onChangeHandler(e.target.value, "flatFee")}
                            // onChange={handleChange}
                            onKeyUp={formik.handleBlur}
                          />
                          <div className="customTooltip">
                            <img src={process.env.PUBLIC_URL + "/assests/img/info_black.svg"} alt="info" />
                            <div>
                              This is the default amount for every job posting on the Cloud sourcing platform through ATS. It can be edited while creating a job in ATS if needed.
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }

                  <div style={{ width: "32%" }}></div>
                </div>
              </>
            )}

          <>
            {customers.portal &&
              customers.portal.includes("4") && (
                <>
                  <h4 style={{ marginTop: "60px", display: "flex" }}><img src={process.env.PUBLIC_URL + "/assests/img/personicon.svg"} alt="" style={{ marginRight: "10px" }} /> Employee portal Info</h4>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "32%" }} className="additionalList">
                      {componentType !== "view" && (
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          error={
                            formik.errors.employeeportal_domains && formik.touched.employeeportal_domains
                              ? true
                              : false
                          }
                          helperText={
                            formik.errors.employeeportal_domains && formik.touched.employeeportal_domains
                              ? formik.errors.employeeportal_domains
                              : ""
                          }
                          style={{ width: "100%", marginBottom: "16px" }}
                          name="employeeportal_domains"
                          placeholder="Please enter Domain and press the enter button"
                          value={customers.employeeportal_domains}
                          autoComplete="off"
                          onChange={(e) => onChangeHandler(e.target.value, "employeeportal_domains")}
                          onKeyUp={formik.handleBlur}

                          onKeyDown={(e) => {
                            if (e.which === 13) {
                              if (formik.errors.employeeportal_domains && formik.touched.employeeportal_domains) {
                                return
                              }

                              customers.employeeportal_domains = '';
                              let details = JSON.parse(JSON.stringify(domainList));
                              details.push(e.target.value);
                              setDomainList(details)
                            }
                          }}
                        />
                      )
                      }
                      {domainList && domainList.length > 0 &&
                        domainList.map((item, i) => {
                          return <p key={i}>{item} {componentType !== "view" && (<CloseIcon onClick={() => onRemoveHandler(item, i)} />)}</p>;
                        })}

                    </div>
                  </div>
                </>
              )}
          </>
        </div>
      </div>

      {componentType === "Create" ? (
        <div
          style={{
            marginLeft: "auto!important",
            width: "fit-content",
            marginTop: "40px",
            marginLeft: "auto",
            marginRight: "-13px",
          }}
        >
          {actions &&
            actions.map((item) => {
              if (item.type === "back") {
                return (
                  <Button
                    className={item.class}
                    variant={item.variant}
                    style={item.style}
                    onClick={() => navigate(item.navigateTo)}
                  >
                    {item.label}{" "}
                  </Button>
                );
              } else if (item.type === "submit") {
                return (
                  <Button
                    className={item.class}
                    variant={item.variant}
                    style={item.style}
                    disabled={disableBtn}
                    onClick={formik.handleSubmit}
                  >
                    {item.label}{" "}
                  </Button>
                );
              }
            })}
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default memo(CustomerComponent);

const initialDetails = {
  companyName: "",
  registerdAddress: "",
  website: "",
  contactDetails: "",
  contactPersonName: "",
  onBoardingDate: "",
  country: [],
  portal: [],
  paymentperHire: "",
  employeeportal_domains: "",
  flatFee: '',
  flatFeeCountryId: 0,
};
