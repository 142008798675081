import React, { useState, useEffect } from "react";
import { styled, Table, TableBody, TableContainer, TableHead, TableRow, Paper, TextField, IconButton } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import "./DynamicTableStyle.css";
import { DateRangePicker } from "materialui-daterange-picker";
import Checkbox from '@mui/material/Checkbox';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { updateSnackbar } from "../../_redux/SnackbarSlice";
import { useDispatch } from "react-redux";



const ApplicantsDynamicTable = ({ data, columns, componentType, typeofTable, receiveProps }) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [tableData, setTableData] = useState([]);
  const [tableRawData, setTableRawData] = useState([]);
  const [isDisableActions, setIsDisableActions] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});

  const toggle = () => setOpen(!open);
    const dispatch = useDispatch();
  

  useEffect(() => {
    if (data.length > 0) {
      setTableData(data);
      setTableRawData(data);
    }
  }, [data])

  useEffect(() => {
    console.log("isDisableActions", isDisableActions)
    console.log("dateRange", dateRange)
  }, [isDisableActions, dateRange])

  useEffect(() => {
    if (tableData.length > 0) {
      console.log('tableData', tableData)
      console.log('tableRawData', tableRawData)
      let isFlag = tableData.filter(x => x.isEdit === true);
      if (isFlag.length > 0) {
        setIsDisableActions(true)
      } else {
        setIsDisableActions(false)
      }
    }
  }, [tableData, tableRawData])

  const onTableHandler = (item, type) => {
    let Array = JSON.parse(JSON.stringify(tableData));
    let Index = Array.findIndex(x => x.id === item.id);
    if (type === 'edit') {
      if (Index !== -1) {
        Array[Index].isEdit = true;
        setTableData(Array);
      }
    } else if (type === 'isCurrent') {
      if (Index !== -1) {
        Array[Index].isCurrent = !Array[Index].isCurrent;
        if(Array[Index].isCurrent === true){
          Array[Index].endDate = "- present";
          Array[Index].duration = `${Array[Index].startDate} - present`;
        } else {
          Array[Index].endDate = "";
          Array[Index].duration = "";
        }
        setTableData(Array);
      } 
    }
    else if (type === 'delete') {
      if (Index !== -1) {
        setIsDisableActions(false)
        Array.splice(Index, 1);
        setTableData(Array);
        setTableRawData(Array);
        receiveProps(Array);
      }
    }
    else if (type === 'cancel') {
      let Array1 = JSON.parse(JSON.stringify(tableRawData));
      Array1.forEach(e => { e.isEdit = false })
      setIsDisableActions(false)
      setTableData(Array1);
    }
    else if (type === 'save') {
      if (Index !== -1) {
        Array[Index].isEdit = false;
        if (typeofTable === "educationDetails") {
          if ((Array[Index].degree !== undefined && Array[Index].degree !== "") || (Array[Index].organization !== undefined && Array[Index].organization !== "") ||
            (Array[Index].duration !== undefined && Array[Index].duration !== "") || (Array[Index].locationFormated !== undefined && Array[Index].locationFormated !== "")) {
            setTableRawData(Array);
            setTableData(Array);
            receiveProps(Array);
          } else {
            onTableHandler(item, 'delete');
          }
        }
        else if (typeofTable === "workExp") {
          if ((Array[Index].jobTitle !== undefined && Array[Index].jobTitle !== "") || (Array[Index].organization !== undefined && Array[Index].organization !== "")
            || (Array[Index].duration !== undefined && Array[Index].duration !== "" && Array[Index].startDate !== "") || (Array[Index].empType !== undefined && Array[Index].empType !== "")) {
            setTableRawData(Array);
            setTableData(Array);
            receiveProps(Array);
          } else if(Array[Index].startDate === ""){
            dispatch(
              updateSnackbar({ type: "error", message: "Please select valid date" })
            );
          }
          else {
            onTableHandler(item, 'delete');
          }
        }
      }
    }
  }

  const handleDateChange = (date, data) => {
    let Array = JSON.parse(JSON.stringify(tableData));
    let Index = Array.findIndex(x => x.id === data.id);
    if (Index !== -1) {
      Array[Index].startDate = date.startDate.toLocaleDateString();
      Array[Index].endDate = date.endDate.toLocaleDateString();
      Array[Index].duration = JSON.stringify(date);
      setOpen(false)
      setTableData(Array);
    }
  }

  const onChangeHandler = (value, label, obj) => {
    let Array = JSON.parse(JSON.stringify(tableData));
    let Index = Array.findIndex(x => x.id === obj.id);
    if (Index !== -1) {
      Array[Index][label] = value;
      setTableData(Array);
    }
  }

  const addRowHandler = () => {
    let Array = JSON.parse(JSON.stringify(tableData));
    if (Array.length === 1) {
      let result = !Object.values(Array[0]).every(o => o === null || o === '');
      if (!result) { Array = []; setTableRawData([]) }
    }
    let obj = JSON.parse(JSON.stringify(columns))
    let newObj = {};
    obj.forEach(e => { newObj[e.name] = '' })
    newObj["isEdit"] = true;
    newObj["id"] = (Array.length > 0) ? Array[Array.length - 1].id + 1 : 1;
    newObj["startDate"] = '';
    newObj["endDate"] = '';
    newObj["isCurrent"] = false;
    setIsDisableActions(true);
    Array.push(newObj);
    setTableData(Array);
  }

  const getFormatedDate = (date) => {
    let modifyDate = date.$d.toLocaleDateString()
    let Obj = modifyDate.split("/");
    return `${Obj[0].padStart(2, "0")}/${Obj[1].padStart(2, "0")}/${Obj[2]}`;
  }

  const onStartDateChangeHandler = (value, obj) => {
    let Array = JSON.parse(JSON.stringify(tableData));
    let Index = Array.findIndex(x => x.id === obj.id);
    if (Index !== -1) {
      Array[Index].startDate = getFormatedDate(value);
      Array[Index].endDate = "- present";
      Array[Index].duration = `${Array[Index].startDate} - present`;
      setTableData(Array);
    }
  }

  return (
    <>
      {componentType !== "view" ?
        <IconButton color="primary" className="addRow" disabled={isDisableActions} onClick={addRowHandler} component="label">
          <AddBoxIcon style={{ color: "#8e8e8e", fontSize: "31px" }} />
        </IconButton>
        : ''}
      <TableContainer component={Paper}>
        <Table id="inlineEditTable" aria-label="customized table">
          <TableHead >
            <TableRow >
              {columns.map(col => {
                return (<StyledTableCell className="applicantdynatablehead" width={col.displayName === "Action" ? "10%" : "20%"}>{col.displayName} </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length > 0 ?
              <>
                {tableData && tableData.map((data, index) => {
                  if (data.isEdit === false) {
                    return (
                      <StyledTableRow>
                        {columns.map(col => {
                          if (col.displayName !== 'Action' && col.displayName !== 'Duration') {
                            return (
                              <StyledTableCell width="20%"><div className="rowHeight">{data[col.name]}</div> </StyledTableCell>
                            )
                          } else if (col.displayName !== 'Action' && (col.displayName === 'Duration' && data.isCurrent === true)) {
                            return (
                              <StyledTableCell width="20%">{data.startDate ? data.startDate + "- Present" : ''} </StyledTableCell>
                            )
                          } else if (col.displayName !== 'Action' && (col.displayName === 'Duration' && data.isCurrent === false)) {
                            return (
                              <StyledTableCell width="20%">
                                {data.startDate && data.endDate ? data.startDate + " - " + data.endDate : data.startDate ? data.startDate : data.endDate ? data.endDate : ''}
                              </StyledTableCell>
                            )
                          } else {
                            if (componentType !== "view" && col.displayName === 'Action') {
                              return (
                                <StyledTableCell width="30">
                                  <IconButton color="primary" disabled={isDisableActions} onClick={() => onTableHandler(data, 'edit')} component="label">
                                    <EditIcon style={{ color: "#8e8e8e" }} className="appediticon" />
                                  </IconButton>
                                  <IconButton color="primary" disabled={isDisableActions} onClick={() => onTableHandler(data, 'delete')} component="label">
                                    <DeleteIcon style={{ color: "#8e8e8e" }} className="appdeleteicon" />
                                  </IconButton>
                                </StyledTableCell>
                              )
                            } else {
                              return (<StyledTableCell> </StyledTableCell>)
                            }
                          }
                        })}
                      </StyledTableRow>
                    )
                  } else if (data.isEdit === true) {
                    return (
                      <>
                        <StyledTableRow valign="top"
                          className={typeofTable === "workExp" ? "educationTableWorkExp" : ""}>
                          {columns.map(col => {
                            if (col.name !== 'duration' && col.displayName !== 'Action') {
                              return (
                                <StyledTableCell valign="top">
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    autoComplete="off"
                                    placeholder={col.displayName}
                                    style={{ width: "100%" }}
                                    name={col.name}
                                    value={data[col.name]}
                                    onChange={(e) => { onChangeHandler(e.target.value, col.name, data) }}
                                  />
                                  <>
                                    {typeofTable === "workExp" && col.displayName === "Title" ?
                                      <div style={{ display: "flex" }}>
                                        <Checkbox {...label} className="checkboxapplicants" checked={data.isCurrent} onClick={() => onTableHandler(data, 'isCurrent')} />
                                        <p>I am currently working on this role</p>
                                      </div> : ""
                                    }
                                  </>
                                </StyledTableCell>
                              )
                            }
                            else if (col.name === 'duration') {
                              return (
                                <StyledTableCell valign="top">
                                  {data.isCurrent === true ?
                                    <div className="isCurrentSection">
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                          className="customPicker"
                                          name={col.name}
                                          variant="outlined"
                                          autoComplete="off"
                                          value={dayjs(data.startDate)}
                                          onChange={(date) =>
                                            onStartDateChangeHandler(dayjs(date), data)
                                          }
                                          style={{
                                            height: "3.5rem",
                                            borderRadius: "4px",
                                          }}
                                        />
                                      </LocalizationProvider>
                                      <span style={{ verticalAlign: "bottom", display: "inline-block", margin: "0 0 19px 18px" }}> - Present</span>
                                    </div>
                                    :
                                    <div className="educationTermRangePicker" >
                                      <div>
                                        <TextField
                                          id="outlined-basic"
                                          // className={data.isCurrent === true ? "br-box" : ""}
                                          variant="outlined"
                                          autoComplete="off"
                                          placeholder="Start Date"
                                          style={{ width: "50%" }}
                                          name={col.name}
                                          value={data.startDate}
                                          onClick={(e) => { setOpen(true) }}
                                        />
                                        <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          autoComplete="off"
                                          placeholder="End Date"
                                          style={{ width: "50%" }}
                                          name={col.name}
                                          value={data.endDate}
                                          onClick={(e) => { setOpen(true) }}
                                        />
                                      </div>
                                      <DateRangePicker
                                        open={open}
                                        toggle={toggle}
                                        minDate={new Date("1-1-1800")}
                                        autoComplete="off"
                                        onChange={(range) => {
                                          setDateRange(range)
                                          handleDateChange(range, data)
                                        }}
                                      />
                                    </div>
                                  }
                                </StyledTableCell>
                              )
                            } else {
                              if (componentType !== "view") {
                                return (
                                  <StyledTableCell>
                                    <IconButton style={{ verticalAlign: "text-top" }} color="primary" onClick={() => onTableHandler(data, 'save')} component="label">
                                      <DoneIcon style={{ color: "#8e8e8e" }} />
                                    </IconButton>
                                    <IconButton style={{ verticalAlign: "text-top" }} color="primary" onClick={() => onTableHandler(data, 'cancel')} component="label">
                                      <CloseIcon style={{ color: "#8e8e8e" }} />
                                    </IconButton>
                                  </StyledTableCell>
                                )
                              } else {
                                return (<StyledTableCell> </StyledTableCell>)
                              }
                            }
                          })}
                        </StyledTableRow>
                      </>
                    )
                  }
                }
                )}
              </>
              : ''}

          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};

export default React.memo(ApplicantsDynamicTable);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRight: "none"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   borderRight: "1px solid #E8E8E8",
  // },
}));
