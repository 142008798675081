import React, { useState } from 'react'
import './Jobportal.css'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IconButton } from '@mui/material';
import GetJobId from '../../../_utilities/GetJobId';
import { Labels } from '../../../_utilities/constants';
import { VENDOR_PORTAL_URL, VENDOR_PORTAL_URL_ID } from '../../../API';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoader } from '../../../_redux/LoaderSlice';
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import { useEffect } from 'react';
import { ValidatesQuestions, ValidatesType, ValidatesOption } from './Validator';
import RightSideBar from '../../../components/Rightsidebar/RightSideBar';
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import InputAdornment from '@mui/material/InputAdornment';
import { decrypt } from '../../../_utilities/_encryptDecryptHelper';
import { Link } from 'react-router-dom';

const EditIcon = `${process.env.PUBLIC_URL}/assests/img/editIcon.svg`;
const DeleteIcon = `${process.env.PUBLIC_URL}/assests/img/deleteicon.svg`;
const EditIcon2 = `${process.env.PUBLIC_URL}/assests/img/editing1.svg`;
const pannelobj = {
  title: "Add New Question",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/questionIcon1.png`,
  pageName: "vendorportal"
};

const JobPortal = ({isQuestionData = false, jobDetailsID, onReceiveData, getCreatedJob}) => {
  const JobId = jobDetailsID ? jobDetailsID : GetJobId();
  const showLoader = useSelector((state) => state.Loader.value);
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);
  const [jobCode, setJobCode] = useState(null);
  const [isValidUser,setIsValidUser ] = useState(false);


  const defaultQuestion = {
    question: "",
    subQuestion: [""],
    multiOptions: [""],
    text: "",
    numeric: "",
    type: "",
    status: "1",
    jobPostingsId: JobId,
    id: null,
    isEdit: false,
  }
  const [questions, setQuestions] = React.useState([]);
  const addQuestion = () => {
    setQuestions([defaultQuestion, ...questions]);
    setIsRightSidePannel(true);
  };

  useEffect(() => {
    if(JobId){
    let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
    if (subPathObj && subPathObj.length > 0) {
      let index = subPathObj.findIndex(x => x.id === JobId);
      let jobCode = ""
      if (index !== -1) {
        jobCode = subPathObj[index].name  ;
      } 
      else {
        if (getCreatedJob) {
          let isJobCode = getCreatedJob.jobCode;
          setJobCode(isJobCode);
        }
      }
      if(jobCode){
        let splitCode = jobCode.split('(');
        let splitJobcode = splitCode[1].split(")");
        // let jobCodeobj = {jobId: JobId, jobCode: splitJobcode[0]}
        setJobCode(splitJobcode[0]);
        console.log(splitJobcode[0]);
      }
    }
    else {
      if(getCreatedJob){
        let isJobCode = getCreatedJob.jobCode;
        setJobCode(isJobCode);
      } 
    }
  }
  }, [JobId, getCreatedJob])

  const fetchQuestions = async (id) => {
    const getUserId = decrypt('GetUserId');
    let getUserDetails = JSON.parse(localStorage.getItem("getAttributes"));
    if (!JobId) return null;
    const postData = await fetch(`${process.env.REACT_APP_JOBOPENING}${VENDOR_PORTAL_URL}/${JobId}/questions`, {      // ${VENDOR_PORTAL_URL_ID(JobId)}
      method: "GET",
      headers:{
        'Content-Type': 'Application/json',
        "Customerid": getUserId,
        "jobCode":jobCode,
        "userId":getUserDetails[0].userId
      }
    });
    let data = await postData.json();
    if (data.code === 200) {
      let updatedQuestions = data.Questions.map((item, index) => {
        let isSubQuestionAvailable = item.subQuestion && item.subQuestion !== "null";
        let obj = {
          ...item,
          subQuestion: isSubQuestionAvailable ? Object.values(JSON.parse(item.subQuestion)) : [],
          isEdit: false,
        }
        return obj;
      }).sort().reverse();
      setQuestions(updatedQuestions);
    }else if(data.code === 404){
      setIsValidUser(true)
    }
  }


  useEffect(() => {
    fetchQuestions()
    
  }, [JobId])

  const onReceivePropsHandler = (pannel, load) => {
    setIsRightSidePannel(pannel);
    let newQuestions = [...questions];
    newQuestions.splice(0, 1);
    setQuestions(newQuestions);
    fetchQuestions();
  };

  useEffect(() => {
    if(isQuestionData === true){
      onReceiveData(questions, false, "questions");
    }
  }, [isQuestionData])

  return (
    <div className="postingQuestion">
      <div style={{ position: "relative" }}>
        {!isValidUser &&
        <div className='addquestionicontext cursor-pointer d-flex justify-content-end ml-10' onClick={addQuestion}>
          {/* <IconButton className='addiconbutton'> */}
            <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" className='circleplusicon' />
          {/* </IconButton> */}
          <p className='addquestion'> New Question</p>
        </div>
        }
      </div>
      {questions.length === 0 && !showLoader && <div className="nodatasection">
        <img src={ process.env.PUBLIC_URL + "/assests/vendorportal/AddQuestions.svg"} alt="noResult" />
        No data found
      </div>}
      {questions.length > 0 &&
        <div>
          {questions && questions.length > 0 && questions.map((item, index) => (
            <QuestionForm index={index} jobDetailsID={jobDetailsID} item={item} fetchQuestions={fetchQuestions} questions={questions} setQuestions={setQuestions}  getCreatedJob={getCreatedJob} />
          ))}
        </div > 
      }
      {
        isRightSidePannel ? (
          <div className='questionsRightsidepanel'>
          <RightSideBar
            componentData={
              <ComponentRenderData
                onReceivechildProps={onReceivePropsHandler}
                questionsData={questions}
                jobDetailsID={jobDetailsID}
                setQuestions={setQuestions}
                getCreatedJob={getCreatedJob}
              />
            }
            componentLayout={pannelobj}
            onReceiveProps={onReceivePropsHandler}
          />
          </div>
        ) : (
          ""
        )
      }
    </div >
  )
}

const QuestionForm = ({ index, jobDetailsID, item, fetchQuestions, questions, setQuestions,getCreatedJob  }) => {
  const JobId = jobDetailsID ? jobDetailsID : GetJobId();
  const dispatch = useDispatch();
  const ValidateQuestions = ValidatesQuestions();
  const ValidateType = ValidatesType();
  const ValidateOption = ValidatesOption();
  const [, forceUpdate] = useState(false);
  const [isEdit, setIsEdit] = React.useState(null);
  const [jobCode, setJobCode] = useState(null);
  // const getJobCode = GetJobId();

  useEffect(() => {
    if(JobId){
    let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
    if (subPathObj && subPathObj.length > 0) {
      let index = subPathObj.findIndex(x => x.id === JobId);
      let jobCode = ""
      if (index !== -1) {
        jobCode = subPathObj[index].name  ;
      }
      else {
        if (getCreatedJob) {
          let isJobCode = getCreatedJob.jobCode;
          setJobCode(isJobCode);
        }
      }
     
      if(jobCode){
        let splitCode = jobCode.split('(');
        let splitJobcode = splitCode[1].split(")");
        // let jobCodeobj = {jobId: JobId, jobCode: splitJobcode[0]}
        setJobCode(splitJobcode[0]);
        console.log(splitJobcode[0]);
      }
    }
    else {
      if(getCreatedJob){
        let isJobCode = getCreatedJob.jobCode;
        setJobCode(isJobCode);
      } 
    }
  }
  }, [JobId, getCreatedJob])

  const addOption = (index, itemType) => {
    if(ValidateQuestions.current.allValid() && ValidateType.current.allValid() && ValidateOption.current.allValid()){
      const newQuestions = [...questions];
      newQuestions[index]["subQuestion"].push("");
      setQuestions(newQuestions);
    }
  };
  const removeQuestion = async (index) => {
    const newQuestions = [...questions];
    let id = newQuestions[index].id;
    newQuestions.splice(index, 1);
    dispatch(updateLoader(true));
    let getUserDetails = JSON.parse(localStorage.getItem("getAttributes"));
    const postData = await fetch(`${process.env.REACT_APP_JOBOPENING}${VENDOR_PORTAL_URL}/${JobId}/questions/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "customerid": JSON.parse(decrypt('GetUserId')),
        "jobCode":jobCode,
        "userId":getUserDetails[0].userId
      }
    });
    let data = await postData.json();
    if (data.code === 200) {
      setQuestions(newQuestions);
      dispatch(updateSnackbar({
        type: (postData) ? "success" : 'error',
        message: (postData) ? "Question deleted successfully" : "Data is not deleted"
      }));
      dispatch(updateLoader(false));
    }
  };

  const removeOption = (index, optionIndex, name) => {
    const newQuestions = [...questions];
    newQuestions[index][name].splice(optionIndex, 1);
    setQuestions(newQuestions);
  };
  const handleQuestion = (event, name, index) => {
    const newQuestions = [...questions];
    newQuestions[index][name] = event.target.value;
    setQuestions(newQuestions);
  };
  const handleOption = (event, name, index, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[index][name][optionIndex] = event.target.value;
    setQuestions(newQuestions);
  };

  const questionTypes = [
    {
      label: Labels.LongText,
      value: Labels.LongText
    },
    {
      label: Labels.ShortText,
      value: Labels.ShortText
    },
    {
      label: Labels.MultiSelect,
      value: Labels.MultiSelect
    },
    {
      label: Labels.SingleSelect,
      value: Labels.SingleSelect
    },
  ];
  const handleSubmit = async (id) => {
    const getUserId = decrypt('GetUserId');
    if (ValidateQuestions.current.allValid() && ValidateType.current.allValid() && ValidateOption.current.allValid()) {
      let selectedIndex = id === null ? 0 : questions.findIndex((question) => question.id === id);
      if (isEdit) {
        //edit function
        const obj = {};
        for (let i = 0; i < questions[selectedIndex].subQuestion.length; i++) {
          obj[i + 1] = questions[selectedIndex].subQuestion[i];
        }
        let allowedTypes = [Labels.MultipleChoice, Labels.MultiSelect, Labels.SingleSelect];

        if ((allowedTypes.includes(questions[selectedIndex].type) && Object.keys(obj).length >= 2) || !allowedTypes.includes(questions[selectedIndex].type)) {
          let question = {
            question: questions[selectedIndex].question,
            type: questions[selectedIndex].type,
            subQuestion: allowedTypes.includes(questions[selectedIndex].type) ? obj : null,
            status: questions[selectedIndex].status,
            jobPostingsId: questions[selectedIndex].jobPostingsId,
          }
          dispatch(updateLoader(true));
          let getUserDetails = JSON.parse(localStorage.getItem("getAttributes"));
          console.log(getUserDetails[0].userId);
          const postData = await fetch(`${process.env.REACT_APP_JOBOPENING}${VENDOR_PORTAL_URL}/${JobId}/questions/${id}`, {
            method: "PUT",
            body: JSON.stringify(question),
            headers: {
              "Content-Type": "application/json",
              "customerid": JSON.parse(decrypt('GetUserId')),
              "jobCode":jobCode,
              "userId":getUserDetails[0].userId
            }
          });
          dispatch(updateSnackbar({
            type: (postData) ? "success" : 'error',
            message: (postData) ? "Question updated successfully" : "Data is not updated"
          }));
          dispatch(updateLoader(false));
          setIsEdit(null);
          fetchQuestions();
        } else {
          dispatch(updateSnackbar({
            type: "error",
            message: "Please add at least two options"
          }));
        }
      } else {
        //save function
        const obj = {};
        for (let i = 0; i < questions[selectedIndex].subQuestion.length; i++) {
          obj[i + 1] = questions[selectedIndex].subQuestion[i];
        }
        let allowedTypes = [Labels.MultipleChoice, Labels.MultiSelect, Labels.SingleSelect];
        if ((allowedTypes.includes(questions[selectedIndex].type) && Object.keys(obj).length >= 2) || !allowedTypes.includes(questions[selectedIndex].type)) {
          let updatedQuestions = [...questions];
          updatedQuestions[selectedIndex].id = questions.length + 1;
          let question = {
            question: questions[selectedIndex].question,
            type: questions[selectedIndex].type,
            subQuestion: allowedTypes.includes(questions[selectedIndex].type) ? obj : null,
            status: questions[selectedIndex].status,
            jobPostingsId: questions[selectedIndex].jobPostingsId ? questions[selectedIndex].jobPostingsId : JobId,
          }
          dispatch(updateLoader(true));
          let getUserDetails = JSON.parse(localStorage.getItem("getAttributes"));
          let CustomerId = JSON.parse(decrypt('GetUserId'));
          const postData = await fetch(`${process.env.REACT_APP_JOBOPENING}${VENDOR_PORTAL_URL}`, {
            method: "POST",
            body: JSON.stringify(question),
            headers: {
              "Content-Type": "application/json",
              "customerid": CustomerId,
              "jobCode":jobCode,
              "userId":getUserDetails[0].userId
            }
          });
          dispatch(updateSnackbar({
            type: (postData) ? "success" : 'error',
            message: (postData) ? "Screening Screening Question added successfully" : "Data is not saved"
          }));
          // Data saved Successfully!
          dispatch(updateLoader(false));
          setQuestions(updatedQuestions);
          fetchQuestions();
        } else {
          dispatch(updateSnackbar({
            type: "error",
            message: "Please add at least two options"
          }));
        }
      }
    } else {
      ValidateQuestions.current.showMessages();
      ValidateType.current.showMessages();
      ValidateOption.current.showMessages();
      forceUpdate(true);
    }
  }

  const handleEdit = (index) => {
    setIsEdit(index);
    let obj = JSON.parse(JSON.stringify(questions));
    let findindex = obj.findIndex(x => x.id === item.id);
    if(findindex !== -1){
      obj[findindex].isEdit = true;
    }
    setQuestions(obj);
  }
  const handleDelete = (index) => {
    removeQuestion(index);
  }
  return (
    <div className='p-1 text-left jobPortal'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          {/* card action start here */}
          <div className='d-flex justify-content-between align-items-center mr-5' style={{ marginBottom: "10px" }} >
            <h4>{item.type} question</h4>
            <div className='addquestionicontext cursor-pointer' style={{ marginLeft: "auto" }}>
              {(isEdit === item.id && (item.type === Labels.MultiSelect || item.type === Labels.SingleSelect && item.subQuestion.length < 4)) && (
                <div onClick={() => addOption(index, item.type)} className={(ValidateQuestions.current.allValid() || ValidateType.current.allValid() || ValidateOption.current.allValid()) ? "cursor-pointer addoption" : "cursor-pointer addoption disabled" }
                  style={{ marginRight: "70px", marginleft: "20px" }}>
                  <IconButton className='addiconbutton'>
                    <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" className='circleplusicon' />
                  </IconButton>
                  <span>Add New Option</span>
                </div>
              )}
              <div style={{ width: "20px" }}></div>
              { (isEdit === item.id && item.isEdit === true) ?
                <>
                  <Button className="buttonHover" style={{textDecoration:"underline"}} variant="default" onClick={() => {setIsEdit(null); fetchQuestions();}} >
                    Cancel
                  </Button>
                  <Button className="buttonHover" size="small" variant="contained" style={{ backgroundColor: "#ffd800", color: "black", borderRadius: "10px", width: "6rem",fontSize:"14px", fontWeight: "", marginleft: "20px" }} onClick={() => handleSubmit(item.id)} >
                    Save
                  </Button>
                </> : (isEdit === null && questions.every((e) => {return e.isEdit === false })) ? 
                <>
                  <img src={DeleteIcon} alt="delete icon" className='vendor-icon delete-icon' onClick={() => handleDelete(index)} />
                  <EditImage handleEdit={handleEdit} item={item} />
                </> : ''}
            </div>
          </div>
          {/* card action end here */}
          <TextField
            size='small' multiline
            className="" fullWidth
            value={item.question}
            disabled={(item.id !== null && isEdit !== item.id)}
            onChange={(event) => handleQuestion(event, 'question', index)}
          />
          {ValidateQuestions.current.message(`Question`, item.question, `required|min:1|max:${item.type === "Short Text" ? "500" : "1000"}`)}
          {/* for Options section start here*/}
          {(item.type === Labels.SingleSelect && item.type !== Labels.MultiSelect) &&
            <Grid container spacing={2} style={{ marginTop: "7px" }}>
              {item.subQuestion.map((option, optionIndex) => (
                <Grid item xs={12} sm={6} md={3} key={optionIndex}>
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <TextField fullWidth size='small'
                      variant="outlined"
                      className=""
                      value={option}
                      disabled={(item.id !== null && isEdit !== item.id)}
                      onChange={(event) => handleOption(event, 'subQuestion', index, optionIndex)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><PanoramaFishEyeIcon /></InputAdornment>,
                      }}
                    />
                    {item.subQuestion.length > 1 && (item.id === null || isEdit === item.id) &&
                      <CloseIcon onClick={() => removeOption(index, optionIndex, 'subQuestion')} style={{ color: "#999999",cursor:"pointer" }} />}
                  </div>
                  {ValidateOption.current.message("This Option", option, "required")}
                </Grid>
              ))}
            </Grid>
          }
          {/* for Options section end here*/}

          {/* for multiselect option section start here */}
          {(item.type !== Labels.SingleSelect && item.type === Labels.MultiSelect) &&
            <Grid container spacing={2} style={{ marginTop: "7px" }}>
              {item.subQuestion.map((option, optionIndex) => (
                <Grid item xs={12} sm={6} md={3} key={optionIndex}>
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <TextField fullWidth size='small'
                      variant="outlined"
                      className=""
                      value={option}
                      disabled={(item.id !== null && isEdit !== item.id)}
                      onChange={(event) => handleOption(event, 'subQuestion', index, optionIndex)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><CheckBoxOutlineBlankIcon /></InputAdornment>,
                      }}
                    />
                    {item.subQuestion.length > 1 && (item.id === null || isEdit === item.id) &&
                      <CloseIcon onClick={() => removeOption(index, optionIndex, 'subQuestion')} style={{ color: "#999999",cursor:"pointer" }} />
                    }
                  </div>
                  {ValidateOption.current.message("This Option", option, "required")}
                </Grid>
              ))}
            </Grid>}
          {/* for multiselect option section end here */}
        </Grid>
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={2}>
          <h4>Type</h4>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={item.type} size='small'
              disabled
              onChange={(event) => handleQuestion(event, 'type', index)}
            >
              {questionTypes.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className='mt-2'>
            {validator.current.message(`Type `, item.type, "required")}
          </div>
        </Grid> */}
      </Grid>
    </div>
  )
}
const ComponentRenderData = ({ onReceivechildProps, jobDetailsID, questionsData: questions, setQuestions, getCreatedJob }) => {
  const dispatch = useDispatch();
  const ValidateQuestions2 = ValidatesQuestions();
  const ValidateType2 = ValidatesType();
  const ValidateOption2 = ValidatesOption();
  const [, forceUpdate] = useState(false);
  const [isEdit, setIsEdit] = React.useState(null);
  const [questionsArray, setQuestionsArray] = useState(questions);
  const [jobCode, setJobCode] = useState(null);
  const JobId = jobDetailsID ? jobDetailsID : GetJobId();

  useEffect(() => {
    if(JobId){
    let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
    if (subPathObj && subPathObj.length > 0) {
      let index = subPathObj.findIndex(x => x.id === JobId);
      let jobCode = ""
      if (index !== -1) {
        jobCode = subPathObj[index].name  ;
      }
      else {
        if (getCreatedJob) {
          let isJobCode = getCreatedJob.jobCode;
          setJobCode(isJobCode);
        }
      }
      
      if(jobCode){
        let splitCode = jobCode.split('(');
        let splitJobcode = splitCode[1].split(")");
        // let jobCodeobj = {jobId: JobId, jobCode: splitJobcode[0]}
        setJobCode(splitJobcode[0]);
        console.log(splitJobcode[0]);
      }
    }
    else {
      if(getCreatedJob){
        let isJobCode = getCreatedJob.jobCode;
        setJobCode(isJobCode);
      } 
    }
  }
  }, [JobId, getCreatedJob])

  const addOption = (index, itemType) => {
    const newQuestions = [...questionsArray];
    //if (itemType === Labels.SingleSelect && newQuestions[index]["subQuestion"].length >= 2) return;
    newQuestions[index]["subQuestion"].push("");
    setQuestionsArray(newQuestions);
  };

  const removeOption = (index, optionIndex, name) => {
    const newQuestions = [...questionsArray];
    newQuestions[index][name].splice(optionIndex, 1);
    setQuestionsArray(newQuestions);
    {ValidateOption2.current.message(`Option ${optionIndex + 1} `, newQuestions[index][name][0], "")}
  };
  const handleQuestion = (event, name, index) => {
    const newQuestions = [...questionsArray];
    newQuestions[index][name] = event.target.value;
    newQuestions[index].subQuestion = [''];
    setQuestionsArray(newQuestions);
    {ValidateOption2.current.message(`Option ${index + 1} `, newQuestions[index].subQuestion[0], "")}
  };
  const handleOption = (event, name, index, optionIndex) => {
    const newQuestions = [...questionsArray];
    newQuestions[index][name][optionIndex] = event.target.value;
    setQuestionsArray(newQuestions);
  };

  const questionTypes = [
    {
      label: Labels.LongText,
      value: Labels.LongText
    },
    {
      label: Labels.ShortText,
      value: Labels.ShortText
    },
    {
      label: Labels.MultiSelect,
      value: Labels.MultiSelect
    },
    {
      label: Labels.SingleSelect,
      value: Labels.SingleSelect
    },
  ];
  const handleSubmit = async (id) => {
    const getUserId = decrypt('GetUserId');
    if (ValidateQuestions2.current.allValid() && ValidateType2.current.allValid() && ValidateOption2.current.allValid()) {
      let selectedIndex = id === null ? 0 : questionsArray.findIndex((question) => question.id === id);
      if (isEdit) {
        //edit function
        const obj = {};
        for (let i = 0; i < questionsArray[selectedIndex].subQuestion.length; i++) {
          obj[i + 1] = questionsArray[selectedIndex].subQuestion[i];
        }
        let allowedTypes = [Labels.MultipleChoice, Labels.MultiSelect, Labels.SingleSelect];
        if ((allowedTypes.includes(questionsArray[selectedIndex].type) && Object.keys(obj).length >= 2) || !allowedTypes.includes(questionsArray[selectedIndex].type)) {
          let question = {
            question: questionsArray[selectedIndex].question,
            type: questionsArray[selectedIndex].type,
            subQuestion: allowedTypes.includes(questionsArray[selectedIndex].type) ? obj : null,
            status: questionsArray[selectedIndex].status,
            jobPostingsId: questionsArray[selectedIndex].jobPostingsId,
          }
          dispatch(updateLoader(true));
          let getUserDetails = JSON.parse(localStorage.getItem("getAttributes"));
          const postData = await fetch(`${process.env.REACT_APP_JOBOPENING}${VENDOR_PORTAL_URL}/${id}`, {
            method: "PUT",
            body: JSON.stringify(question),
            headers: {
              "Content-Type": "application/json",
              "Customerid": getUserId,
              "jobCode":jobCode,
              "userId":getUserDetails[0].userId
            }
          });
          dispatch(updateSnackbar({
            type: (postData) ? "success" : 'error',
            message: (postData) ? "Question Updated Successfully" : "Data is not updated"
          }));
          // Data saved Successfully!
          dispatch(updateLoader(false));
          setIsEdit(null);
          onReceivechildProps(false, true)
        } else {
          dispatch(updateSnackbar({
            type: "error",
            message: "Please add at least two options"
          }));
        }
      } else {
        //save function
        const obj = {};
        for (let i = 0; i < questionsArray[selectedIndex].subQuestion.length; i++) {
          obj[i + 1] = questionsArray[selectedIndex].subQuestion[i];
        }
        let allowedTypes = [Labels.MultipleChoice, Labels.MultiSelect, Labels.SingleSelect];
        console.log("is alowed", allowedTypes.includes(questionsArray[selectedIndex].type))
        if ((allowedTypes.includes(questionsArray[selectedIndex].type) && Object.keys(obj).length >= 2) || !allowedTypes.includes(questionsArray[selectedIndex].type)) {
          let updatedQuestions = [...questionsArray];
          updatedQuestions[selectedIndex].id = questionsArray.length + 1;
          let question = {
            question: questionsArray[selectedIndex].question,
            subQuestion: allowedTypes.includes(questionsArray[selectedIndex].type) ? obj : null,
            type: questionsArray[selectedIndex].type,
            status: questionsArray[selectedIndex].status,
            jobPostingsId: questionsArray[selectedIndex].jobPostingsId ? questionsArray[selectedIndex].jobPostingsId : JobId
          }
          dispatch(updateLoader(true));
          let getUserDetails = JSON.parse(localStorage.getItem("getAttributes"));
          const postData = await fetch(`${process.env.REACT_APP_JOBOPENING}${VENDOR_PORTAL_URL}/${JobId}/questions`, {
            method: "POST",
            body: JSON.stringify(question),
            headers: {
              "Content-Type": "application/json",
              "Customerid": getUserId,
              "jobCode":jobCode,
              "userId":getUserDetails[0].userId
            }
          });
          dispatch(updateSnackbar({
            type: (postData) ? "success" : 'error',
            message: (postData) ? "Screening question added successfully" : "Data is not saved"
          }));
          // Data saved Successfully!
          dispatch(updateLoader(false));
          setQuestions([...updatedQuestions]);
          onReceivechildProps(false, true)
        } else {
          dispatch(updateSnackbar({
            type: "error",
            message: "Please add at least two options"
          }));
        }
      }
    } else {
      ValidateQuestions2.current.showMessages();
      ValidateType2.current.showMessages();
      ValidateOption2.current.showMessages();
      forceUpdate(true);
    }
  }

  const handleEdit = (index) => {
    setIsEdit(index);
  }
  const handleDelete = (index) => {
    onReceivechildProps(false, true);
  }

  return (
    <div>
      {questions && questions.length > 0 && questions.slice(0, 1).map((item, index) => (
        <div className='p-1 text-left' style={{marginTop: "-20px"}}>
          <div className='questionType'>
            <FormControl sx={{ m: 1, minWidth: 260 }} size="small" className='smalltextfeild'>
              <InputLabel id="demo-simple-select-autowidth-label" disabled={(item.id !== null && isEdit !== item.id)}>{Labels.Type}</InputLabel>
              <Select
                className='smalltextfeild'
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                autoComplete="off"
                value={item.type}
                label={Labels.Type}
                disabled={(item.id !== null && isEdit !== item.id)}
                onChange={(event) => handleQuestion(event, 'type', index)}
              >
                {questionTypes.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>

            </FormControl>
            <div className='validationright'>
              {ValidateType2.current.message(`Type `, item.type, "required")}
            </div>
          </div>
          <div className=''>
            <p className='question1'>{Labels.Question} </p>
          </div>
          <div className=' '>
            <div>
              <TextField sx={{
                width: "32.5rem",

              }} multiline={true}
                className='input-option'
                variant="outlined"
                autoComplete="off"
                label={`Question `}
                value={item.question}
                disabled={(item.id !== null && isEdit !== item.id)}
                onChange={(event) => handleQuestion(event, 'question', index)}
              />
              {ValidateQuestions2.current.message(`Question `, item.question, `required|min:1|max:${item.type === "Short Text" ? "500" : "1000"}`)}
            </div>

          </div>
          {/* options */}
          {(item.id === null || isEdit === item.id) && (item.type === Labels.SingleSelect || item.type === Labels.MultiSelect) && <div className="autoScroll">
            {item.subQuestion.map((option, optionIndex) => (
              <div className='mb-2 d-flex' style={{alignItems: "center"}}>
                <div>
                  <TextField sx={{
                    width: item.subQuestion.length > 4 ? "30rem" : "30rem",

                  }}
                    variant="outlined"
                    className='input-option'
                    autoComplete="off"
                    label={`Option `}
                    value={option}
                    disabled={(item.id !== null && isEdit !== item.id)}
                    onChange={(event) => handleOption(event, 'subQuestion', index, optionIndex)}
                  />
                  {ValidateOption2.current.message(`Option ${optionIndex + 1} `, option, "required|min:1|max:80")}
                </div>
                {item.subQuestion.length > 1 && <CloseIcon style={{cursor:"pointer"}} onClick={() => removeOption(index, optionIndex, 'subQuestion')} />
                  // <img src={DeleteIcon} alt="delete icon" className='cursor-pointer vendor-icon delete-icon'  />
                }
              </div>
            ))}
          </div>}

          {(item.type === Labels.MultiSelect || item.type === Labels.SingleSelect && item.subQuestion.length < 4) && <div className='d-flex  align-items-center' style={{justifyContent: "end"}}>
            <div onClick={() => addOption(index, item.type)} className="cursor-pointer addoption">
              <IconButton className='addiconbutton'>
                <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" className='circleplusicon' />
              </IconButton>
              <span>Add New Option</span>
            </div>
          </div>}

          <div className='d-flex justify-content-end align-items-center question-bts'>
            <div className='items-alignment cursor-pointer'>
              {/* <img src={DeleteIcon} alt="delete icon" className='vendor-icon delete-icon'  style={{ marginRight: 25 }} type="button" /> */}

              <Button variant="text" 
              className="buttonHover" 
              style={{ color: "red", fontWeight: "bolder", width:"96px"}}
              size='small'
              onClick={() => handleDelete(index)}>
                <Link href="#" className="cancel-btn" underline="always">Cancel</Link> 
              </Button>
              {(item.id === null || isEdit === item.id) ?
                // <button variant="contained" style={{ background: '#ffd800', color: 'black', borderRadius: 10, width: 117, height: 45, border: 0, fontSize: 22 }} className="saveBtn1" type="button" onClick={() => handleSubmit(item.id)}>Save</button>
                <Button className="buttonHover1" size="small" variant="contained" style={{ backgroundColor: "#ffd800",  fontSize:"14px", width:"96px", color: "black", borderRadius: "10px", fontWeight: "" }} onClick={() => handleSubmit(item.id)} >
                  Save
                </Button> :
                <EditImage handleEdit={handleEdit} item={item} />}
            </div>
          </div>
        </div >
      ))}
    </div >
  )
}
export default JobPortal

function EditImage({ handleEdit, item }) {
  const [over, setOver] = useState(false);
  return (<img src={over ? EditIcon2 : EditIcon} onMouseOver={() => setOver(true)} onMouseOut={() => setOver(false)} alt="edit icon" className='vendor-icon edit-icon' onClick={() => handleEdit(item.id)} />);
}