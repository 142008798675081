import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import "../ApplicantsNewDashboard.css";

const CardTitle = ({
  card,
  editMode,
  cardTitleChangeId,
  handleCardTitle,
  handleSaveCardTitle,
  handleDeleteCard,
  handleEditCard,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState("");
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setTitle(card.title);
  }, [card.title]);

  return (
    <>
      <div className="dashboard__card-row dashboard__card-header-section">
        {cardTitleChangeId === card.i ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "10px",
            }}
          >
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              label="Edit Card Title"
              size="small"
            />
            <Button
              variant="contained"
              className=" dashboard__toolbar-button--small"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                handleSaveCardTitle(card.i, title);
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center", width: "75%" }}>
            <Typography className="dashboard__card-header" variant="subtitle1">
              {card.title}
            </Typography>
          </div>
        )}
        {editMode && (
          <div>
            <IconButton
              aria-label="more"
              aria-controls={`card-menu-${card.i}`}
              aria-haspopup="true"
              className="dashboard__card-icon"
              onClick={(event) => handleMenuClick(event)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`card-menu-${card.i}`}
              anchorEl={anchorEl}
              open={open}
              onClick={() => handleMenuClose()}
            >
              <MenuItem
                className="dashboard__card-icons"
                onClick={() => handleCardTitle(card.i)}
              >
                <EditIcon className="dashboard__card-icon" fontSize="small" />{" "}
                Edit Card Title
              </MenuItem>
              <MenuItem
                className="dashboard__card-icons"
                onClick={() =>handleEditCard(card)}
              >
                <SettingsIcon
                  className="dashboard__card-icon"
                  fontSize="small"
                />{" "}
                Configure Card
              </MenuItem>
              <MenuItem
                className="dashboard__card-icons"
                onClick={() => handleDeleteCard(card.i)}
              >
                <DeleteIcon className="dashboard__card-icon" fontSize="small" />{" "}
                Delete Card
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(CardTitle);
