export const jobPostingMenus = [
  {
    id: 1,
    img: `${process.env.PUBLIC_URL}/assests/img/editing.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/editing1.svg`,
    menuName: "Job Details",
    url: "jobpostings/view",
    urls: "jobpostings/edit",
  },
  {
    id: 2,
    img: `${process.env.PUBLIC_URL}/assests/img/documenticon.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/documenticon1.svg`,
    menuName: "Documents",
    url: "jobpostings/documents",
  },
  {
    id: 3,
    img: `${process.env.PUBLIC_URL}/assests/img/Associatedapplicants.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/Associatedapplicants1.svg`,
    menuName: "Associated Applicants",
    url: "jobpostings/associatedapplicants",
  },
  {
    id: 4,
    img: `${process.env.PUBLIC_URL}/assests/img/portal.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/portalActive.svg`,
    menuName: "Portals",
    url: "jobpostings/portals/view",
    urls: "jobpostings/portals/edit",
  },
  {
    id: 5,
    img:`${process.env.PUBLIC_URL}/assests/img/notesicon.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/notesicon1.svg`,
    menuName: "Notes",
    url: "jobpostings/notes",
  },
  {
    id: 6,
    img: `${process.env.PUBLIC_URL}/assests/img/jobportal.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/jobportal1.svg`,
    menuName: "Screening Questions",
    url: "jobpostings/screeningquestions",
  },
  {
    id: 7,
    img:`${process.env.PUBLIC_URL}/assests/img/auditInfo-Inactive.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/auditInfo-Active.svg`,
    menuName: "Audit Info",
    url: "jobpostings/auditinfo",
  },
];


export const applicantsMenus = [
  {
    id: 1,
    img: `${process.env.PUBLIC_URL}/assests/img/editing.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/editing1.svg`,
    menuName: "Applicant Details",
    url: "applicants/view",
    urls: "applicants/edit",
  },
  {
    id: 2,
    img: `${process.env.PUBLIC_URL}/assests/img/documenticon.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/documenticon1.svg`,
    menuName: "Documents",
    url: "applicants/documents",
  },
  {
    id: 3,
    img: `${process.env.PUBLIC_URL}/assests/img/Associatedapplicants.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/Associatedapplicants1.svg`,
    menuName: "Associated Jobs",
    url: "applicants/associatedjobs",
  },
  {
    id: 4,
    img: `${process.env.PUBLIC_URL}/assests/img/notesicon.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/notesicon1.svg`,
    menuName: "Notes",
    url: "applicants/notes",
  },
  {
    id: 5,
    img: `${process.env.PUBLIC_URL}/assests/img/auditInfo-Inactive.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/auditInfo-Active.svg`,
    menuName: "Audit Info",
    url: "applicants/auditinfo",
  },
];

export const clientsMenus = [
  {
    id: 1,
    img: `${process.env.PUBLIC_URL}/assests/img/editing.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/editing1.svg`,
    menuName: "Clients Details",
    url: "clients/view",
    urls: "clients/edit",
  },
  {
    id: 2,
    img: `${process.env.PUBLIC_URL}/assests/img/auditInfo-Inactive.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/auditInfo-Active.svg`,
    menuName: "Audit Info",
    url: "clients/auditinfo",
  },
];

export const helpSectionMenus = [
  {
    id: 1,
    img: `${process.env.PUBLIC_URL}/assests/img/editing.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/editing1.svg`,
    menuName: "Job Postings",
    url: "helpsection/postings",
    urls: "helpsection/postings",
  },
  {
    id: 2,
    img:`${process.env.PUBLIC_URL}/assests/img/notesicon.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/notesicon1.svg`,
    menuName: "Applicants",
    url: "helpsection/applicant",
  },
  {
    id: 3,
    img: `${process.env.PUBLIC_URL}/assests/img/documenticon.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/documenticon1.svg`,
    menuName: "Email Templates",
    url: "helpsection/emailtemplates",
  }
];

export const customerMenus = [
  {
    id: 1,
    img: `${process.env.PUBLIC_URL}/assests/img/user-Inactive.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/user-Active.svg`,
    menuName: "Customer Details",
    url: "customer/view",
    // urls: "customers/edit",
  },
  {
    id: 2,
    img: `${process.env.PUBLIC_URL}/assests/img/groupUsers-Inactive.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/groupUsers-Active.svg`,
    menuName: "Users",
    url: "customer/users",
  },
  {
    id: 3,
    img: `${process.env.PUBLIC_URL}/assests/img/groupUsers-Inactive.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/groupUsers-Active.svg`,
    menuName: "Vendors",
    url: "customer/vendors",
  },
  {
    id: 4,
    img: `${process.env.PUBLIC_URL}/assests/img/settings.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/settingshover.svg`,  
    menuName: "Custom",
    url: "customer/custom",
  },
  {
    id: 5,
    img: `${process.env.PUBLIC_URL}/assests/img/jobboardportal_black.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/jobboardportal.svg`,  
    menuName: "Jobboard Portal",
    url: "customer/jobboardportal/view",
    urls: "customer/jobboardportal/edit",
  },
  {
    id: 6,
    img: `${process.env.PUBLIC_URL}/assests/img/mail-Inactive.svg`,
    activeImg: `${process.env.PUBLIC_URL}/assests/img/mail-Active.svg`,  
    menuName: "Mail",
    url: "customer/organize/mail",
    // urls: "customers/jobboardportal/edit",
  },
  ];


export const BreadCrumbData = [
  {
    id: 1,
    name: "View",
    url: "jobpostings/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/Group.svg`, //"/assests/img/Group.svg",
    type: "jobpostings"
    
  },
  {
    id: 2,
    name: "Edit",
    url: "jobpostings/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/Group.svg`,
    type: "jobpostings"
  },
  {
    id: 3,
    name: "Documents",
    url: "jobpostings/documents",
    icon: `${process.env.PUBLIC_URL}/assests/img/Group.svg`,
    type: "jobpostings"
  },
  {
    id: 4,
    name: "Associated Applicants",
    url: "jobpostings/associatedapplicants",
    icon:`${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "jobpostings"
  },
  {
    id: 5,
    name: "Notes",
    url: "jobpostings/notes",
    icon: `${process.env.PUBLIC_URL}/assests/img/editicondark.svg`,
    type: "jobpostings"
  },
  {
    id: 6,
    name: "Screening Questions",
    url: "jobpostings/screeningquestions",
    icon: `${process.env.PUBLIC_URL}/assests/img/Group.svg`,
    type: "jobpostings"
  },
  {
    id: 7,
    name: "Audit Info",
    url: "jobpostings/auditinfo",
    icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "jobpostings"
  },
 
  // applicants
  {
    id: 8,
    name: "View",
    url: "applicants/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/Group.svg`,
    type: "applicants"
  },
  {
    id: 9,
    name: "Edit",
    url: "applicants/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "applicants"
  },
  {
    id: 10,
    name: "Documents",
    url: "applicants/documents",
    icon: `${process.env.PUBLIC_URL}/assests/img/Group.svg`,
    type: "applicants"
  },
  {
    id: 11,
    name: "Associated Jobs",
    url: "applicants/associatedjobs",
    icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "applicants"
  },
  {
    id: 12,
    name: "Notes",
    url: "applicants/notes",
    icon: `${process.env.PUBLIC_URL}/assests/img/editicondark.svg`,
    type: "applicants"
  },
  {
    id: 13,
    name: "Audit Info",
    url: "applicants/auditinfo",
    icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "applicants"
  },
  // clients
  {
    id: 14,
    name: "View",
    url: "clients/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/Group.svg`,
    type: "clients"
  },
  {
    id: 15,
    name: "Edit",
    url: "clients/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "clients"
  },
  {
    id: 16,
    name: "Audit Info",
    url: "clients/auditinfo",
    icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "clients"
  },
// jobpostingsportals
  {
    id: 17,
    name: "Portals View",
    url: "jobpostings/portals/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "jobpostings"
  },
  {
    id: 18,
    name: "Portals Edit",
    url: "jobpostings/portals/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "jobpostings"
  },
// helpsection


  // {
  //   id: 19,
  //   name: "Portals Edit",
  //   url: "postings/portals/edit",
  //   icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
  //   type: "helpsection"
  // },
  // {
  //   id: 20,
  //   name: "Portals Edit",
  //   url: "postings/portals/edit",
  //   icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
  //   type: "helpsection"
  // },
  // {
  //   id: 21,
  //   name: "Portals Edit",
  //   url: "postings/portals/edit",
  //   icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
  //   type: "helpsection"
  // },
  //customer
  {
    id: 19,
    name: "View",
    url: "customer/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
    type: "customer"
  },
  {
    id: 20,
    name: "Users",
    url: "customer/users",
    icon: `${process.env.PUBLIC_URL}/assests/img/applicant.svg`,
    type: "customer"
  },
  {
    id: 21,
    name: "Vendors",
    url: "customer/vendors",
    icon: `${process.env.PUBLIC_URL}/assests/img/applicant.svg`,
    type: "customer"
  },
  {
    id: 22,
    name: "Custom",
    url: "customer/custom",
    icon: `${process.env.PUBLIC_URL}/assests/img/settings.svg`,
    type: "customer"
  },
  {
    id: 23,
    name: "Jobboard Portal View",
    url: "customer/jobboardportal/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/settings.svg`,
    type: "customer"
  },
  {
    id: 24,
    name: "Jobboard Portal Edit",
    url: "customer/jobboardportal/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/settings.svg`,
    type: "customer"
  },
  {
    id: 25,
    name: "Mail",
    url: "customer/organize/mail",
    icon: `${process.env.PUBLIC_URL}/assests/img/mail.svg`,
    type: "customer"
  },
  //customer

];

export const BreadCrumbObj = [
  {
    id: 1,
    name: "Job Postings",
    url: "jobpostings/landing",
    icon: "",
    type: ""
  },
  {
    id: 2,
    name: "Applicants",
    url: "applicants/landing",
    icon: "",
    type: ""
  },
  {
    id: 3,
    name: "Clients",
    url: "clients/landing",
    icon: "",
    type: ""
  },
  {
    id: 4,
    name: "HelpSection",
    url: "helpsection/postings",
    icon: "",
    type: ""
  },
  {
    id: 5,
    name: "customer",
    url: "customer/view",
    icon: "",
    type: ""
  }
]