import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled
} from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import SearchBar from "material-ui-search-bar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateJobCode } from "../../_redux/SeletedJobCodeSlice";
import { updateSnackbar } from "../../_redux/SnackbarSlice";
import "./DynamicTableStyle.css";
import Pagination from "./Pagination";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

const DynamicTable = ({
  config,
  data,
  colmns,
  actionDropdown,
  filterValue,
  actions,
  noDataText,
  onReceiveActionProps,
  searchKeyWord,
  addModel,
  statusValues,
  selectedId
}) => {
  const [tableData, setTableData] = useState(data);
  const [checked, setChecked] = useState(false);
  const [action, setAction] = useState("Action");
  const [deleteId, setDeleteId] = useState(null);
  const [searchValue, setSearchValue] = useState(filterValue);
  const [isInterminate, setIsInterminate] = useState(false);
  const tableRawData = JSON.stringify(data);
  const currentPage = config.currentPage;
  const showLoader = useSelector((state) => state.Loader.value);
  const showUncommonLoader = useSelector((state) => state.UncommonLoader.value);
  const [open, setOpen] = useState(false);
  const [associateDropdown, setAssociateDropdown] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const usersPerPage = config.recordperPage;
  const [totalRecords, setTotalRecords] = useState(config.totalRecords);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const filteropen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlefilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {

  }, [searchValue]);

  useEffect(() => {
    setTableData(data);
    setTotalRecords(config.totalRecords);
    if(data.length && data && data !== "null"){
      let filterData = data.filter((x) => x.isChecked === true);
        if (filterData.length === data.length) {
          setChecked(true);
        }else{
          setChecked(false);
        }
    }
  }, [data,config]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#ebc700' : '#308fe8',
    },
  }));

  const onCheckedHandler = (e, displayUser) => {
    const data = JSON.parse(JSON.stringify(tableData));
    let index = data.findIndex((x) => x.id === displayUser.id);
    if (index !== -1) {
      data[index].isChecked = !data[index].isChecked;
      setTableData(data);
      isSelectAll("individual", e.target.checked, data);
    }
  };

  useEffect(() => {
    if (config.isPagingServer === false) {
      setTableData(data.slice((config.currentPage - 1) * usersPerPage, config.currentPage * usersPerPage));
    }
  }, [config]);

  useEffect(() => {
    isSelectAll("all", null, null);
  }, [checked]);

  useEffect(() => {
  }, [tableData]);

  const isSelectAll = (type, boolean, data) => {
    if (type === "all") {
      setIsInterminate(false);
    } else if (type === "individual") {
      setChecked(false);
    } else {
      let filterData = data.filter((x) => x.isChecked === boolean);
      if (filterData.length === data.length) {
        setIsInterminate(false);
      } else {
        setIsInterminate(true);
      }
    }
  };

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    let Data = tableData;
    Data.forEach((item) => {
      item.isChecked = isChecked;
      if (item.length <= 1) {
        setIsInterminate(false);
      }
    });
    setTableData(Data);
  };

  const onSearchhandler = (value, type) => {
    if (value !== "" && type !== "onChange") {
      value = value.trimStart();
      // value = value.replace(/[^a-zA-Z0-9&%$/-_= .( ) -]/g, "");
      setSearchValue(value);
      if (config.isSearchServer === true) {
        if (value !== "" && value.length >= 2) {
          let obj1 = {
            current:
              config.currentPage !== 1
                ? (config.currentPage - 1) * config.limitData + 1
                : config.currentPage,
            keyword: value,
          };
          onReceiveActionProps("searchResult", obj1);
        } else if (value.length < 2 && type === "onRequestSearch") {
          dispatch(
            updateSnackbar({
              type: "error",
              message: "Required Minimum 2 Characters",
            })
          );
        } else if (value === "") {
          onReceiveActionProps("cancelSearchResult", null);
        }
      } else {
        const rawData = JSON.parse(tableRawData);
        let data = [];
        if (value.length >= 2) {
          if (rawData[0]?.documentType) {
            data = rawData.filter((x) =>
              ["name"].some((k) =>
                String(x[k]).toLowerCase().includes(value.toLowerCase())
              )
            );
          } else if (rawData[0]?.sourcedFrom) {
            data = rawData.filter((x) =>
              ["applicantCode", "firstName", "lastName", "contactNumber", "jobCode", "jobTitle"].some(
                (k) => String(x[k]).toLowerCase().includes(value.toLowerCase())
              )
            );
          }
          // else if (rawData[0]?.location) {
          //   data = rawData.filter((x) =>
          //     ["jobCode", "jobTitle"].some((k) =>
          //       String(x[k]).toLowerCase().includes(value.toLowerCase())
          //     )
          //   );
          // }
          else {
            data = rawData.filter((x) =>
              Object.keys(x).some((k) =>
                String(x[k]).toLowerCase().includes(value.toLowerCase())
              )
            );
          }
          if (data.length > 0) {
            // let total = JSON.parse(JSON.stringify(totalRecords))
            setTotalRecords(data.length);
            setTableData(data);
          } else {
            setTableData("");
            setTotalRecords(0);
          }
        } else if (value.length < 2 && type === "onRequestSearch") {
          dispatch(
            updateSnackbar({
              type: "error",
              message: "Required Minimum 2 Characters",
            })
          );
        } else {
          setTableData(rawData);
          setTotalRecords(rawData.length);
        }
      }
    } else if (value === "" && config.isSearchServer === true) {
      onReceiveActionProps("cancelSearchResult", null);
    } else if (value === "" && config.isSearchServer === false) {
      onReceiveActionProps("cancelSearchResult", null);
      let rawData = JSON.parse(tableRawData);
      setTableData(rawData);
      setTotalRecords(rawData.length);
    }
  };

  const onSelectActionHandler = (type) => {
    setAction(type);
    if (type === "Delete") {
      setOpen(false);
      if (tableData.length > 0) {
        let getData = tableData.filter((user) => user.isChecked === true);
        if (getData.length > 0) {
          setOpen(true);
          setDeleteId(getData);
        } else {
          dispatch(
            updateSnackbar({ type: "error", message: "No Record Selected" })
          );
        }
      } else {
        dispatch(
          updateSnackbar({ type: "error", message: "No Record Selected" })
        );
      }
      setTimeout(() => {
        setAction("Action");
      }, 100);
    } else if (
      type === "All Applicants" ||
      type === "ATS Applicants" ||
      type === "Job Portal Applicants" ||
      type === "Associate Applicants"
    ) {
      setAction(type);
      setOpen(false);
      onReceiveActionProps(type, null);
    } else if (type === "Associate Applicant" || type === "Remove Applicants") {
      setAction(type);
      setOpen(false);
      let getData = tableData.filter((user) => user.isChecked === true);
      if (getData.length > 0) {
        onReceiveActionProps(type, getData);
      }
    } else if (type === "Download") {
      let getData = tableData.filter((user) => user.isChecked === true);
      if (getData.length > 0) {
        onReceiveActionProps(type, getData);
      }
    } else if (type === "Accept" || type === "Reject"){
      let getData = tableData.filter((user) => user.isChecked === true);
      if (getData.length > 0) {
        onReceiveActionProps(type, getData);
      } else {
          dispatch(
            updateSnackbar({ type: "error", message: "No Record Selected" })
          );
        }
        setTimeout(()=>{
          setAction("Action");
        },200)
    } else if(type === "Send Email" || type === "Send SMS"){
      let getData = tableData.filter((user) => user.isChecked === true);
      if (getData.length > 0) {
                 onReceiveActionProps(type, getData);
              } else {
          dispatch(
            updateSnackbar({ type: "error", message: "No Record Selected" })
          );
        }
      // onReceiveActionProps(type, true);
      setTimeout(() => {setAction("Action") }, 100);
    } else if(type === "Update Status") {
      let getData = tableData.filter((user) => user.isChecked === true);
      if (getData.length > 0) {
        onReceiveActionProps(type, getData);
     } else {
      dispatch(updateSnackbar({ type: "error", message: "No Record Selected" }) );
     }
     setTimeout(() => {setAction("Action") }, 100);
    } 
  };

  const handleActionClick = (obj, type, HandlerType) => {
    if (type === "edit") {
      dispatch(updateJobCode(obj));
      let Name = obj.jobTitle
        ? `${obj.jobTitle} (${obj.jobCode})`
        : (obj.firstName || obj.lastName)
        ? `${obj.firstName} ${obj.lastName}` 
        : obj.clientName;
      let newobj = { id: obj.id, name : Name, type : obj.type };
      let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
      if(subPathObj && subPathObj.length > 0){
        let index = subPathObj.findIndex(x => x.id === newobj.id);
        if(index !== -1){
          subPathObj[index] = newobj;
        } else {
          subPathObj.push(newobj);
        }
      } else {
        subPathObj = [newobj];
      }
      if (obj.nagigate !== "detailspage") {
     

          localStorage.setItem("subpathObj", JSON.stringify(subPathObj));
        
        // navigate(`${obj.nagigate}/${obj.id}`);
      } else {
        onReceiveActionProps("detailspage", obj);
      }
    } else if (type === "delete") {
      setOpen(true);
      setDeleteId(obj);
    } else if (type === "confirmDel" && deleteId !== null) {
      setOpen(false);
      onReceiveActionProps(type, deleteId);
    } else if (type === "download") {
      onReceiveActionProps(type, obj);
    } else if (type === "pagination" && config.isPagingServer === true) {
      let obj1 = {
        current: obj,
        offset: obj !== 1 ? (obj - 1) * config.limitData + 1 : 1,   //obj
        limit: config.limitData,
      };
      onReceiveActionProps(type, obj1);
    } else if (type === "pagination" && config.isPagingServer === false) {
      let DataNew = JSON.parse(tableRawData);
      // setTableData(DataNew.slice((((obj - 1) * usersPerPage) + ((obj === 1) ? 0 : 1)), ((obj * usersPerPage) + ((obj === 1) ? 0 : 1))));
      setTableData(DataNew.slice((obj - 1) * usersPerPage, obj * usersPerPage));
      config.currentPage = obj;
    } else if (type === "view") {
      onReceiveActionProps(type, obj);
    } else if(type === "viewAnswers"){
      onReceiveActionProps(type, obj);
    }else if(type === 'pageRange'){
      onReceiveActionProps(type, obj);
    } else if (type === "associateApplicant") {
      onReceiveActionProps(type, obj);
    }
  };

  const onChangeHandler = (value, rowData, type) => {
    if(type === "associatejobStatus"){
      // setAssociateDropdown(value);
      if (tableData.length > 0) {
            let findIndex = tableData.findIndex((user) => user.jobCode === rowData.jobCode);
            if (findIndex !== -1) {
              tableData[findIndex].associatejobStatus = value;
            }
            setTableData(tableData);
            onReceiveActionProps(type, tableData[findIndex]);
          }
    }
  }
  
  return (
    <>
      {config.isDropdownActions || config.isSearchbar ? (
        <div className={config.isDropdownActions && config.isSearchbar && addModel.displayName == "" ? "actionSectionAssoc" : !config.isDropdownActions && addModel.displayName == "" && config.isCheckbox ? "actionSectionAssocReject" : "actionSection"}>
          <div className="actionDropdowncss">
          <FormControl style={{ textDecorationColor: "black", width: "32%" }}>
            {config.isDropdownActions ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="actionDropdown"
                value={action}
                label=""
                style={{ paddingLeft: "20px", width: "242px" }}
                onChange={(e) => onSelectActionHandler(e.target.value)}
              >
                {actionDropdown.map((list, i) => (
                  <MenuItem
                    style={{
                      display: "grid",
                      justifyContent: "left",
                      padding: "6px 30px",
                      cursor: "pointer",
                      width: "242px",
                    }}
                    key={i}
                    value={list}
                  >
                    {list}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              ""
            )}
          </FormControl>
          </div>
          <div className="searchdivcss">
            {config.isSearchbar ? (
              <SearchBar
                className="SearchBar"
                autoFocus
                autocomplete="off"
                onChange={(newValue) => onSearchhandler(newValue, "onChange")}
                onRequestSearch={(newValue) =>
                  onSearchhandler(newValue, "onRequestSearch")
                }
                onCancelSearch={() => onSearchhandler("", "onCancelSearch")}
                placeholder={searchKeyWord}
                value={searchValue}
              />
            ) : (
              ""
            )}
          </div>
          <div className="addcomponent">
            {addModel.displayName && (
              <div
                className="addicon"
                style={{ marginBottom: "15px", marginLeft: "47px" }}
                onClick={() =>
                  addModel.url !== ""
                    ? navigate(addModel.url)
                    : onReceiveActionProps("newApplicants", true)
                }
              >
                <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" />
                <h4
                  style={{
                    marginLeft: "9px",
                    marginLeft: "9px",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "18px",
                    color: "#3B4046",
                  }}
                >
                  {" "}
                  {addModel.displayName}
                </h4>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          className="customizedTable"
        >
          <TableHead>
            <TableRow>
              {config.isCheckbox ? (
                <StyledTableCell
                  style={{ width: 50 }}
                  className={config.ischeckAlignRight ? "col-align-right" : ""}
                >
                  {tableData.length !== 0 && (
                    <Checkbox
                      checked={checked ? true : false}
                      onChange={handleChange}
                      indeterminate={isInterminate}
                    />
                  )}
                </StyledTableCell>
              ) : (
                ""
              )}
              {colmns.map((list, k) => {
                // eslint-disable-next-line
                if (list.displayName !== "Action" && list.mappingName !== "screeningquestions") {
                  return (
                    <StyledTableCell
                      key={k}
                      className={list.theadClass}
                      width={list.width}
                    >
                      {list.mappingName === " " ? (
                        <>
                          {" "}
                          {list.displayName}
                          <FilterListIcon
                            fontSize="small"
                            style={{
                              verticalAlign: "text-top",
                              marginLeft: "12px",
                              cursor: "pointer",
                            }}
                            onClick={handleClick}
                          />
                          <Menu
                            anchorEl={anchorEl}
                            className="filterMenuList"
                            open={filteropen}
                            onClose={handlefilterClose}
                          >
                            <MenuItem
                              onClick={(e) =>
                                onSelectActionHandler("All Applicants")
                              }
                            >
                              All Applicants
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                onSelectActionHandler("ATS Applicants")
                              }
                            >
                              ATS Applicants
                            </MenuItem>
                            <MenuItem
                              onClick={(e) =>
                                onSelectActionHandler("Job Portal Applicants")
                              }
                            >
                              Job Portal Applicants
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        list.displayName
                      )}
                    </StyledTableCell>
                  );
                } else if(list.mappingName === "screeningquestions") {
                    return (
                      <StyledTableCell key={k} className={list.theadClass}>
                      {list.displayName}
                    </StyledTableCell>
                    )
                }else if (config.isActions && list.displayName === "Action") {
                  return (
                    <StyledTableCell key={k} className={list.theadClass}>
                      {list.displayName}
                    </StyledTableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          {typeof tableData !== "string" && tableData.length > 0 && (
            <TableBody>
              {tableData.map((rowData, i) => (
                <StyledTableRow key={i}>
                  {config.isCheckbox ? (
                    <StyledTableCell
                      component="th"
                      scope="row"
                      key={i}
                      className={
                        config.ischeckAlignRight ? "col-align-right" : ""
                      }
                    >
                      <Checkbox
                        checked={rowData.isChecked ? true : false}
                        onChange={(e) => onCheckedHandler(e, rowData)}
                      />
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                  {colmns.map((list, j) => {
                    if (list.displayName !== "Action" && list.isEdit === true && list.mappingName !== "screeningquestions") {
                      return (
                        <StyledTableCell
                          className={`${list.tbodyClass} activeRow`}
                          width={list.width}
                          key={j}
                          
                        >
                          <span onClick={() =>
                            handleActionClick({ ...list, ...rowData }, "edit")
                          }>
                          {list.nagigate !== "detailspage" ? (
                            <a
                              className="rm-link"
                              href={`${list.nagigate}/${rowData.id}`}
                              onContextMenu={() =>
                                handleActionClick(
                                  { ...list, ...rowData },
                                  "edit"
                                )
                              }
                            >
                              {rowData[list.mappingName]}
                            </a>
                          ) : (
                            rowData[list.mappingName]
                          )}
                          </span>
                        </StyledTableCell>
                      );
                    } else if (
                      list.displayName !== "Action" && list.mappingName !== "documentType" && list.mappingName !== "documentName" &&
                      list.isEdit !== true && list.mappingName !== "screeningquestions" && list.mappingName !== "percentage" && list.mappingName !== "associatejobStatus"
                    ) {
                      return (
                        <StyledTableCell
                          className={list.tbodyClass}
                          width={list.width}
                          key={j}
                          // style={{ cursor: "pointer" }}
                        >
                          {list.displayName === "Applicant ID" ? (
                            <span
                            // className="activeHyperLink"
                            // onClick={() =>
                            //   handleActionClick(rowData, "download")
                            // }
                            >
                              {rowData[list.mappingName]}
                            </span>
                          ) : (
                            <span>{rowData[list.mappingName]}</span>
                          )}
                        </StyledTableCell>
                      );
                    } else if(list.mappingName === "screeningquestions") {
                      return (
                        <StyledTableCell className="screeningQuestionsCountAlign" key={j} width={list.width}>
                        {rowData[list.mappingName]}
                        { rowData[list.mappingName] && !rowData[list.mappingName].startsWith("0/") && (
                          <img src={process.env.PUBLIC_URL + "/assests/viewIcon.svg"} width={17} alt={rowData[list.mappingName]} onClick={() => handleActionClick(rowData, "viewAnswers")} />
                        )}
                      </StyledTableCell>
                      )
                    } else if (
                      config.isActions &&
                      list.displayName === "Action"
                    ) {
                      return (
                        <StyledTableCell
                          className={list.tbodyClass}
                          key={j}
                          width={list.width}
                        >
                          <div className="postingdocumentsicons">
                            {actions.isDownload ? (
                              <Button
                                variant="icon"
                                className="downloadicon"
                                style={{ marginRight: "10px" }}
                                onClick={() =>
                                  handleActionClick(rowData, "download")
                                }
                              >
                                <SaveAltIcon />
                              </Button>
                            ) : (
                              ""
                            )}

                            {actions.isPositingsView ? (
                              <Button
                                variant="icon"
                                className="viewicon"
                                onClick={() =>
                                  handleActionClick(rowData, "view")
                                }
                              >
                                <svg
                                  id="Component_160_2"
                                  data-name="Component 160 – 2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21.485"
                                  height="23.966"
                                  viewBox="0 0 21.485 23.966"
                                >
                                  <path
                                    id="file"
                                    d="M8.8,23.03a.936.936,0,0,1-.936.936H3.745A3.749,3.749,0,0,1,0,20.222V3.745A3.749,3.749,0,0,1,3.745,0h11.51A3.749,3.749,0,0,1,19,3.745v8.941a.936.936,0,0,1-1.872,0V3.745a1.875,1.875,0,0,0-1.872-1.872H3.745A1.875,1.875,0,0,0,1.872,3.745V20.222a1.875,1.875,0,0,0,1.872,1.872H7.864A.936.936,0,0,1,8.8,23.03ZM14.318,5.617H4.676a.936.936,0,1,0,0,1.872h9.643a.936.936,0,0,0,0-1.872Zm.936,4.681a.936.936,0,0,0-.936-.936H4.676a.936.936,0,1,0,0,1.872h9.643A.936.936,0,0,0,15.255,10.3ZM4.676,13.107a.936.936,0,1,0,0,1.872H9.6a.936.936,0,1,0,0-1.872Zm16.639,6.857c-.033.047-.146.207-.216.295a13.174,13.174,0,0,1-2.069,2.133,6.42,6.42,0,0,1-4,1.575,6.42,6.42,0,0,1-4-1.575,13.17,13.17,0,0,1-2.069-2.133c-.07-.088-.183-.248-.216-.295a.936.936,0,0,1,0-1.076c.033-.047.146-.207.216-.295a13.171,13.171,0,0,1,2.069-2.132,6.42,6.42,0,0,1,4-1.575,6.42,6.42,0,0,1,4,1.575A13.17,13.17,0,0,1,21.1,18.593c.07.088.183.248.216.295A.936.936,0,0,1,21.315,19.964Zm-1.953-.538c-1.489-1.771-2.946-2.668-4.337-2.668s-2.848.9-4.337,2.668c1.489,1.771,2.946,2.668,4.337,2.668S17.874,21.2,19.362,19.426Zm-4.29-1.779a1.779,1.779,0,1,0,1.779,1.779A1.779,1.779,0,0,0,15.073,17.647Zm0,0"
                                    fill="#3b4046"
                                  />
                                </svg>
                              </Button>
                            ) : (
                              ""
                            )}
                            {actions.isPostingsDownload ? (
                              <Button
                                variant="icon"
                                className="documentdownloadicon"
                                onClick={() =>
                                  handleActionClick(rowData, "download")
                                }
                              >
                                <svg
                                  id="surface1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21.617"
                                  height="23.966"
                                  viewBox="0 0 21.617 23.966"
                                >
                                  <path
                                    id="Path_24419"
                                    data-name="Path 24419"
                                    d="M237.853,18.217H240.5l-4.049-4.26v2.851A1.409,1.409,0,0,0,237.853,18.217Zm0,0"
                                    transform="translate(-225.381 -13.304)"
                                    fill="#3b4046"
                                  />
                                  <path
                                    id="Path_24420"
                                    data-name="Path 24420"
                                    d="M192.612,236.336a6.452,6.452,0,1,0,6.452,6.452A6.458,6.458,0,0,0,192.612,236.336Zm3.466,7-3.164,3.4a.432.432,0,0,1-.319.135.42.42,0,0,1-.313-.135l-3.158-3.4a.434.434,0,0,1,.022-.61.44.44,0,0,1,.615.022l2.419,2.591V239.17a.432.432,0,0,1,.864,0v6.171l2.408-2.591a.43.43,0,1,1,.626.589Zm0,0"
                                    transform="translate(-177.446 -225.273)"
                                    fill="#3b4046"
                                  />
                                  <path
                                    id="Path_24421"
                                    data-name="Path 24421"
                                    d="M8.082,15.657H3.39a.432.432,0,1,1,0-.864H8.368a7.669,7.669,0,0,1,1.1-1.89H3.39a.432.432,0,0,1,0-.864h6.895a7.433,7.433,0,0,1,4.886-1.857c.178,0,.324.016.54.032V5.777H12.471A2.275,2.275,0,0,1,10.2,3.5V0H1.922A1.932,1.932,0,0,0,0,1.949V18.427a1.914,1.914,0,0,0,1.922,1.927H8.428a7.374,7.374,0,0,1-.345-4.7ZM3.39,9.34H8.86a.432.432,0,0,1,0,.864H3.39a.432.432,0,0,1,0-.864Zm0,0"
                                    fill="#3b4046"
                                  />
                                </svg>
                              </Button>
                            ) : (
                              ""
                            )}
                            {actions.isDelete ? (
                              <Button
                                variant="icon"
                                className="deleteicon"
                                onClick={() =>
                                  handleActionClick(rowData, "delete")
                                }
                              >
                                <DeleteIcon />
                              </Button>
                            ) : (
                              ""
                            )}
                            {actions.isRadiobtn ? (
                              <Button className="checkCircleicon" 
                              variant="icon"
                              onClick={() => handleActionClick(rowData, "associateApplicant")}>

                               {selectedId === (rowData.id ? rowData.id : rowData.docsid) && selectedId !== ""
                                ? <img src={process.env.PUBLIC_URL + "/assests/radio.svg"} width={16} className="radiobtnActive" alt={""}/> : <PanoramaFishEyeIcon />} 
                            </Button> 
                            ) : (
                              ""
                            )}
                          </div>
                        </StyledTableCell>
                      );
                    } else if ( config.isActions == false &&
                      list.mappingName === "percentage" ) {
                        return (
                        <StyledTableCell 
                        className={list.tbodyClass}
                        key={j}
                        width={list.width}>
                        {list.mappingName === "percentage" ? ( 
                        <div className="percentbar">
                        <BorderLinearProgress className="progress" variant="determinate" value={rowData[list.mappingName]}/><p>{rowData[list.mappingName]+"%"}</p>
                        </div>
                        ): ("")}
                        </StyledTableCell>
                        );
                    } else if ( config.isActions == true &&
                      list.mappingName === "associatejobStatus" && list.nagigate === null ) {
                        return (
                        <StyledTableCell 
                        className={list.tbodyClass}
                        key={j}
                        width={list.width}>
                        {list.mappingName === "associatejobStatus" ? ( 
                        <div className="statusDropdown">
                      <Select
                        value={rowData[list.mappingName]}
                        size="small"
                        name="associatejobStatus"
                        autoComplete="off"
                        onChange={(e) => onChangeHandler(e.target.value, rowData, "associatejobStatus")}
                        style={{
                          width: "85%",
                          borderRadius: "4px",
                          textAlign:"left",
                          paddingLeft:"4%"
                        }}
                      >
                        {statusValues.map((name) => (
                          <MenuItem className="selectOptions" key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                        </div>
                        ): ("")}
                        </StyledTableCell>
                        );
                    } else if (( config.isActions == false && list.mappingName === "documentName")  ||
                    (config.isActions == true && list.mappingName === "documentType")) {
                        return (
                        <StyledTableCell 
                        className={list.tbodyClass}
                        key={j}
                        width={list.width}>
                        {list.mappingName === "documentType"  || list.mappingName === "documentName" ? ( 
                        <div className="documentTypecss" >
                        {rowData["fileType"] === "pdf" ? <img src={process.env.PUBLIC_URL + "/assests/img/pdf.svg"} alt="" className='pdficon' /> : <img src={process.env.PUBLIC_URL + "/assests/img/docx.svg"} alt="" className='pdficon' />}
                        <p>{rowData[list.mappingName]}</p>
                        </div>
                        ): ("")}
                        </StyledTableCell>
                        );
                    } 
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          )}
          {typeof tableData !== "string" && tableData.length === 0 && (
            <StyledTableRow>
              <StyledTableCell
                colSpan={config.isCheckbox ? colmns.length + 1 : colmns.length}
              >
                {(!showLoader && !showUncommonLoader) && (
                  <div className="nodatasection">
                    <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noData" />
                    {noDataText}
                  </div>
                )}
              </StyledTableCell>
            </StyledTableRow>
          )}
          {typeof tableData === "string" && (
            <StyledTableRow>
              <StyledTableCell
                colSpan={config.isCheckbox ? colmns.length + 1 : colmns.length}
              >
                {(!showLoader && !showUncommonLoader) && (
                  <div className="nodatasection">
                    <img src={process.env.PUBLIC_URL + "/assests/NoResult.svg"} alt="noResult" />
                    No match Found
                  </div>
                )}
              </StyledTableCell>
            </StyledTableRow>
          )}
        </Table>
      </TableContainer>
      <div className="pagingRight">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage ? currentPage : 0}
          totalCount={totalRecords}
          pageSize={usersPerPage}
          onPageChange={(page) => handleActionClick(page, "pagination")}
        />
        {config.isPageRange?
      <Select
      id="demo-simple-select"
      onChange={(e) => handleActionClick(Number(e.target.value), "pageRange")} value={config.limitData} className="selectData"
    >
      <MenuItem className="selectOptions" value='10'>10</MenuItem>
      <MenuItem className="selectOptions" value='20'>20</MenuItem>
      <MenuItem className="selectOptions" value='50'>50</MenuItem>
    </Select>  
      :""}
        
      </div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog"
        style={{ marginTop: "-26%" }}
      >
        <Box sx={{ width: "395px" }}>
          <DialogTitle className="diabtn">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText className="diabtnjob" id="alert-dialog">
              <p className="btntext">Are you sure you want to delete ?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="alert-actionbtns">
            <Button onClick={handleClose}><Link href="#" color="black" underline="always">Cancel</Link></Button>
            <Button onClick={() => handleActionClick(null, "confirmDel")}>
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default React.memo(DynamicTable);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    paddingTop: "14px",
    paddingBottom: "14px",
    borderRight: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: "11px",
    paddingBottom: "11px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // "&:last-child td": {
  //   borderRight: "1px solid #E8E8E8",
  // },
}));
