import React, { useState } from "react";
import { Card, CardContent, Switch } from "@mui/material";
import DynamicTable from "../../../../components/DynamicTable/DynamicTable";
import "../ApplicantsNewDashboard.css";
import { configuration, columnsData } from "../widget";
import CardTitle from "../CardTitle/CardTitle";

const TableWidgetCard = ({
  card,
  showToggle = false,
  switchChange,
  editMode,
  handleSaveCardTitle,
  handleDeleteCard,
  handleEditCard,
  handleCardTitle,
  cardTitleChangeId,
}) => {
  const data = card.data;
  const [toggle, switchToggle] = useState(false);
  return (
    <Card className="dashboard__card">
      <CardTitle
        card={card}
        editMode={editMode}
        handleSaveCardTitle={handleSaveCardTitle}
        handleDeleteCard={handleDeleteCard}
        handleEditCard={handleEditCard}
        handleCardTitle={handleCardTitle}
        cardTitleChangeId={cardTitleChangeId}
      />
      <CardContent style={{ position: "relative" }}>
        {showToggle && (
          <div style={{ textAlign: "right" }}>
            <div className="switch-container">
              <span className="text">Individual</span>
              <Switch
                checked={toggle}
                name="pieChartIndividualORTeam"
                onChange={() => {
                  const value = !toggle;
                  switchToggle(value);
                  switchChange(value);
                }}
              />
              <span className="text">Team</span>
            </div>
          </div>
        )}
        {data?.length > 0 ? (
          <DynamicTable
            config={configuration}
            data={data || []}
            colmns={columnsData}
            actionDropdown={[]}
            actions={[]}
            filterValue={""}
            noDataText={"No Postings found"}
            addModel={{}}
            searchKeyWord=""
          />
        ) : (
          <div className="dashboard__card-no-data-container">
            No data available
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default React.memo(TableWidgetCard);
