// export const BASE_URL = process.env.REACT_APP_BASE_URL || `http://3.97.4.103:2526`
export const BASE_URL = `http://api-lb-561452931.ap-south-2.elb.amazonaws.com:2003`
// `http://3.97.4.103:2003`
 //`http://localhost:2526`     //`http://localhost:2526`

export const BASEURL = `http://3.97.4.103:3000`

export const JOB_POSTING_URL = `/mgmt/jobopening`
export const JOB_ASSOCIATED_APPLICANTS = `/mgmt/jobopening`
export const GET_ALL_CLIENTS = `/mgmt/jobopening/getclients`
export const APPLICANTS_ASSOCIATED_APPLICANTS = `mgmt/jobseeker`
export const VENDOR_PORTAL_URL = `/mgmt/jobopening`
export const VENDOR_PORTAL_URL_ID = (id) => `/mgmt/jobopening/${id}/vendorportal`
export const Countries_List = `/getcountries`
export const getStates_India = `/getstatebycountry`
export const ALL_APPLICANTS_URL = `/mgmt/jobseeker`

export const ALL_CLIENTS = `/mgmt/client`
export const CLIENTS_CREATE = `/mgmt/client`

export const ACCESSKEY_ID = "AKIATX6D76SIAIMOXCFI"
export const SECRETACCESS_KEY = "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"

export const countries1 = `/mgmt/utils/countries`;
export const countries = `/mgmt/countries`;
export const Portals = `/mgmt/customer/portaltype`;

