import React, { useState, useEffect } from 'react';
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from "@material-ui/core";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
// pages
import './ApplicantsTable.css'
import { getUserDetails } from '../../../_redux/CognitoSlice'
import { updateAuth } from "../../../_redux/AuthSlice";
import * as _ser from '../../../_redux/_services/ApplicantsSlice';
import RightSideBar from "../../../components/Rightsidebar/RightSideBar";
import { Encrypt, decrypt } from '../../../_utilities/_encryptDecryptHelper';
import { ApplicantsRenderData } from './ApplicantsRenderData';
import { Button, MenuItem, Select, InputLabel,  Box, Chip, FormControl } from "@mui/material";
import { Theme, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { TrendingUpRounded } from '@material-ui/icons';
import CloseIcon from '@mui/icons-material/Close';
import ApplicantsManualUploadPopup from './ApplicantsManualUploadPopup';
import DuplicateApplicantsManualUploadPopup from './DuplicateApplicantsManualUploadPopup';
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import SendSms from '../../../components/SendSms/SendSms';

import { customFieldsAsync, customFieldsStartReducer } from '../../../_redux/_services/UserManagerSlice';

const initalState = { data: null, message: '', isloader: true }
const initalFilter = { jobtitle: '', yearsExperienceMin: '', yearsExperienceMax: '' }

const TableApplicants = () => {

  const [users, setUsers] = useState([])
  const [config, setConfig] = useState(configuration);
  const [filterValue, setFilterValue] = useState("");
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);
  const [isBulkuploadResponse, setIsBulkuploadResponse] = useState(false);
  const [bulkUploadMessage, setBulkUploadMessage] = useState({});
  const [isAdvancePannel, setIsAdvancePannel] = useState(false);
  const [advanceSearchlist, setAdvanceSearchlist] = useState(advanceSearchModel)
  const [skillsValue, setSkillsValue] = useState("");
  const [skillsObj, setSkillsObj] = useState([]);
  const [filterObj, setFilterObj] = useState(JSON.parse(JSON.stringify(initalFilter)));
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const [manualApplicatsConfirmPopup, setManualApplicatsConfirmPopup] = useState(false);
  const [ updateProfileWithNewResume, setUpdateProfileWithNewResume ] = useState(null)
  const [duplicateApplicatsConfirmPopup, setDuplicateApplicatsConfirmPopup] = useState(false);
  const [twoDuplicateApplicatsConfirmPopup, setTwoDuplicateApplicatsConfirmPopup] = useState(false);
  const [ skipId, setSkipId ] = useState(null);
  const [ skipName, setSkipName ] = useState(null);
  const [ statusObj, setStatusObj ] = useState([]);
  const [footerImg,setFooterImg]=useState(false);
  const [isSendsms, setIsSendsms]= useState(false);
  const [smsCandidates, setSmsCandidates]= useState([]);

  const [sourceModelValues, setSourceModelValues] = useState([]);
  const customFieldsData = useSelector(state => state.CustomFields.data);

  const [sourceId, setSourceId] = useState(0);
  const [defaultFile, setDefaultFile] = useState(null)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [GetUserId, setUserId] = useState(null);

  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  useEffect(() => {
    if (GetUserId === null) {
      let userId = JSON.parse(decrypt('GetUserId'))
      setUserId(userId);
      dispatch(customFieldsAsync(userId));
    }
  }, [GetUserId])


  useEffect(() => {
    if (customFieldsData && customFieldsData.length > 0) {
      const data = JSON.parse(JSON.stringify(customFieldsData));
      let overrideDefaultPostingFieldsData = data.filter(x => x.moduleId.toString() === "2" && x.fieldType === "default");
      if (overrideDefaultPostingFieldsData.length > 0) {
        overrideDefaultPostingFieldsData.forEach(list => {
          if (list.fieldName === "Candidate status") {
            let updatedCustomValues = JSON.parse(list.customValues);
            let modifiedCustomValues = [];
            updatedCustomValues.forEach(list => {
              if (list !== "Cloudsourcing portal" && list !== "Employee portal" && list !== "Vendorsourcing portal" && list !== "JobBoard portal" && list !== "Others") {
                modifiedCustomValues.push(list);
              }
            })
            setSourceModelValues(modifiedCustomValues);
          }
        })
      }
      dispatch(customFieldsStartReducer({}));
    }
  }, [customFieldsData])

  

  useEffect(() => {
    console.log('Updated sourceModelValues:', sourceModelValues);
  }, [sourceModelValues]);
   
  useEffect(() => {
    localStorage.removeItem("OverwriteEditObj");
    let obj1 = { current: config.currentPage, offset: users.length + 1 };
    FetchData(obj1);
    // eslint-disable-next-line
    let userInfo = JSON.parse(decrypt("GetUserInfo"));
    if(userInfo){
      let modifyConfig = JSON.parse(JSON.stringify(config));
      modifyConfig.isDropdownActions = userInfo[0].systemroles === "Super Admin" || userInfo[0].systemroles === "Manager" || userInfo[0].systemroles === "Admin" ? true : false
      setConfig(modifyConfig)
    } else {
      setConfig(configuration);
    }
  }, []);

  useEffect(() => {
    if (GetUserId === null) {
      setUserId(JSON.parse(decrypt('GetUserId')));
    }
  }, [GetUserId])

  const applicantsData = useSelector(state => state.GetListApplicants.data);
  const bulkUploadResumeData = useSelector(store => store.BulkUploadResume.data);

  useEffect(()=>{
    if(bulkUploadResumeData){
      setIsBulkuploadResponse(true);
      setBulkUploadMessage(bulkUploadResumeData);
      console.log(bulkUploadResumeData);
    }

  },[bulkUploadResumeData])

  const FetchData = async (obj) => {
    setUsers([]);
    dispatch(_ser.getListApplicantsAsync(config.limitData, obj.offset));
    let configure = JSON.parse(JSON.stringify(config));
    configure.currentPage = obj.current;
    setConfig(configure);
  };

  useEffect(() => {
    if (applicantsData !== null) {
      const myData = JSON.parse(JSON.stringify(applicantsData));
      setUsers([]);
      if (myData?.rows?.length > 0) {
        myData.rows.forEach((e) => {
          e["isChecked"] = false;
          e.userFirstName = e.userFirstName ? e.userFirstName : ' ';
          e.userLastName = e.userLastName ? e.userLastName : ' ';
          e["createdBy"] = e.userFirstName + ' ' + e.userLastName;
          e["ApplicantName"] = e.firstName + ' ' + e.lastName;
        });
        let configure = JSON.parse(JSON.stringify(config));
        configure.totalRecords = myData.total_count / config.limitData;
        setConfig(configure);
        setUsers(myData.rows);
        dispatch(_ser.getListApplicantsStartReducer(initalState));
      }
    }
  }, [applicantsData]);

  // for dynamic component received props
  const onReceiveActionHandler = async (type, object, url) => {
    if (type === "confirmDel") {
      DeleteRecords(object);
    } else if (type === "download") {
      alert("download option is not implemented!");
    } else if (type === "pagination") {
      if (filterValue !== '') {
        getSearchResult(object, true, url, type);
      } else if (skillsObj.length > 0 || filterObj.yearsExperienceMin !== '' || filterObj.yearsExperienceMax !== '' || filterObj.jobtitle !== "" || statusObj.length > 0) {
        onApplyAdFilterHandler(object)
        // getSearchResult(object, true, url);
      }
      else {
        FetchData(object);
      }
    } else if (type === "searchResult") {
      // let isPaging = object.current > 1 ? true : false;
      if (filterValue !== '' || (object.keyword !== "" && object.keyword !== undefined)) {
        object.offset = object.offset ? object.offset : 1; 
        setFilterValue(object.keyword);
        getSearchResult(object, true, url, type);
      } else if (object.limitData === undefined) {
        object.limitData = config.limitData
        getSearchResult(object, false, url, type);
      }
    } else if (type === "cancelSearchResult") {
      setFilterValue("");
      let obj1 = { current: 1, offset: 1 };
      FetchData(obj1);
    } else if (type === "newApplicants") {
      setIsRightSidePannel(object);
    } else if(type === "pageRange"){
      setConfig({...config,limitData:config.limitData=object})
      let obj1 = { current: config.currentPage, offset: config.currentPage !== 1 ? (config.currentPage - 1) * config.limitData + 1 : config.currentPage, limit: config.limitData };
      FetchData(obj1);
    } else if (type === "Send SMS"){
      setIsSendsms(true);
      setSmsCandidates(object);
    }
  };

  const DelApplicantscode = useSelector(state => state.DeleteApplicants.data);
  const UploadDocCode = useSelector(state => state.UploadDocuments.data);

  const DeleteRecords = async (object) => {
    let ids;
    if (typeof object === "object" && object.length > 0) {
      ids = object.map((x) => x.id.toString());
    } else {
      ids = [object.toString()];
    }
    let obj = { ids: ids, Customerid: GetUserId };
    dispatch(_ser.deleteApplicantsAsync(obj));
  };

  useEffect(() => {
    if (DelApplicantscode === 200) {
      let obj1 = {
        current: config.currentPage,
        offset: config.currentPage !== 1 ? (config.currentPage - 1) * 20 + 1 : config.currentPage,
      };
      FetchData(obj1);
      setFilterValue("")
      dispatch(_ser.deleteApplicantsStartReducer(initalState))
    }
  }, [DelApplicantscode])

  const getSearchResult = async (object, paging, url, type) => {
    setUsers([]);
    if (paging === true) {
      if (filterValue === ""){
        setFilterValue(object.keyword);
      } 
      // else if (filterValue !== "" && filterValue !== object.keyword) {
      //   setFilterValue(object.keyword);
      // }
        SearchResultWithPaging(object, url);
      
    } else {
      if (url !== undefined) {
        // setFilterValue(object.keyword);
      } else {
        setFilterValue(object.keyword);
      }
      if (object.current === undefined) {
        object["current"] = 1;
        object["limitData"] = 20;
      }
      SearchResultWithoutPaging(object, url);
    }
  };

  useEffect(() => {
    async function FetchData() {
      const userInfo = await getUserDetails()
      dispatch(updateAuth(userInfo))
    }
    FetchData();
    // eslint-disable-next-line
  }, []);

  const SearchApplicantsWithoutPagingData = useSelector(state => state.SearchApplicantsWithoutPaging.data);

  const SearchResultWithoutPaging = async (object, url) => {
    dispatch(_ser.searchApplicantsWithoutPagingAsync(object, url));
    let configure = JSON.parse(JSON.stringify(config));
    configure.currentPage = object.current;
    setConfig(configure);
  }

  useEffect(() => {
    if (SearchApplicantsWithoutPagingData && (SearchApplicantsWithoutPagingData.length > 0 || SearchApplicantsWithoutPagingData.rows?.length > 0)) {
      const applicantsWithoutPagingData = JSON.parse(JSON.stringify(SearchApplicantsWithoutPagingData));
      let myData = applicantsWithoutPagingData?.rows || applicantsWithoutPagingData;
      const total_count = applicantsWithoutPagingData?.total_count || applicantsWithoutPagingData[0]?.total_count
      myData.forEach((e) => {
        e["isChecked"] = false;
        e.userFirstName = e.userFirstName ? e.userFirstName : '-';
        e.userLastName = e.userLastName ? e.userLastName : '-';
        e["createdBy"] = e.userFirstName + ' ' + e.userLastName;
        e["ApplicantName"] = e.firstName + ' ' + e.lastName;
      });
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = total_count / config.limitData; //myData[0]?.totalCount ? myData[0].totalCount : 
      setConfig(configure);
      setUsers(myData.slice(0, config.limitData));
      dispatch(_ser.searchApplicantsWithoutPagingStartReducer(initalState))
    }
    else if (SearchApplicantsWithoutPagingData || SearchApplicantsWithoutPagingData?.rows) { //&& SearchApplicantsWithoutPagingData.length === 0
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = 0;
      configure.currentPage = 1;
      setConfig(configure);
      setUsers('null');
      dispatch(_ser.searchApplicantsWithoutPagingStartReducer(initalState))
    }
  }, [SearchApplicantsWithoutPagingData])

  const SearchApplicantsWithPagingData = useSelector(state => state.SearchApplicantsWithPaging.data);

  const SearchResultWithPaging = async (object, url) => {
    let value = object.keyword? object.keyword : filterValue ;  //filterValue? filterValue : object.keyword;
    dispatch(_ser.searchApplicantsWithPagingAsync(config.limitData, object.offset, value, url));
    let configure = JSON.parse(JSON.stringify(config));
    configure.currentPage = object.current;
    setConfig(configure);
  }

  useEffect(() => {
    if (SearchApplicantsWithPagingData && (SearchApplicantsWithPagingData.length > 0 || SearchApplicantsWithPagingData?.rows?.length > 0)) {
       const applicantsWithPagingData = JSON.parse(JSON.stringify(SearchApplicantsWithPagingData));
      let  myData = applicantsWithPagingData?.rows || applicantsWithPagingData;
      const total_count = applicantsWithPagingData?.total_count  // || applicantsWithPagingData[0].total_count;
      myData.forEach((e) => {
        e["isChecked"] = false;
        e.userFirstName = e.userFirstName ? e.userFirstName : '-';
        e.userLastName = e.userLastName ? e.userLastName : '-';
        e["createdBy"] = e.userFirstName + ' ' + e.userLastName;
        e["ApplicantName"] = e.firstName + ' ' + e.lastName;
      });
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = total_count / config.limitData;
      setConfig(configure);
      setUsers(myData.slice(0, config.limitData));
      dispatch(_ser.searchApplicantsWithPagingStartReducer(initalState));
    }
    else if (SearchApplicantsWithPagingData?.rows || SearchApplicantsWithPagingData) { //&& SearchApplicantsWithPagingData.length === 0
      let configure = JSON.parse(JSON.stringify(config));
      configure.totalRecords = 0;
      configure.currentPage = 1;
      setConfig(configure);
      setUsers('null');
      dispatch(_ser.searchApplicantsWithPagingStartReducer(initalState))
    }
  }, [SearchApplicantsWithPagingData])

  const onReceivePropsHandler = (pannel, load, bool) => {
    setIsRightSidePannel(pannel);
    setIsBulkuploadResponse(pannel)
    setFooterImg(bool)
    setIsSendsms(pannel);
    let obj1 = { current: 1, offset: 1 };
      FetchData(obj1);
      if(!pannel){
        setSmsCandidates([]);
      }
  };

  const onAdvanceSearchPannel = (type) => {
    if (type === "close") {
      setIsAdvancePannel(false);
    } else {
      setIsAdvancePannel(true);
    }
  }

  const onActiveAccodianHandler = (obj) => {
    let data = JSON.parse(JSON.stringify(advanceSearchlist));
    data.forEach(list => list.isActive = false);
    let index = data.findIndex(s => s.id === obj.id);
    data[index].isActive = !obj.isActive;
    setAdvanceSearchlist(data);
  }

  const onkeyhandlerhandler = (e) => {
    let value = skillsValue.trimStart();
    if (e.which === 13 && value) {
      let Array = JSON.parse(JSON.stringify(skillsObj));
      Array.push(value);
      setSkillsValue("");
      setSkillsObj(Array);
    }
  }

  const onRemoveValueHandler = (e, list, i) => {
    let Array = JSON.parse(JSON.stringify(skillsObj));
    Array.splice(i, 1);
    setSkillsValue("");
    setSkillsObj(Array);
  }

  const onChangehandler = (value, name) => {
    var numbers = /^[-+]?[0-9]+$/;
    if (name === "yearsExperienceMin" || name === "yearsExperienceMax") {
      if (value.match(numbers)) {
        setFilterObj((prevValue) => ({ ...prevValue, [name]: value }))
      } else if (value === "") {
        setFilterObj((prevValue) => ({ ...prevValue, [name]: value }))
      }
    } else if (name === "candidateStatus") {
      setStatusObj(typeof value === 'string' ? value.split(',') : value);
    } else {
      setFilterObj((prevValue) => ({ ...prevValue, [name]: value }))
    }
  }

  useEffect(() => {
    console.log("statusObj ++++++++++++++++++++", statusObj)
  }, [statusObj])

  const onApplyAdFilterHandler = (obj) => {
    let params = [], generateURL = '';
    // params.push(`limit=${6}`);
    // params.push(`offset=${1}`);
    if (filterObj.jobtitle !== '') {
      params.push(`keyword=${filterObj.jobtitle}`);
    }
    if (skillsObj.length > 0) {
      params.push(`skills=${skillsObj}`);
    }
    if (statusObj.length > 0) {
      params.push(`candidateStatus=${statusObj}`);
    }
    if (filterObj.yearsExperienceMin !== '') {
      params.push(`yearsExperienceMin=${filterObj.yearsExperienceMin}`);
    }
    if (filterObj.yearsExperienceMax !== '') {
      params.push(`yearsExperienceMax=${filterObj.yearsExperienceMax}`);
    }
    if (params.length > 0) {
      generateURL = params.join("&");
      onReceiveActionHandler("searchResult", obj, generateURL);
    } else {
      dispatch(updateSnackbar({ type:"error", message: "Select atleast one field to filter" }));
    }
    // obj["keyword"] = skillsObj[0];
  }

  useEffect(() => {
    if ((filterObj.jobtitle !=="" || skillsObj.length > 0 || statusObj.length > 0 || filterObj.yearsExperienceMax !=="" || filterObj.yearsExperienceMax !== '')) {
      setIsDisableBtn(false);
    }
    else {
      setIsDisableBtn(true);
    }
  }, [skillsObj, filterObj, statusObj])

  const onClearFilterHandler = () => {
    setSkillsObj([]);
    setStatusObj([]);
    setFilterObj(initalFilter);
    onReceiveActionHandler("cancelSearchResult", null)
    // setIsAdvancePannel(false);
  }

  const onManualuploadHandler = () => {
    let json = {
      Applicants: { firstName: "", lastName: "", phone: "", emailId: "", AlternateContactNumber: '', resumesourceId: sourceId },
      totalExperience: null,
      educationDetails: [],
      workExp: [],
      skillDetails: [],
      certification: [],
      identifier: "",
      customFields: JSON.stringify([]),
      jobDescription:'',
      source: sourceId,
      id:0,
    }
    navigate('/ats/layout/applicants/create', { state: { applicant: json, sourceId: sourceId, uploadfile: defaultFile, isAssociate: null, isduplicate: false, isManual: true } });
  }

  const onManualPopup = (isPopup, sourceId, file, obj) => {
    if(sourceId !== null && file !== null){
      setManualApplicatsConfirmPopup(isPopup);
      setSourceId(sourceId);
      setDefaultFile(file);
      if(obj !== null){
        setSkipId(obj.id);
        let json = {
          Applicants: obj.data.Applicants,
          totalExperience: obj.data.totalExperience ? obj.data.totalExperience : null,
          educationDetails: obj.data.educationDetails ? obj.data.educationDetails : [],
          workExp: obj.data.workExperiences ? obj.data.workExperiences : [],
          skillDetails: obj.data.skillDetails ? obj.data.skillDetails : [],
          certification: obj.data.certifications ? obj.data.certifications : [],
          identifier: obj.data.identifier ? obj.data.identifier : '',
          jobDescription:'',
          customFields: JSON.stringify([]),
          source: sourceId,
          id:obj.id,
          oldApplicantName:obj.oldApplicantName ? obj.oldApplicantName : '',
          Resume:file,
        }
        setUpdateProfileWithNewResume(json);
        setDuplicateApplicatsConfirmPopup(true);
        setSourceId(sourceId);
      }
    } else {
      setTwoDuplicateApplicatsConfirmPopup(true);
      setSkipId(obj.data.id);
      setSkipName(obj.data.name);
    }
  }
  
  useEffect(() => {
    if (UploadDocCode === 200 && skipId) {
      navigate(`/ats/layout/applicants/documents/${skipId}`);
      dispatch(_ser.uploadDocumentsStartReducer(initalState))
    }
  }, [UploadDocCode, skipId])


  return (
    <div style={{ marginTop: "80px", marginBottom: "60px" }}>
      <div style={{ background: "#fff", padding: !isAdvancePannel ? "22px 47px 47px" : "22px 47px 47px 0" }}>
        <div className={ Boolean(config.isDropdownActions) || isAdvancePannel ? "advanceSearchbtn" : "advanceSearchbtn1 advanceSearchbtn"}>
          <button style={{ marginLeft: !isAdvancePannel ? "0" : "31px", position: !isAdvancePannel ? "absolute" : "fixed" }} onClick={() => onAdvanceSearchPannel("open")}>
            <img src={process.env.PUBLIC_URL + "/assests/panel.svg"} alt="advanced search btn" />Advanced search
          </button>
        </div>
        {/* for dynamic Table */}
        <div className="d-flex">
          {isAdvancePannel ?
            <div style={{ width: "20%", paddingRight: "30px" }}>
              <div style={{ position: "relative" }}>
                <img src={process.env.PUBLIC_URL + "/assests/backBtn.svg"} alt="advanced search close" onClick={() => onAdvanceSearchPannel("close")} style={{ position: "fixed", left: "16.7%", top: "112px", cursor: "pointer", zIndex: 9 }} />
              </div>
              <div className="adSearchPannel">
                <ul className="adSearchPannelList">
                  {advanceSearchlist.map(item => (
                    <li>
                      <div className={item.isActive ? "active" : ''} onClick={(e) => { onActiveAccodianHandler(item) }}>
                        {item.displayName}<span>{item.isActive ? "-" : "+"}
                        </span></div> 
                        
                      {item.isActive ?
                        <div style={{ marginTop: "10px" }}>
                          {item.type === "multiselect" ?
                            <>
                              <input placeholder={`Search for ${item.displayName}`} className="adSearchBox" value={skillsValue}
                                onKeyUp={onkeyhandlerhandler} onChange={e => setSkillsValue(e.target.value)}
                              />
                              <ul className="listbtns">
                                {skillsObj && skillsObj.map((list, i) => (
                                  <li>{list}<span onClick={(e) => onRemoveValueHandler(e, item, i)}>x</span></li>
                                ))}</ul>
                            </>
                            : item.type === "text" ?
                              <input placeholder={`Search for ${item.displayName}`} className="adSearchBox" value={filterObj[item.name]} onChange={(e) => onChangehandler(e.target.value, item.name)} />
                               : item.type === "options" && item.name === "candidateStatus" && item.isActive ?
                               <div >
                                <FormControl sx={{ m: 1, width: 300 }}>
                                 {/* <InputLabel className="boldSelectlabel" id="demo-multiple-chip-label">Candidate Status</InputLabel> */}
                                  <Select 
                                  labelId="demo-multiple-chip-label"
                                  id=""
                                  multiple
                                  name="candidateStatus"
                                  value={statusObj}
                                  onChange={(e) => onChangehandler(e.target.value, "candidateStatus")}
                                  
                                  renderValue={(selected) => {
                                    if (typeof selected === 'string') { selected = selected.split(",") }
                                    return (
                                      <Box sx={{ display: "flex", flexWrap: "wrap", gap:0.5  }}>
                                        {typeof selected !== 'string' && 
                                        selected.map((value) => (
                                          <Chip key={value} label={value} />
                                        ))
                                        }
                                      </Box>
                                    )
                                  }}
                                  MenuProps={MenuProps}
                                >
                                  {sourceModelValues.map((name) => (
                                    <MenuItem className="selectOptions" key={name.id} 
                                    style={getStyles(name, statusObj, theme)} value={name.id}
                                    >
                                      {name.id}
                                    </MenuItem>
                                  ))}
                                </Select>
                                </FormControl>
                                </div>
                              : item.type === "options" && item.name === "Work Model" ?
                                <ul className="listbtns">
                                  {workModelModel.map(list => (
                                    <li className="active">{list.name}</li>
                                  ))}
                                </ul>
                                : item.type === "options" && item.name === "Job Status" ?
                                  <ul className="listbtns">
                                    {jobStatusModel.map(list => (
                                      <li className="active">{list.name}</li>
                                    ))}
                                  </ul> :
                                  item.type === "options" && item.name === "Client" ?
                                    <ul className="listbtns">
                                      {clientsModel.map(list => (
                                        <li className="active">{list.name}</li>
                                      ))}
                                    </ul> :
                                    item.type === "rangeText" ?
                                      <div className="rangeText">
                                        <div className="suffixtext">
                                          <input placeholder="Min" type="text" value={filterObj.yearsExperienceMin} onChange={(e) => onChangehandler(e.target.value, "yearsExperienceMin")} />
                                          <span>Years</span>
                                        </div>
                                        <div className="suffixtext">
                                          <input placeholder="Max" type="text" value={filterObj.yearsExperienceMax} onChange={(e) => onChangehandler(e.target.value, "yearsExperienceMax")} />
                                          <span>Years</span>
                                        </div>
                                      </div> : ""}
                        </div> : ''}
                    </li>
                  ))}
                </ul>
                <div className="actionBtns" style={{ position: "absolute", bottom: "200px", width: "100%", left: 0, textAlign: "center" }}>
                  <Button
                    type="button"
                    className="cancelBtn"
                    style={{ backgroundColor: "transparent" }}
                    onClick={onClearFilterHandler}
                    size='small'
                    // disabled={isDisableBtn}
                  >
                    Clear
                  </Button>
                  <Button
                    className="savebtn btnHover"
                    variant="contained"
                    size='small'
                    type="submit"
                    // disabled={isDisableBtn}
                    onClick={() => onApplyAdFilterHandler({ offset: 1, limitData: undefined })}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
            : ''
          }
          <div style={{ width: !isAdvancePannel ? "100%" : "80%" }}>
            {users && users.length === 0 && (
              <DynamicTable
                config={config}
                data={users}
                colmns={columnsData}
                actionDropdown={actionsDropdownData}
                filterValue={filterValue}
                searchKeyWord="Search by Code, Name, Email, Contact"
                actions={actionsList}
                addModel={addNewModel}
                noDataText="No Applicants Found"
                onReceiveActionProps={(type, obj) =>
                  onReceiveActionHandler(type, obj)
                }
              />
            )}
            {users && users.length > 0 && (
              <DynamicTable
                config={config}
                data={users}
                colmns={columnsData}
                actionDropdown={actionsDropdownData}
                filterValue={filterValue}
                searchKeyWord="Search by Code, Name, Email, Contact"
                actions={actionsList}
                addModel={addNewModel}
                nodataText="No Applicants Found"
                onReceiveActionProps={(type, obj) =>
                  onReceiveActionHandler(type, obj)
                }
              />
            )}
          </div>
        </div>

        {isRightSidePannel ? (
          <RightSideBar
            componentData={
              <ApplicantsRenderData
                onReceivechildProps={onReceivePropsHandler}
                onManualPopup={onManualPopup}
                isAssociate={null}
              />
            }
            componentLayout={!footerImg ? pannelobj1 : pannelobj2}
            onReceiveProps={onReceivePropsHandler}
          />
        ) : isBulkuploadResponse ? ( 
          <div id="bulkUploadResults">
          <RightSideBar
          componentData={ 
            <ComponentRenderData
                data={bulkUploadMessage}
                onReceivechildProps={onReceivePropsHandler}
              />
           }
          componentLayout={bulkResponseObj}
          onReceiveProps={onReceivePropsHandler}
        />
        </div>
        ) : isSendsms ? (
          <div id="sendSms">
          <RightSideBar
            componentData={
             <SendSms
                data={smsCandidates}
                onReceivechildProps={onReceivePropsHandler}
             />
            }
            componentLayout={smsObj}
            onReceiveProps={onReceivePropsHandler}
          />
          </div>
        ) : ("")}

        {/* Manual Upload popup */}
        {manualApplicatsConfirmPopup === true && (
          <section className="customModal">
            <div className="customModalDiv">
              <h1 className="modal-header">Error</h1>
              <div className="modal-body">
                <p>We are currently facing a technical glitch. Please select to upload resume manually.</p>
                <div className="modalActionBtns">
                  <Button type="button" className="cancelBtn" style={{ marginRight: "20px" }} onClick={() => { setManualApplicatsConfirmPopup(false) }}>Cancel</Button>
                  <Button type="button" variant="contained" className="saveBtn" onClick={onManualuploadHandler}>Upload Manually</Button>
                </div>
              </div>
            </div>
          </section>
        )}
        
        {duplicateApplicatsConfirmPopup === true && (
          <ApplicantsManualUploadPopup
            skipId={skipId}
            isAssociatedApplicant={false}
            updateProfileWithNewResume={updateProfileWithNewResume}
            sourceId={sourceId}
            defaultFile={defaultFile}
            setDuplicateApplicatsConfirmPopup={() => {
              setDuplicateApplicatsConfirmPopup(false);
              // setSkipId(null);
            }}
          />
        )}

        {twoDuplicateApplicatsConfirmPopup === true && (
          <DuplicateApplicantsManualUploadPopup
            skipId={skipId}
            skipName={skipName}
            setTwoDuplicateApplicatsConfirmPopup={() => {
              setTwoDuplicateApplicatsConfirmPopup(false);
              dispatch(_ser.uploadResumeStartReducer(initalState))
              setSkipId(null);
              setSkipName(null);
            }}
          />
        )}
      </div>
    </div>
  )
}

const ComponentRenderData = ({data,onReceivechildProps}) => {



  return(
    <>
    <div className="tabs">
      <TabContext value={"1"}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={""}
                  aria-label="lab API tabs example"
                  className="UpdateStatustabsvalue"
                >
                  <Tab label="Bulk upload Results" value="1" />
                  
                </TabList>
              </Box>
              <TabPanel value="1">

              {data && (
                <div>
                <div className='jobsdatacss'>
                       <tr>
                          <td>Total Resumes</td>
                          <td>:</td>
                          <td>{data.totalResume}</td>
                        </tr>
                       {/* <tr>
                          <td>Successful</td>
                          <td>:</td>
                          <td>{data.successful}</td>
                        </tr>
                       <tr>
                          <td>Failed</td>
                          <td>:</td>
                          <td>{data.failed}</td>
                        </tr> */}
                </div><br/>
                <Divider className="secline" />
                <p>Your documents have been submitted for offline processing. Once the process is complete, you will receive a confirmation email and Please refresh the page.</p>
            {data?.data?.map((msg)=>{
              return(
                <ul>
                  <li style={{textAlign: "left"}} dangerouslySetInnerHTML={{ __html: msg }}></li>
                </ul>
              )
            })}
           
            {/* <div style={{textAlign: "right"}}>
                <Button
                  type="button"
                  className="cancelBtn"
                  onClick={() => {
                  onReceivechildProps(false, "close");
                  }}
                    >
                      Close
                    </Button>
                  </div>                 */}
            </div>
            )}
    </TabPanel>
    </TabContext>
    </div>
    </>
  )
}

export default TableApplicants

/// for dynamic Table config Object
const configuration = {
  isSearchbar: true,
  isPaging: true,
  isDropdownActions: true,
  isCheckbox: true,
  isActions: true,
  recordperPage: 1,
  currentPage: 1,
  totalRecords: 0,
  limitData: 20,
  isCheckAlignRight: true,
  isPaginationTop: true,
  isSearchServer: true,
  isPagingServer: true,
  isPageRange:true,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["Delete", "Send SMS"]; //"Send SMS"

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isDelete: true,
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "Applicant Code",
    type:"Applicant Code",
    mappingName: "applicantCode",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/applicants/view"
  },
  {
    id: 2,
    displayName: "Applicant Name",
    type:"Applicant Code",
    mappingName: "ApplicantName",
    theadClass: "dynatableheadtext text-center ",
    tbodyClass: "dynatablebodytext  text-center NameCapitalise",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/applicants/view"
  },
  {
    id: 3,
    displayName: "Contact Number",
    type:"Applicant Code",
    mappingName: "contactNumber",
    theadClass: "dynatableheadtext text-center  ",
    tbodyClass: "dynatablebodytext text-center",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/applicants/view"
  },
  {
    id: 4,
    displayName: "Email ID",
    type:"Applicant Code",
    mappingName: "emailId",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytextemail text-center",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/applicants/view"
  },
  // {
  //   id: 5,
  //   displayName: "Candidate Status",
  //   type:"Applicant Code",
  //   mappingName: "candidateStatus",
  //   theadClass: "dynatableheadtext text-center",
  //   tbodyClass: "dynatablebodytextemail text-center",
  //   width: "",
  //   isEdit: true,
  //   nagigate: "/ats/layout/applicants/view"
  // },
  {
    id: 5,
    displayName: "Created By",
    type:"Applicant Code",
    mappingName: "createdBy",
    theadClass: "dynatableheadtext text-center ",
    tbodyClass: "dynatablebodytext text-center",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/applicants/view"
  }
];

const addNewModel = {
  url: "",
  displayName: "New Applicant"
}

const pannelobj1 = {
  title: "Add New Applicant",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/AddDocumentsIcon.svg`,//${process.env.PUBLIC_URL}/assests/img/AddDocumentsIcon.svg
};

const pannelobj2 = {
  title: "Add New Applicant",
  description: "",
  bgImage: ''
};

const bulkResponseObj = {
  title: "Bulk Upload",
  description: "",
  bgImage: ``,
};

const smsObj = {
  title: "Send SMS",
  description: "",
  bgImage: "" // `${process.env.PUBLIC_URL}/assests/img/emailbackground.svg`,
};

export const advanceSearchModel = [
  { id: 1, displayName: "Skills", name: "skills", isActive: false, type: "multiselect" },
  { id: 2, displayName: "Job Title", name: "jobtitle", isActive: false, type: "text" },
  { id: 3, displayName: "Experience", name: "experience", isActive: false, type: "rangeText" },
  { id: 4, displayName: "Candidate Status", name: "candidateStatus", isActive: false, type: "options" },
  // { id: 3, displayName: ""Location", name: "location", isActive: false, type: "text" },
  // { id: 4, displayName: ""State", name: 'state', isActive: false, type: "text" },
  // { id: 5, displayName: ""City/Zip Code", name: "zip", isActive: false, type: "text" },
  // { id: 6, displayName: ""Job Status", name: "jobstatus", isActive: false, type: "options" },
  // { id: 7, displayName: ""Work Model", name: "workmodel", isActive: false, type: "options" },
  // { id: 8, displayName: ""Client", name: "client", isActive: false, type: "options" },
]

export const candidateStatusValues = [
   "New", 
   "Associated", 
   "Consent await", 
   "Submitted", 
   "Interview", 
   "Selected", 
   "Rejected",
   "Pending",
];

export const workModelModel = [
  { id: 1, name: "All", isActive: false },
  { id: 2, name: "On-site", isActive: "false" },
  { id: 3, name: "Contract", isActive: "false" },
  { id: 4, name: "Internship", isActive: "false" },
  { id: 5, name: "Fulltime", isActive: "false" }
];

export const jobStatusModel = [
  { id: 1, name: "All", isActive: false },
  { id: 2, name: "PartTime", isActive: "false" },
  { id: 3, name: "Contract", isActive: "false" },
  { id: 4, name: "Internship", isActive: "false" },
  { id: 5, name: "Fulltime", isActive: "false" }
];

export const clientsModel = [
  { id: 1, name: "ABC", isActive: false },
  { id: 1, name: "BCD", isActive: false }
];

export const searchParam = {
  keyword: [""],
  jobTitle: "",
  location: "",
  state: "",
  zipCode: "",
  jobStatus: [""],
  workModel: [""],
  client: [""],
  candidateStatus: [""],
}