
import { SDK } from '@ringcentral/sdk';

let rcsdk;

export const initializeRingCentralSDK = () => {
    const integrationDetails = JSON.parse(localStorage.getItem("ringCentralIntegration"));
    if (!integrationDetails) {
        console.log("RingCentral integration details not found. Please ensure they are fetched and saved during login.");
        return
    }

    const { attribute_1: clientId, attribute_2: clientSecret } = integrationDetails;

    rcsdk = new SDK({
        server: "https://platform.ringcentral.com",
        clientId,
        clientSecret,
        redirectUri: process.env.REACT_APP_RINGCENTRAL_REDIRECT_URI,
    });
};

export const platform = () => {
    if (!rcsdk) {
        initializeRingCentralSDK();
    }
    return rcsdk.platform();
};

// Initialize with default details for safety
initializeRingCentralSDK();

export const integrationsMapping = {
    "Ring Central": {
        clientId: "attribute_1",
        clientSecret: "attribute_2",
        features: "attribute_3",
        countries: "attribute_4",
        type: "attribute_5",
        active: "active",
        integrationName: "attribute_6"
    },
};

