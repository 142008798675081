import { getApi, postApi, putApi, deleteApi } from "./_apihelper";

export const AddClients = (data) => (
    postApi(process.env.REACT_APP_ALL_CLIENTS, data, process.env.REACT_APP_CLIENTS)
)

export const EditClients = (id, data) => (
    putApi(`${process.env.REACT_APP_ALL_CLIENTS}/${id}`, data, process.env.REACT_APP_CLIENTS)
)

export const GetClients = (id) => (
    getApi(`${process.env.REACT_APP_ALL_CLIENTS}/${id}`, process.env.REACT_APP_CLIENTS)
)

export const DeleteClients = (data) => (
    deleteApi(process.env.REACT_APP_ALL_CLIENTS, data, process.env.REACT_APP_CLIENTS)
);

export const ParentClients = (data) => (
    getApi(process.env.REACT_APP_ALL_CLIENTS, process.env.REACT_APP_CLIENTS)
)

export const FilterClients = (keword) => (
    getApi(`${process.env.REACT_APP_ALL_CLIENTS}?keyword=${encodeURIComponent(keword)}`, process.env.REACT_APP_CLIENTS)
)

export const GetClientswithPaging = (limit, offset) => (
    getApi(`${process.env.REACT_APP_ALL_CLIENTS}?limit=${limit}&offset=${offset}`, process.env.REACT_APP_CLIENTS)
)

export const GetClientswithFields = (field) => (
    getApi(`${process.env.REACT_APP_ALL_CLIENTS}?filterBy=${field}`, process.env.REACT_APP_CLIENTS)
)

export const FilterClientsWithPaging = (limit, offset, filterValue) => (
    getApi(`${process.env.REACT_APP_ALL_CLIENTS}?limit=${limit}&offset=${offset}&keyword=${encodeURIComponent(filterValue)}`, process.env.REACT_APP_CLIENTS)
)

export const GetClientsComments = (data) => (
    getApi(`${process.env.REACT_APP_CLIENTS_COMMENTS}?type=${data.type}&typeId=${data.typeId}`, process.env.REACT_APP_CLIENTS)
)