import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Box, CircularProgress, Typography,Checkbox } from '@mui/material';
import SearchBar from "material-ui-search-bar";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { updateSnackbar } from "../../_redux/SnackbarSlice";
import Pagination from '../../components/DynamicTable/Pagination';
import DynamicTable from "../../components/DynamicTable/DynamicTable";
import { DocumentViewer } from 'react-documents';
import { useDispatch, useSelector } from "react-redux";
import RightSideBar from "../../components/Rightsidebar/RightSideBar";
import * as _ser from '../../_redux/_services/ApplicantsSlice';
import GetJobId from "../../_utilities/GetJobId";
import { GetTimeZone } from "../../_utilities/GetTimeZone";
import './AssociateCards.css';

const initalState = { data: null, message: '', isloader: true }

const AssociateCards = ({ data, selectedItems, isAImatched, onReceiveHandler, componentType, navigateTo, config, searchKeyWord , documentData, showSearchBar = true}) => {

  const [jobData, setjobData] = useState();
  const [searchValue, setSearchValue] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [jobRawData, setJobRawData]  = useState(JSON.stringify(data));
  const [aiSearchData, setAiSearchData] = useState()
  const [currentPage, setCurrentPage] = useState(config.currentPage);
  const usersPerPage = 9;
  const [totalRecords, setTotalRecords] = useState(data.length);
  const [isNoData, setIsNoData] = useState(false);
  const [showResumes, setShowResumes] = useState("");
  const [selectedResume, setSelectedResume] = useState({});
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [previewType, setPreviewType] = useState("");
  const [actionType, setActionType] = useState("");
  const dispatch = useDispatch();

  const JobId = GetJobId();

  useEffect(() => {
    if (selectedItems.length > 0) {
      let Ids = []
      selectedItems.forEach(element => {
        if (element ) {
          Ids.push(element.id)
        }
      });
      setSelectedIds(Ids);
    }
  }, [selectedItems ]);

  useEffect(() => {
    let cardData = JSON.parse(JSON.stringify(data));
    if (cardData && cardData.length > 0) {
      if(componentType === "AssociatedJobs"){
        cardData.forEach((list)=>{
          if(selectedIds.includes(list.id)) {
            let index = selectedItems.findIndex(x => x.id == list.id);
            if(index !== -1){
              list['docName'] = selectedItems[index].documentName;
              list['lastUpdated'] = GetTimeZone(selectedItems[index].lastUpdated);
              list['uploadedBy'] = selectedItems[index].uploadedBy;
              list['associatedDocId'] = selectedItems[index].docId;
            }
            console.log(list);
          }
          if(documentData.length == 1){
            list["docId"] = documentData[0].id;
          }
        })
        if(documentData && documentData.length > 0){
          setDocuments(documentData);
          console.log(documentData);
        }
      } else {
        cardData.forEach((list)=>{
          if(list.docsarray){
            let formatVal = "[" + list.docsarray + "]"
            list.docsarray = JSON.parse(formatVal);
            if(list.docsarray.length == 1){
              list.docId = list.docsarray[0].docsid;
            }
            list.docsarray.forEach((doc)=>{
              doc.docscreatedDate = GetTimeZone(doc.docscreatedDate);
              doc.uploadedBy = doc.submittedByName;
             })
          }

            if(selectedIds.includes(list.id)) {
              let index = selectedItems.findIndex(x => x.id == list.id);
              if(index !== -1 && (list.score ? list.docsId == selectedItems[index].docId : true) ){
                list['docName'] = selectedItems[index].documentName;
                list['docLastUpdated'] = GetTimeZone(selectedItems[index].lastUpdated);
                list['docUploadedBy'] = selectedItems[index].uploadedBy;
                list['associatedDocId'] = selectedItems[index].docId;
              }
              // console.log(list);
            }
        })
        console.log(cardData);
      }
      if(searchValue && searchValue !== "" || isAImatched){
        if (cardData.length > usersPerPage){
          let data = cardData.slice((currentPage !== 1 ? (currentPage - 1) : 0 ) * usersPerPage, (currentPage * usersPerPage));
          setjobData(data);  
        } else {
          setjobData(cardData);  
        }
      }
      setTotalRecords( componentType === "AssociatedApplicants" ? (cardData[0].total_count ? cardData[0].total_count : config.totalRecords) : cardData.length);
      setJobRawData(JSON.stringify(cardData));
    } else {
      setjobData([]);
      if (searchValue) {
        setIsNoData(true);
      }
    }
  }, [data, documentData, selectedItems])

  const handleActionClick = (obj) => {
    let currentpage = (obj - 1);
    let limitObj = {limit : usersPerPage, offset: (currentpage * usersPerPage) + 1, currentPage: obj, keyword: searchValue};
    setCurrentPage(obj);
    onReceiveHandler(limitObj, 'searchResult');
    let DataNew
    if (aiSearchData) {
      DataNew = aiSearchData;
    } else {
      DataNew = JSON.parse(jobRawData);
    }
    if (DataNew.length > usersPerPage){
    let Data = DataNew.slice(((currentpage * usersPerPage)), ((obj * usersPerPage)));
    setjobData(Data);
    } else {
      setjobData(DataNew);
    }
  };

  const onSelectHandler = (item, doc) => {
    let dataArray = JSON.parse(JSON.stringify(selectedIds));
    let findIndex = dataArray.findIndex(x => x === item.id);
    if (findIndex !== -1 && !doc) {
      dataArray.splice(findIndex, 1);
      if (componentType === "AssociatedJobs") {
        let obj = { id: parseInt(item.id), jobCode: item.jobCode }
        onReceiveHandler(obj, 'delete');
        setShowResumes("")
      }
      else if (componentType === "AssociatedApplicants") {
        let obj = { id: parseInt(item.id), applicantCode: item.applicantCode }
        onReceiveHandler(obj, 'delete');
        setShowResumes("")
      }
    } else  {
      dataArray.push(parseInt(item.id));
      if (componentType === "AssociatedJobs") {
        let obj = { id: parseInt(item.id), jobCode: item.jobCode, docId: doc ? doc.id : item.docId , keyword: searchValue, firstName : doc.firstName , lastName: doc.lastName }
        onReceiveHandler(obj, 'add');
      }
      else if (componentType === "AssociatedApplicants") {
        let obj = { id: parseInt(item.id), applicantCode: item.applicantCode, docId: doc ? doc.docsid : item.docId ? item.docId : item.docsId, score : item.score ? item.score : "", source: item.source, userId: item.userId , firstName : doc ? doc.submittedByName : item.docsarray ? item.docsarray[0].submittedByName : item.documentSubmittedBy , lastName: ""}
        onReceiveHandler(obj, 'add');
      }
    }
    setSelectedIds(dataArray);
  }

  const onSearchhandler = (value, type) => {
    value = value.trimStart();
    if (value.length < 2 && type === "onRequestSearch") {
      dispatch(updateSnackbar({
        type: "error",
        message: "Required Minimum 2 Characters"
      }));
    }
    if (config.isSearchServer === false) {
      const rawData = JSON.parse(jobRawData);
      if (value !== "") {
        value = value.trimStart();
        setSearchValue(value);
        if (rawData) {
          if (value.length > 1) {
            let data1 = rawData.filter((x) =>
              Object.keys(x).some((k) => String(x[k]).toLowerCase().includes(value.toLowerCase()))
            );
            if (data1.length > 0) {
              // setjobData(data1);
              setAiSearchData(data1)
              setIsNoData(false);
              setTotalRecords(data1.length);
              setjobData(data1.slice(0, (usersPerPage)));
            } else {
              setjobData("");
              setIsNoData(true);
            }
          }
          else {
            setAiSearchData();
            setjobData(rawData);  // setjobData(rawData.slice(0, (usersPerPage)));
            setTotalRecords(rawData[0].total_count);  //  setTotalRecords(rawData.length);
            setIsNoData(true);
          }
          setCurrentPage(1);
        }
      } else if (value === "" && type === "onCancelSearch") {
        setCurrentPage(1);
        setAiSearchData();
        setjobData(rawData);  // setjobData(rawData.slice(0, (usersPerPage)));
        setTotalRecords(rawData[0].total_count);  //  setTotalRecords(rawData.length);
        setIsNoData(false);
      }
    } else if (config.isSearchServer === true) {
      if (value !== "" && value.length >= 2) {
        value = value.trimStart();
        setSearchValue(value);
        // setCurrentPage(1);
        setIsNoData(false);
        let limitObj = {limit : usersPerPage, offset: 1, currentPage: currentPage, keyword: value};
        onReceiveHandler( limitObj, 'searchResult');
      } else if (value === "") {
        setSearchValue(value);
        setIsNoData(false);
        setjobData([]);
        setCurrentPage(1);
      }
    }
  }

  useEffect(() => {
    console.log('jobData ++++++++++++++++++++++', JSON.stringify(jobData))
  }, [jobData])

  
  const onNavigeteInNew = (e, object) => {
    e.stopPropagation();
    console.log("object ++++++++++++++++++++=", object);
    let Name = "", type = '';
    if(object.applicantCode){
      Name = object.firstName + " " + object.lastName;
      type = "Applicant Code";
    }
    else if(object.jobCode){
      Name = `${object.jobTitle} (${object.jobCode})`;
      type = "Job Code";
    }
      let newobj = { id: object.id, name : Name, type : type };
      let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
      if(subPathObj && subPathObj.length > 0){
        let index = subPathObj.findIndex(x => x.id === newobj.id);
        if(index !== -1){
          subPathObj[index] = newobj;
        } else {
          subPathObj.push(newobj);
        }
      } else {
        subPathObj = [newobj];
      }
      localStorage.setItem("subpathObj", JSON.stringify(subPathObj));
      window.open(navigateTo + object.id, '_blank')
  }

  const handleShowResumes = (object)=>{
    setSelectedResume(componentType === "AssociatedApplicants" ? object : documents[0]);
    if(showResumes === "" && (componentType !== "AssociatedApplicants" ? documents.length > 1 : object.docsarray?.length > 1)) {   
      setShowResumes(object)
    } else if (showResumes === "" && (componentType !== "AssociatedApplicants" ? documents.length == 1 : (object.docsarray ? object.docsarray?.length == 1 : true))) {
      onSelectHandler(object, documents[0]);
    } else if (showResumes) {
      setShowResumes("")
    } 
  }

  useEffect(()=>{
    documents.forEach((item)=>{
      item.docscreatedDate = GetTimeZone(item.docscreatedDate);
    })
  },[showResumes])

  const onReceiveActionHandler = async (type, object) => {
    if (type === "associateApplicant" ) {
      setSelectedResume(object);
      // let docId = componentType === "AssociatedJobs" ? object.id : object.docsid;
      onSelectHandler(showResumes, object);
      setShowResumes("")
  //  console.log(object);
    } else if (type === "view") {
      DownloadFileFromS3(object, type);
      setIsRightSidePannel(true);
    }
  };

  const onReceivePropsHandler = (pannel, load, type) => {
    if(type !== "") {
      setIsRightSidePannel(pannel);
      // setIsLoadDoc(load);
    } else if (type === "Download") {
      // DownloadFileFromS3(pannel, load);
    }
  };

  const docData = useSelector(state => state.Downloaddocument.data);
  const [DocName, setDocName] = useState("")

  const DownloadFileFromS3 = async (object, type) => {
    type = (type === "view") ? "preview" : "" ; //"download"
    dispatch(_ser.downloaddocumentAsync(JobId,object.path , type));
    setActionType(type);
    setDocName(object.path);
  }

  useEffect(() => {
    if (docData) {
      if (actionType === "Download" || actionType === "download") {
        const link = document.createElement('a');
        link.href = docData;
        link.download = DocName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setPreviewType(docData)
      }
      dispatch(_ser.downloaddocumentStartReducer(initalState));
    }
  }, [docData])

  return (
    <>
      {showSearchBar && <div className="resultHeader">
        <SearchBar
          className="rightnavsearchbar"
          placeholder=
          {searchKeyWord}
          //Search by JobCode, Title
          // value={searchValue !== null ? searchValue : ''}
          onChange={(newValue) => onSearchhandler(newValue, "search")}
          onRequestSearch={(newValue) =>
            // onSearchhandler(newValue.slice(0, 8), "onRequestSearch")
            onSearchhandler(newValue, "onRequestSearch")
          }
          onCancelSearch={() => onSearchhandler("", "onCancelSearch")}
        />
      </div>}
      {jobData !== null && jobData !== undefined && jobData.length > 0 ?
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ color: "#3B4046", fontWeight: "700", fontSize: "20px" }}>Results</p>
          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={usersPerPage}
            onPageChange={(page) => handleActionClick(page)}
          />
        </div>
        : ''}
      <div className="innerdiv">
        {jobData !== null && jobData !== undefined && jobData.length > 0 && (
          <>
            {jobData.map((elem, index) => {
              return (
                <>
                  <aside style={{ position: "relative" }} className={ (selectedIds.includes(parseInt(elem.id)) && showResumes.id === elem.id) ? "resumesCardCSS active" 
                  : showResumes.id === elem.id ? "resumesCardCSS" 
                  : selectedIds.includes(parseInt(elem.id)) && elem.score ? (elem.associatedDocId === elem.docsId ? "active" : "") : (selectedIds.includes(parseInt(elem.id)) ? "active" : "")  }>
                    {componentType === "AssociatedJobs" ?
                      <>
                        {/* <div title={elem.jobTitle} onClick={() => onSelectHandler(elem)}>{elem.jobTitle}</div>
                        <div onClick={() => onSelectHandler(elem)} className={componentType === "AssociatedJobs" ? "cardsforassjobs" : " "}>
                          <p title={elem.clientName}><PersonIcon />{elem.clientName}</p>
                          <p title={elem.jobCode}><CodeIcon /> {elem.jobCode}</p>
                          <p title={elem.location}><LocationOnIcon />{elem.location}</p>
                          <div style={{ display: "flex", justifyContent: "end", gap: "5px", marginTop: "-85px", position: "absolute", right: "9px", zIndex: 1, flexDirection: "column" }}>
                            <Link to={navigateTo + elem.id} target='_blank' style={{ marginTop: "-4px" }} type="button">
                              <OpenInNewIcon />
                            </Link>
                            {selectedIds.includes(elem.id) ? <CheckCircleIcon /> : <PanoramaFishEyeIcon />}
                          </div>
                        </div> */}
                        <div className="cardbgcolor" title={elem.jobTitle} >{elem.jobTitle}</div>
                        { showResumes.id === elem.id && <div className={(selectedIds.includes(parseInt(elem.id)) && showResumes.id === elem.id) ? 'hideBorderActivejobs' : "hideBorderjobs"}></div> }
                        <div  className={componentType === "AssociatedJobs" ? "cardsforassjobs" : " "}>
                        <div style={{ display: "flex", marginTop:"-6px"}}>
                          <span title={elem.clientName}>{elem.clientName}</span>
                            <Link onClick={(e) => { onNavigeteInNew(e, elem) }} style={{ marginLeft: "75%", position: "absolute" }} type="button">
                              <OpenInNewIcon /> 
                            </Link>
                            <span className='resumecount' style={{ marginLeft: "80%", position: "absolute" }}>view</span>
                            </div>
                          <p title={elem.jobCode}> {elem.jobCode}</p>
                          {/* <p title={elem.location}>{elem.location}</p> */}
                        <div  style={{ display: "flex", flexDirection:"column", margin: "3px 70px 0px 0px", gap: "10px", alignItems: "baseline"}} className='resumecount'>
                            { !selectedIds.includes(parseInt(elem.id)) || showResumes.id === elem.id ? 
                            <>
                            <div  style={{justifyItems:"center", gap:"10px", display:"flex", height:"15px"}}>
                            <img src={process.env.PUBLIC_URL + "/assests/img/pdf.svg"} alt="" className='resumepdficon' />
                            <span onClick={()=> handleShowResumes(elem)} style={{cursor: "pointer", textDecoration: documents.length == 1 ? "underline" : ""}}>{documents.length > 1 ? `${documents.length} Resumes` : documents.length == 1 ? documents[0].name : ""}</span>
                            </div>
                            {documents.length == 1 ? 
                              <span style={{marginTop:"-5px"}} className="uploadedDate">{( documents[0].firstName + " " + documents[0].lastName) + ", " + ( GetTimeZone(documents[0].docscreatedDate))}</span>
                              : ""
                            }
                            </>
                              : selectedIds.includes(parseInt(elem.id)) && showResumes.id !== elem.id ?
                              <>
                            <div style={{justifyItems:"center", gap:"10px", display:"flex", height:"15px"}}>
                              <img src={process.env.PUBLIC_URL + "/assests/img/pdf.svg"} alt="" className='resumepdficon' />
                              <span style={{cursor: "pointer", textDecoration: "underline" }}>{ elem.docName ? elem.docName : selectedResume.name}</span>
                            </div>
                              <span style={{marginTop:"-5px"}} className="uploadedDate">{( elem.uploadedBy ? elem.uploadedBy : selectedResume.uploadedBy ? selectedResume.uploadedBy : "") + ", " + ( elem.lastUpdated ? elem.lastUpdated : selectedResume.docscreatedDate ? GetTimeZone(selectedResume.docscreatedDate) : "")}</span>
                              {documents.length - 1 > 0 &&
                              <div onClick={()=> handleShowResumes(elem)} style={{marginTop:"-5px"}}><span style={{cursor: "pointer", marginTop:"-5px",textDecoration:"underline"}}>{`View Resumes` + " "}</span><span>{`(${documents.length - 1})`}</span></div>
                               }
                              </>
                              : " "
                            }
                          </div>
                        </div>
                          { (selectedIds.includes(parseInt(elem.id)) ) &&
                           <Checkbox  className={ selectedIds.includes(parseInt(elem.id)) && showResumes.id !== elem.id  ? 'cardcheckboxjobs' : (selectedIds.includes(parseInt(elem.id)) && showResumes.id === elem.id) ? "cardcheckboxActivejobs" :  ""}
                            checked={selectedIds.includes(parseInt(elem.id)) ? true : false}
                            onClick={() => onSelectHandler(elem)}
                           /> }
                        {/* <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-38px", height: "15px", alignItems: "baseline" }}>
                          <Link onClick={(e) => { onNavigeteInNew(elem) }} style={{ marginLeft: "20px", marginTop: "-4px" }} type="button">
                            <OpenInNewIcon />
                          </Link>
                          <div onClick={() => onSelectHandler(elem)}>
                            {selectedIds.includes(parseInt(elem.id)) ? <CheckCircleIcon /> : <PanoramaFishEyeIcon />}
                          </div>
                        </div> */}
                      </>
                      : componentType === "AssociatedApplicants" ? <>
                        {elem.firstName && (<div className="cardbgcolor" title={`${elem.firstName} ${elem.lastName}`}>
                          {elem.firstName} {elem.lastName}
                          { showResumes.id === elem.id && <div className={(selectedIds.includes(parseInt(elem.id)) && showResumes.id === elem.id) ? 'hideBorderActive' : "hideBorder"}></div> }
                        </div>)}
                        <div>
                          {/* {elem.jobTitle && (<div title={elem.jobTitle}>{elem.jobTitle}</div>)} */}
                          {elem.applicantCode && (
                            <>
                            <div style={{ display: "flex"}}>
                            <span  title={elem.applicantCode}> {elem.applicantCode}</span>
                             <Link onClick={(e) => { onNavigeteInNew(e, elem) }} style={{ marginLeft: "57%" }} type="button">
                              <OpenInNewIcon /> 
                            </Link>
                            <span className='resumecount'>view</span>
                            </div>
                            <div style={{ marginTop: "20px", right:"18px", position:"absolute" }}>
                              {elem.score ?
                                <div className="mathCards">
                                  <Box position="relative" display="inline-flex">
                                    <CircularProgress variant="determinate" value={elem.score * 100} />
                                    <Box
                                      top={0}
                                      left={5}
                                      bottom={10}
                                      right={0}
                                      position="absolute"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Typography variant="caption">{`${Math.round(elem.score * 100)}%`}</Typography>
                                    </Box>
                                  </Box>

                                </div>
                                : ''}

                            </div>
                            </> )}
                          {/* <div onClick={() => onSelectHandler(elem)}>
                            {elem.contactNumber && (<p title={elem.contactNumber}> {elem.contactNumber}</p>)}
                            {elem.emailId && (<p title={elem.emailId}> {elem.emailId}
                            </p>)}
                          </div> */}

                          <div  style={{ display: "flex", flexDirection:"column", margin: "10px 70px 0px 0px", gap: "12px", alignItems: "baseline", width: "175px", overflow: "hidden"}} className='resumecount'>
                            { !selectedIds.includes(parseInt(elem.id))  || showResumes.id === elem.id ? 
                            <>
                            <div  style={{justifyItems:"center", gap:"10px", display:"flex", height:"15px"}}>
                            <img src={process.env.PUBLIC_URL + "/assests/img/pdf.svg"} alt="" className='resumepdficon' />
                            <span onClick={()=> handleShowResumes(elem)} style={{cursor: "pointer", textDecoration: elem.docsarray?.length == 1 || elem.documentName ? "underline" : ""}}>{elem.docsarray?.length > 1 ? `${elem.docsarray?.length} Resumes` : elem.docsarray?.length == 1 ? elem.docsarray[0].name : elem.associatedDocId === elem.docsId ? elem.docName : elem.documentName }</span>
                            </div>
                            {(elem.docsarray?.length == 1 ) ?
                            <span style={{marginTop:"-5px"}} className="uploadedDate">{( elem.docsarray[0].submittedByName ? elem.docsarray[0].submittedByName : "" ) + ", " + ( elem.docsarray[0].docscreatedDate ? elem.docsarray[0].docscreatedDate : "" )}</span>
                            : (elem.documentName ) ?
                            <span style={{marginTop:"-5px"}} className="uploadedDate">{( elem.associatedDocId === elem.docsId ? elem.docUploadedBy :  elem.documentSubmittedBy ) + ", " + ( elem.associatedDocId === elem.docsId ? elem.docLastUpdated : GetTimeZone(elem.docscreatedDate) )}</span>
                            : " "
                            }
                            </>
                              : selectedIds.includes(parseInt(elem.id)) && showResumes.id !== elem.id ?
                              <>
                            <div style={{justifyItems:"center", gap:"10px", display:"flex", height:"15px"}}>
                              <img src={process.env.PUBLIC_URL + "/assests/img/pdf.svg"} alt="" className='resumepdficon' />
                              <span style={{cursor: "pointer", textDecoration: "underline" }}>{elem.docName ? elem.docName : elem.documentName ? elem.documentName : selectedResume?.docsarray?.length == 1 ? selectedResume?.docsarray[0]?.name : ""}</span>
                            </div>
                              <span style={{marginTop:"-5px"}} className="uploadedDate">{( elem.docUploadedBy ? elem.docUploadedBy : elem.documentSubmittedBy ? elem.documentSubmittedBy : selectedResume?.docsarray?.length == 1 ? selectedResume?.docsarray[0].uploadedBy : selectedResume.uploadedBy) + ", " + ( elem.docLastUpdated ? elem.docLastUpdated : elem?.docsarray ? GetTimeZone(elem?.docsarray?.[0]?.docscreatedDate) : GetTimeZone(elem.docscreatedDate))}</span>
                              {elem.docsarray?.length - 1 > 0 &&
                              <div onClick={()=> handleShowResumes(elem)} style={{marginTop:"-5px"}}><span style={{cursor: "pointer", marginTop:"-5px",textDecoration:"underline"}}>{`View Resumes` + " "}</span><span>{`(${elem.docsarray?.length - 1})`}</span></div>
                               }
                              </>
                              : " "
                            }
                          </div>
                            {/* <div onClick={() => onSelectHandler(elem)}>

                               {selectedIds.includes(parseInt(elem.id))
                                ? <CheckCircleIcon /> : <PanoramaFishEyeIcon />} 
                            </div> */}
                            { (elem.score ? elem.associatedDocId === elem.docsId : (selectedIds.includes(parseInt(elem.id))) ) &&
                           <Checkbox  className={ selectedIds.includes(parseInt(elem.id)) && showResumes.id !== elem.id && elem.docsarray?.length - 1 > 0 ? 'cardcheckbox' : (selectedIds.includes(parseInt(elem.id)) && showResumes.id === elem.id) ? "cardcheckboxActive" : selectedIds.includes(parseInt(elem.id)) && elem.docsarray?.length == 1 ? "checkboxSingledoc" : "aiCardcheckbox" }
                            checked={selectedIds.includes(parseInt(elem.id)) ? true : false}
                            onClick={() => onSelectHandler(elem)}
                           /> }
                        </div>
                      </> : ''
                    }
                    { showResumes.id === elem.id &&
                      <>
                      <div className={selectedIds.includes(parseInt(elem.id)) && componentType === "AssociatedJobs" ? "resumeTabledivJobs" : "resumeTablediv" } style={{marginLeft: index === 0 ? "-1px" : index > 2 ? "-" + ((index%3 === 0 ? 1 : 275) * (index%3 === 0 ? 1 : index%3)) + "px" : "-" + 275 * index + "px"}}>
                        {documents.length > 0 && (
                          <DynamicTable
                            config={configuration}
                            data={documents.length > 0 ? documents : elem.docsarray }
                            selectedId={elem.associatedDocId ? elem.associatedDocId : ""}
                            colmns={columnsData}
                            actionDropdown={""}
                            actions={actionsList}
                            addModel={""}
                            noDataText={"No Documents Found"}
                            searchKeyWord="Search by Document Name"
                            onReceiveActionProps={(type, obj) =>
                              onReceiveActionHandler(type, obj)
                            }
                          />
                        )}
                        {documents.length === 0 && (
                          <DynamicTable
                            config={configuration}
                            data={documents.length > 0 ? documents : elem.docsarray }
                            selectedId={elem.associatedDocId ? elem.associatedDocId : ""}
                            colmns={columnsData}
                            actionDropdown={""}
                            actions={actionsList}
                            addModel={""}
                            noDataText={"No Documents Found"}
                            searchKeyWord="Search by Document Name"
                            onReceiveActionProps={(type, obj) =>
                              onReceiveActionHandler(type, obj)
                            }
                          />
                        )}
                      </div> 
                     
                      </>
                      }
                  </aside>
                </>
              );
            })
          }
          
          </>)}
      </div>
      {jobData === "" && isNoData && (
        <div className="nodatasection nodataSection">
          <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
          No search results found
        </div>
      )}

      {isRightSidePannel ? (
        <div id= 'associateResumes'>
          <RightSideBar
            componentData={
              <ComponentRenderData
                JobId={JobId}
                previewType={previewType}
                DocName={DocName}
                onReceivechildProps={onReceivePropsHandler}
              />

            }
            componentLayout={ pannelobj}
            onReceiveProps={onReceivePropsHandler}
          />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

const ComponentRenderData = (props) => {
  
  const [docUrl, setDocUrl] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.previewType !== "") {
      setDocUrl(props.previewType)
    }
  }, [props]);

  const downloadHandler = () => {
    // props.onReceivechildProps({docUrl:props.DocName}, "Download", "");
  }

  return(
    <>
      <div style={{ textAlign: "center" }}>
          {docUrl !== null ?
            <>
              <DocumentViewer
                url={docUrl}
                // viewerUrl={docUrl}
                style={{ width: "100%", height: "calc(100vh - 120px)" }}
              >
              </DocumentViewer>
              <div className="floatBtn" onClick={downloadHandler}>
                <svg id="a852fdaa-156c-46c0-a3b6-364630b4c7fc" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 637.22 637.22">
                  <g>
                    <circle cx="318.61" cy="318.61" r="318.61" style={{ fill: "#404040" }} />
                    <g>
                      <path d="M363.11,176.87c0,19.08,0,38.16,0,57.24.05,22.81,16.39,39.29,39.21,39.45,16.08.12,32.15,0,48.23,0h4.55v74.13c-4.44,0-8.83-.12-13.22,0C414.6,348.65,390.4,358,369.14,375a15.67,15.67,0,0,1-10.62,3.64c-34.63-.15-69.26-.08-103.89-.08h-2.75c-6.49,0-10.1,2.62-10.11,7.28s3.6,7.29,10.1,7.29q46.85,0,93.7,0h4.81c-1.05,1.64-1.77,2.67-2.4,3.75-4.75,8.23-9.63,16.4-14.14,24.76-1.23,2.29-2.35,3.15-5,3.14-24.7-.11-49.4-.07-74.1-.07h-3.53c-5.92,0-9.33,2.59-9.45,7.09s3.36,7.35,9.54,7.38c9.15,0,18.29,0,27.44,0h48.67c-6.34,27.06-4.53,53.06,5.91,78.74H226.65c-11.18,0-20.73-3.61-27.76-12.68-4.94-6.37-6.73-13.71-6.73-21.63q0-55.68,0-111.34,0-80.37,0-160.75c0-14,5.62-24.78,18.32-31.26,3.07-1.57,6.59-2.27,9.91-3.37Zm-68.61,171q21.74,0,43.48,0a18.39,18.39,0,0,0,4.27-.34,6.94,6.94,0,0,0,5.4-7.19,6.76,6.76,0,0,0-6-6.64,26.72,26.72,0,0,0-4.29-.26h-85a29.08,29.08,0,0,0-4.67.3A6.49,6.49,0,0,0,242.1,339c-1.2,4.9,2.27,8.79,8.13,8.81C265,347.86,279.74,347.82,294.5,347.82Z" transform="translate(-43.92 -47.78)" style={{ fill: "#fff" }} />
                      <path d="M438.39,578.35c-6-1.08-12.13-1.8-18-3.3-44.26-11.19-76.92-48.47-81.52-92.81-5.67-54.73,28.63-103.38,82.56-117.09,39.89-10.14,85.49,6.15,110.29,39.15,17.35,23.07,25.37,49,21.79,77.52-5.32,42.52-28.34,72.51-68,88.79-9.86,4-20.88,5.24-31.36,7.74Zm16.36-65.57-1.36-.9v-4.12q0-47.22,0-94.46a33.32,33.32,0,0,0-.17-4.69,7.08,7.08,0,0,0-14-.23,28.14,28.14,0,0,0-.21,4.69q0,47.42,0,94.85v4l-1.16.55a36,36,0,0,0-2.33-3.11q-17.49-18.79-35-37.54a19.07,19.07,0,0,0-3.46-3.15A7.07,7.07,0,0,0,387,478.15a18.15,18.15,0,0,0,2.4,3.07q24.68,26.56,49.38,53.1c5.41,5.8,9,5.8,14.36,0q24.47-26.22,48.91-52.46a19.6,19.6,0,0,0,3.13-4c1.63-3.1,1.26-6.17-1.4-8.54a6.63,6.63,0,0,0-8.64-.63,19.83,19.83,0,0,0-3.43,3.18q-17.5,18.78-35,37.62A19.83,19.83,0,0,0,454.75,512.78Z" transform="translate(-43.92 -47.78)" style={{ fill: "#fff" }} />
                      <path d="M445.4,259.17c-15.7,0-30.2.26-44.68-.08-13.26-.32-22.61-9.85-23.05-23.82-.47-15.13-.09-30.29-.07-45.45,0-.22.19-.45.59-1.32Z" transform="translate(-43.92 -47.78)" style={{ fill: "#fff" }} />
                    </g>
                  </g>
                </svg>
              </div>
            </>
            : ""}
        </div>

    </>
  )
}

export default React.memo(AssociateCards);

// for dynamic Table config Object
const configuration = {
  isSearchbar: false,
  isPaging: true,
  isDropdownActions: false,
  isCheckbox: false,
  isActions: true,
  recordperPage: 10,
  currentPage: 1,
  totalRecords: 0,
  limitData: 10,
  isPaginationTop: false,
  ischeckAlignRight: true,
  isSearchServer: false,
  isPagingServer: false,
  isPageRange:false,
};

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isPositingsView: true,
  isPostingsDownload: false,
  isDelete: false,
  isRadiobtn: true
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "Document Name",
    mappingName: "name",
    class: "",
    theadClass: " text-center doctableheadtext",
    tbodyClass: " text-center doctablebodytext",
    isEdit: false,
    nagigate: null,
    width: "200px",
  },
  {
    id: 2,
    displayName: "Date and Time",
    mappingName: "docscreatedDate",
    theadClass: " text-center doctableheadtext",
    tbodyClass: " text-center doctablebodytext",
    isEdit: false,
    nagigate: null,
    width: "200px",
  },
  {
    id: 3,
    displayName: "Uploaded by",
    mappingName: "uploadedBy",
    class: "",
    theadClass: " text-center doctableheadtext",
    tbodyClass: " text-center doctablebodytext",
    isEdit: false,
    nagigate: null,
    width: "200px",
  },
  {
    id: 4,
    displayName: "Action",
    mappingName: "Actions",
    theadClass: " text-center doctableheadtext",
    tbodyClass: " text-center documentposttime",
    isEdit: false,
    width: "150px",
    nagigate: null,
  },
];

const pannelobj = {
  title: "Document view",
  description: "",
  bgImage: "",
};

