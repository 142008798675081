import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,Link } from "react-router-dom";
import * as _ser from "../../../_redux/_services/ClientsSlice";
import { getUserDetails } from '../../../_redux/CognitoSlice';
import GetJobId from '../../../_utilities/GetJobId';
import ClientsCreate from '../ClientsCreate/ClientsCreate';

const initalState = { data: null, message: '', isloader: true }

const ClientsEdit = () => {
  const getJobCode = GetJobId();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const [ viewClients, setviewClients ] = useState(null);

  const getDetails = useSelector(state => state.GetClients.data);

  useEffect(() => {
    if (getDetails === null && viewClients === null) {
      dispatch(_ser.getClientsAsync(getJobCode));
    }
  }, [dispatch, getJobCode]);

  useEffect(() => {
      if(getDetails !== null){
        setviewClients(getDetails[0]);
      }
      console.log(getDetails);
  },[getDetails])

  const code = useSelector(state => state.EditClients.data);

  const GetValidData = async (client) => {
    setDisableSubmitBtn(true)
    delete client.client_id;
    const userInfo = await getUserDetails()
    client['userId'] = userInfo.userId;
    let newobj = { id: client.id, name : client.clientName, type : "clients" };
      let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
      if(subPathObj && subPathObj.length > 0){
        let index = subPathObj.findIndex(x => x.id === newobj.id);
        if(index !== -1){
          subPathObj[index] = newobj;
        } else {
          subPathObj.push(newobj);
        }
      } else {
        subPathObj = [newobj];
      }
      localStorage.setItem("subpathObj", JSON.stringify(subPathObj));
    dispatch(_ser.editClientsAsync(getJobCode,client)).then(() =>{
      setDisableSubmitBtn(false)
    }).catch(() => {
      setDisableSubmitBtn(false)
    })
    
  }

  useEffect(() => {
    if (code === 200 && getJobCode !== null) {
      dispatch(_ser.editClientsStartReducer(initalState));
      navigate(`/ats/layout/clients/view/${getJobCode}`);
    }
  }, [code])

  const actionBtns = [{
    variant: "text",
    style: { color: "red", width:"6rem", fontWeight: "bolder",fontSize:'14px' },
    label: "Cancel",
    type: "back",
    class: 'buttonHover',
    navigateTo: `/ats/layout/clients/view/${getJobCode}`
  },
  {
    variant: "contained",
    style: { backgroundColor: "#ffd800", color: "black", borderRadius: "10px", width: "6rem",fontSize:'14px' },
    navigateTo: null,
    label: "Save",
    type: "submit",
    class: 'buttonHover1',
  }]

  return (
    <React.Fragment>
      <div style={{ padding: "0 10px" }} id="clientsView">
        {viewClients && viewClients.length !== 0 && viewClients?.clientName !== '' ? (
          <ClientsCreate componentType="Edit" input={viewClients} iniValue={initialDetails}
            actions={actionBtns} onValidData={GetValidData} disableBtn={disableSubmitBtn}  />
        ): <div className="nodatasection">
        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noData" />
        {"No data found"}</div>
      }
      
      </div>
    </React.Fragment>
  )
}

export default ClientsEdit;

const initialDetails = {
  clientName: "",
  // endClient: "",
  managerEmail: "",
  region: "",
  status: "",
  id: "",
  source: 1,
}