import { createSlice } from "@reduxjs/toolkit";
import * as _ser from '../_apiHelpers/ClientsApihelper';
import * as _msg from "../../_utilities/message";
import { updateLoader } from "../../_redux/LoaderSlice";
import { updateSnackbar } from "../../_redux/SnackbarSlice";

const initalState = { data: null, message: '' }
const initialSnackbar = { type: "", message: "" };

export const filterClientsSlice = createSlice({
    name: "FilterClients",
    initialState: initalState,
    reducers: {
        filterClientsStartReducer : (state, action) => {
            return { ...state, data: null }
        },
        filterClientsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.clients, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { filterClientsReducer, filterClientsStartReducer } = filterClientsSlice.actions;
export const filterClientsAsync = (keword) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.FilterClients(keword);
    if(res.data?.clients.length === 0){
        res.data.clients = null
    }
    dispatch(updateLoader(false));
    dispatch(filterClientsReducer(res));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const getClientsSlice = createSlice({
    name: "GetClients",
    initialState: initalState,
    reducers: {
        getClientsStartReducer : (state, action) => {
            return { ...state, data: null }
        },
        getClientsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data.client) {
                    let clientData = action.payload.data?.client; 
                   if(clientData.length>0){
                       clientData[0].status = (clientData[0].status === "Active" || clientData[0].status === "active") ? "1" : "0";
                       clientData[0]["client_id"] = clientData[0].id;
                   }
                    return { ...state, data: clientData, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getClientsReducer, getClientsStartReducer } = getClientsSlice.actions;
export const getClientsAsync = (id) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetClients(id);
    dispatch(updateLoader(false));
    dispatch(getClientsReducer(res));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}


export const addClientsSlice = createSlice({
    name: "AddClients",
    initialState: initalState,
    reducers: {
        addClientsStartReducer : (state, action) => {
            return { ...state, data: null }
        },
        addClientsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { addClientsReducer, addClientsStartReducer } = addClientsSlice.actions;
export const addClientsAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.AddClients(data);
    dispatch(updateLoader(false));
    dispatch(addClientsReducer(res));
    setTimeout(() => {
        dispatch(updateSnackbar({
            type: res.status === 200 && res.data.code === 200 ? "success" : "error",
            message: res.status === 200 && res.data.code === 200 ? _msg.ADDNEWCLIENT_SUCCESS_MSG : res.data.message
        }));
    }, 500);
}


export const editClientsSlice = createSlice({
    name: "EditClients",
    initialState: initalState,
    reducers: {
        editClientsStartReducer : (state, action) => {
            return { ...state, data: null }
        },
        editClientsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { editClientsReducer, editClientsStartReducer } = editClientsSlice.actions;
export const editClientsAsync = (id, data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.EditClients(id, data);
    dispatch(updateLoader(false));
    dispatch(editClientsReducer(res));
    setTimeout(() => {
        
        dispatch(updateSnackbar({
            type: res.status === 200 ? "success" : "error",
            message: res.status === 200 ? _msg.UPDATECLIENT_SUCCESS_MSG : _msg.UPDATECLIENT_ERROR_MSG
        }));
    }, 500);
}


export const getClientswithPagingSlice = createSlice({
    name: "GetClientswithPaging",
    initialState: initalState,
    reducers: {
        getClientswithPagingStartReducer : (state, action) => {
            return { ...state, data: null }
        },
        getClientswithPagingReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.clients, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getClientswithPagingReducer, getClientswithPagingStartReducer } = getClientswithPagingSlice.actions;
export const getClientswithPagingAsync = (limit, offset) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetClientswithPaging(limit, offset);
    if(res.data?.clients.length === 0){
        res.data.clients = null
    }
    dispatch(updateLoader(false));
    dispatch(getClientswithPagingReducer(res));
    if (res.status !== 200) {
        dispatch(updateSnackbar({ type: "error", message: res.clients.message }));
    }
}

export const getClientswithFieldsSlice = createSlice({
    name: "GetClientswithFields",
    initialState: initalState,
    reducers: {
        getClientswithFieldsStartReducer : (state, action) => {
            return { ...state, data: null }
        },
        getClientswithFieldsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.clients, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { getClientswithFieldsReducer, getClientswithFieldsStartReducer } = getClientswithFieldsSlice.actions;
export const getClientswithFieldsAsync = (fields) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetClientswithFields(fields);
    if(res.data?.clients.length === 0){
        res.data.clients = null
    }
    dispatch(updateLoader(false));
    dispatch(getClientswithFieldsReducer(res));
    if (res.status !== 200) {
        dispatch(updateSnackbar({ type: "error", message: res.clients.message }));
    }
}


export const deleteClientsSlice = createSlice({
    name: "DeleteClients",
    initialState: initalState,
    reducers: {
        deleteClientsStartReducer : (state, action) => {
            return { ...state, data: null }
        },
        deleteClientsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.code, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { deleteClientsReducer, deleteClientsStartReducer } = deleteClientsSlice.actions;
export const deleteClientsAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.DeleteClients(data);
    dispatch(updateLoader(false));
    dispatch(deleteClientsReducer(res));
    setTimeout(() => {
        
        dispatch(updateSnackbar({
            type: (res.status === 200) ? "success" : "error",
            message: (res.status === 200) ? _msg.DELETECLIENT_SUCCESS_MSG : _msg.DELETECLIENT_ERROR_MSG,
        }));
    }, 0);
}


export const filterClientsWithPagingSlice = createSlice({
    name: "FilterClientsWithPaging",
    initialState: initalState,
    reducers: {
        filterClientsWithPagingStartReducer : (state, action) => {
            return { ...state, data: null }
        },
        filterClientsWithPagingReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data?.clients, message: action.payload.message };
                }
            } else {
                state = { ...state, message: action.payload.message, data: null }
            }
        }
    }
});
export const { filterClientsWithPagingReducer, filterClientsWithPagingStartReducer } = filterClientsWithPagingSlice.actions;
export const filterClientsWithPagingAsync = (limit, offset, filterValue) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.FilterClientsWithPaging(limit, offset, filterValue);
    dispatch(updateLoader(false));
    dispatch(filterClientsWithPagingReducer(res));
    // dispatch(updateSnackbar({
    //     type: (res.status === 200) ? "success" : "error",
    //     message: (res.status === 200) ? res.data.message : res.data.message,
    // }));
}

export const getClientsCommentsSlice = createSlice({
    name: "GetClientsComments",
    initialState: initalState,
    reducers: {
        getClientsCommentsStartReducer : (state, action) => {
            return { ...state, data: null, isLoading: false }
        },
        getClientsCommentsReducer: (state, action) => {
            if (action.payload.status === 200) {
                if (action.payload.data) {
                    return { ...state, data: action.payload.data.auditlogs, message: action.payload.data.message, isloader: false };
                }
            } else {
                state = { ...state, message: action.payload.data.message, data: null, isloader: false }
            }
        }
    }
});
export const { getClientsCommentsReducer, getClientsCommentsStartReducer } = getClientsCommentsSlice.actions;
export const getClientsCommentsAsync = (data) => async (dispatch) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await _ser.GetClientsComments(data);
    dispatch(getClientsCommentsReducer(res));
    dispatch(updateLoader(false));
    // dispatch(updateSnackbar({ type: res.status === 200 ? "success" : "error", message: res.data.message }));
}