export const getResumeInfo = (response, type) => {
  const responseKey = `${type}Data`;
  const responseData = response?.[responseKey];
  const currentValue = responseData.resumesForCurrentUser || 0;
  const avgValue = responseData?.averageResumes?.toFixed(0) || 0;
  const highestValue = responseData?.highestResumes?.toFixed(0) || 0;
  const avgPercentage = highestValue > 0 ? (avgValue / highestValue) * 100 : 0;
  return {
    currentValue,
    avgValue,
    highestValue,
    avgPercentage,
  };
};

export const getSubmissionInfo = (response, type) => {
  const responseKey = `${type}Data`;
  const responseData = response?.[responseKey];
  const currentValue = responseData.submissionsForCurrentUser || 0;
  const avgValue = responseData?.avgSubmissions?.toFixed(0) || 0;
  const highestValue = responseData?.highestSubmissions?.toFixed(0) || 0;
  const avgPercentage = highestValue > 0 ? (avgValue / highestValue) * 100 : 0;
  return {
    currentValue,
    avgValue,
    highestValue,
    avgPercentage,
  };
};

export const getInterviewInfo = (response, type) => {
  const responseKey = `${type}Data`;
  const responseData = response?.[responseKey];
  const currentValue = responseData.submissionsinInterviewForCurrentUser || 0;
  const avgValue = responseData?.averageSubmissionsinInterview?.toFixed(0) || 0;
  const highestValue =
    responseData?.highestSubmissionsinInterview?.toFixed(0) || 0;
  const avgPercentage = highestValue > 0 ? (avgValue / highestValue) * 100 : 0;
  return {
    currentValue,
    avgValue,
    highestValue,
    avgPercentage,
  };
};

export const getSelectedInfo = (response, type) => {
  const responseKey = `${type}Data`;
  const responseData = response?.[responseKey];
  const currentValue = responseData.selectedForCurrentUser || 0;
  const avgValue = responseData?.averageSelected?.toFixed(0) || 0;
  const highestValue = responseData?.highestSelected?.toFixed(0) || 0;
  const avgPercentage = highestValue > 0 ? (avgValue / highestValue) * 100 : 0;
  return {
    currentValue,
    avgValue,
    highestValue,
    avgPercentage,
  };
};
