import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../../_redux/CognitoSlice';
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import * as _ser from '../../../_redux/_services/ApplicantsSlice';
import { customFieldsAsync, customFieldsStartReducer } from '../../../_redux/_services/UserManagerSlice';
import { decrypt } from '../../../_utilities/_encryptDecryptHelper';
import { defaultApplicantsData } from '../../../_utilities/defaultfieldsModel';
import Loading from '../../../components/Loader/Loading';

const initalState = { data: null, message: '', isloader: true }

export const ApplicantsRenderData = (props) => {
    const [isUpload, setIsUpload] = useState(true);
    const [uploadFile, setUploadFile] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [sourced, setSourced] = useState('');
    const [imgList, setImgList] = useState([]);
    const [imgData, setImgData] = useState([]);
    const [sourceModelValues, setSourceModelValues] = useState([]);

    const customFieldsData = useSelector(state => state.CustomFields.data);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [GetUserId, setUserId] = useState(null);

    useEffect(()=>{
console.log(props);

    },[props])

    useEffect(() => {
        if (GetUserId === null) {
            let userId = JSON.parse(decrypt('GetUserId'))
            setUserId(userId);
            dispatch(customFieldsAsync(userId));
        }
    }, [GetUserId])

    // const bulkUploadResumeData = useSelector(store => store.BulkUploadResume.data);
    const bulkUploadResumeCode = useSelector(store => store.BulkUploadResume.code);
    const uploadResumeData = useSelector(store => store.UploadResume.data);
    const duplicateResume = useSelector(store => store.UploadResume.code);
    const updateResumeData = useSelector(store => store.UploadResume);
    const isDuplicats = useSelector(store => store.UploadResume.isDuplicats);
    // const uploadResumemessage = useSelector(store => store.UploadResume.data);  //Applicant Added successfully

    const UploadImgHandler = async (e, files) => {
        if(imgList.length !== 0 && sourced !== ""){
            const userInfo = await getUserDetails();
            let userDetails = JSON.parse(decrypt('GetUserInfo'));
            e.preventDefault()
            setUploadFile(imgData[0]);
            setIsLoading(true);
            
            let formData = new FormData();
            for (let i = 0; i < imgData.length; i++) {
                formData.append("Resume", imgData[i]);
            }
            formData.append("customerId", GetUserId);
            formData.append("userId", userInfo.userId);
            if(imgData.length === 1){
                dispatch(_ser.uploadResumeAsync(formData, true));
            } else {
                formData.append("userFirstName",userInfo.firstName);
                formData.append("userLastName",userInfo.lastName);
                formData.append("email", userInfo.email);
                formData.append("resumesourceId", sourced.id ? sourced.id : "");
                formData.append("resumesource", sourced.name ? sourced.name : "");
                formData.append("submittedBy",userDetails[0].userId);
                formData.append("DbUserId",userDetails[0].userId);
                formData.append("source", "ATS");
                dispatch(_ser.bulkUploadResumeAsync(formData, true, props.isAssociate));
            }
        }else{
            dispatch(updateSnackbar({ type: 'error', message: "All fields are required" }));
        }
    }

    useEffect(()=>{
        if(bulkUploadResumeCode === 200 ){
            setIsLoading(false);
            setIsUpload(false);
            dispatch(_ser.bulkUploadResumeStartReducer(initalState));
            props.onReceivechildProps(false, "close")
            // console.log(bulkUploadResumeData);

        } else {
            setIsLoading(false);
        }
    },[bulkUploadResumeCode])

    // useEffect(() => {
    //     if (uploadResumemessage !== null) {
    //         setIsLoading(false);
    //         setIsUpload(false);
    //         dispatch(_ser.uploadResumeStartReducer(initalState))
    //     }
    // }, [uploadResumemessage])

    useEffect(() => {
        if (uploadResumeData !== null && sourced !== '' && uploadFile.length !== 0) {
            setIsLoading(false);
            setIsUpload(false);
            let json = JSON.parse(JSON.stringify(uploadResumeData[0]));
            navigate('/ats/layout/applicants/create', { state: { applicant: json, sourceId: sourced, uploadfile: uploadFile, isAssociate: props.isAssociate, isduplicate : props.isduplicate } });
            dispatch(_ser.uploadResumeStartReducer(initalState))
        }
    }, [uploadResumeData])

    useEffect(() => {
        if(duplicateResume === 500 && updateResumeData.updatedata !== null){
            setIsLoading(false);
            setIsUpload(true);
            dispatch(_ser.uploadResumeStartReducer(initalState))
            let obj = {
                data : updateResumeData.updatedata[0],
                message: updateResumeData.message,
                id: updateResumeData.id,
                oldApplicantName: updateResumeData.oldApplicantName,
            }
            props.onManualPopup(false, sourced, uploadFile, obj);
        }
        if (duplicateResume === 500) {
            setIsLoading(false);
            setIsUpload(true);
            dispatch(_ser.uploadResumeStartReducer(initalState))
        } else if(duplicateResume === 400){
            setIsLoading(false);
            setIsUpload(true);
            dispatch(_ser.uploadResumeStartReducer(initalState))
            props.onManualPopup(true, sourced, uploadFile, null);
        }
        if(isDuplicats !== null){
            setIsLoading(false);
            props.onManualPopup(false, null, null, {data:isDuplicats});
        }
    }, [duplicateResume, updateResumeData, isDuplicats])

    useEffect(() => {
        console.log("isUpload", isUpload)
        console.log("isLoading", isLoading)
    }, [isUpload, isLoading])

    const onRemoveItem = (item) => {
        let imgNames = JSON.parse(JSON.stringify(imgList));
        let ImgData = JSON.parse(JSON.stringify(imgData));
        let findIndex = imgNames.findIndex((x) => x.id === item.id);
        if (findIndex !== -1) {
            imgNames.splice(findIndex, 1);
            ImgData.splice(findIndex, 1);
            setImgList(imgNames);
        }
        // imgList.splice(parseInt(item.id), 1);
        // setImgList(imgList);
         imgData.splice(parseInt(findIndex), 1);
            setImgData(imgData);
    };

    const fileImgHandler = (event) => {
        let files = event.target.files;
        let imgNames = JSON.parse(JSON.stringify(imgList));
        let imgObj = imgData;  // JSON.parse(JSON.stringify(imgData));
        var fileInput = document.getElementById('fileId');
        var filePath = fileInput.value;
        var allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;
        if (!allowedExtensions.exec(filePath)) {
            dispatch(updateSnackbar({ type: 'error', message: "Invalid file format. Please upload a Doc, Pdf, or Docx file" }));
            fileInput.value = '';
            return false;
        }   
        else {
            if(files.length + imgData.length > 25){
                dispatch(updateSnackbar({ type: 'error', message: "Reached maximum upload limit of 25 files" }));  //Max limit 10 files
                // fileInput.value = '';
            }

            let tempImgNames = [],
            tempImgObj = [];
        for (let i = 0; i < files.length; i++) {
            let uniqueId = (imgData.length > 0) ? imgData.length + i : i;
            let obj = { id: uniqueId, name: files[i].name };
            // const existingIndex = imgNames.findIndex(existing => existing.name === files[i].name);
            // if (existingIndex !== -1) {
            //     imgNames[existingIndex] = obj; 
            //     imgObj[existingIndex] = files[i]; 
            // } else {
                // add new entries
                tempImgNames.push(obj);
                tempImgObj.push(files[i]);
            // }
        }
        imgNames.push(...tempImgNames);
        imgObj.push(...tempImgObj);
        setImgList(imgNames.splice(0, 25));
        setImgData(imgObj.splice(0, 25));
        }
        event.target.value = '';
    };
    useEffect(() => {
        let defaultPostingModules = defaultApplicantsData.filter(x => x.moduleId.toString() === "2");
        if (defaultPostingModules.length > 0) {
            defaultPostingModules.forEach(list => {
                if (list.fieldName === "Source") {
                    setSourceModelValues(list.customValues);
                }
            })
        }
    }, [])

    useEffect(()=>{
        if(imgList.length>0){
            props.onReceivechildProps(true,null,true)
        }else{
            props.onReceivechildProps(true,null,false)
        }
    },[imgList])

    

    useEffect(() => {
        if (customFieldsData && customFieldsData.length > 0) {
            const data = JSON.parse(JSON.stringify(customFieldsData));
            let overrideDefaultPostingFieldsData = data.filter(x => x.moduleId.toString() === "2" && x.fieldType === "default");
            if (overrideDefaultPostingFieldsData.length > 0) {
                overrideDefaultPostingFieldsData.forEach(list => {
                    if (list.fieldName === "Source") {
                        let updatedCustomValues = JSON.parse(list.customValues);
                        let modifiedCustomValues = [];
                        updatedCustomValues.forEach(list => {
                            if (list !== "Cloudsourcing portal" && list !== "Employee portal" && list !== "Vendorsourcing portal" && list !== "JobBoard portal" && list !== "Others") {
                                modifiedCustomValues.push(list);
                            }
                        })
                        setSourceModelValues(modifiedCustomValues);
                    }
                })
            }
            dispatch(customFieldsStartReducer({}));
        }
    }, [customFieldsData])

    const onChangehandler = (e, type)=> {
        if(type === "source"){
            let obj = { id : e.target.value, name : sourceModelValues[(parseInt(e.target.value) - 1)]};
            setSourced(obj);
        }
    }

    return (
        <>
            <div>
                <FormControl
                    fullWidth
                >
                    <InputLabel className="boldSelectlabel"shrink = {true} >Sourced from *</InputLabel>
                    <Select
                        value={sourced?.id}
                        name="Sourced From"
                        autoComplete="off"
                        label="Sourced From*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        onChange={(e) => onChangehandler(e, "source") }  // { setSourced(e.target.value) }
                        style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                        className='sourcedfrominput'
                    >
                        {/* <MenuItem className='sourcedselectmenulist' value="1">LinkedIn</MenuItem>
                        <MenuItem className='sourcedselectmenulist' value="2">Indeed</MenuItem>
                        <MenuItem className='sourcedselectmenulist' value="3">Zoho Recruit</MenuItem>
                        <MenuItem className='sourcedselectmenulist' value="4">Zip Recruit</MenuItem>
                        <MenuItem className='sourcedselectmenulist' value="5">Monster</MenuItem>
                        <MenuItem className='sourcedselectmenulist' value="6">Social Media</MenuItem>
                        <MenuItem className='sourcedselectmenulist' value="7">Ceipal</MenuItem>
                        <MenuItem className='sourcedselectmenulist' value="8">Reference</MenuItem>
                        <MenuItem className='sourcedselectmenulist' value="9">Career Builder</MenuItem>
                        <MenuItem className='sourcedselectmenulist' value="10">Others</MenuItem> */}
                        {sourceModelValues.map((name, index) => (
                            <MenuItem className="selectOptions" key={name} value={(index + 1).toString()}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>

                </FormControl>
            </div>

            {isUpload === true ?
                <div className='applicantsupload'>
                    <div className='Applicantuploadfileicon'>
                        {imgList.length === 0 && <img src={process.env.PUBLIC_URL + "/assests/img/uploadBgIcon.svg"} alt="" />}
                    </div>
                    <Button
                        variant="contained"
                        component="label"
                        className="appuploadresume"
                        disabled={imgList.length >= 25}
                    >
                        <span>
                            <b style={{ color: "#FFD800" }}>Upload</b>
                            <b style={{ color: "#9E9E9E" }}> browse file to upload </b></span>
                        <input
                            hidden
                            type="file"
                            id="fileId"
                            multiple
                            onChange={(e) => fileImgHandler(e)} />
                    </Button>
                    <p className="docsupporttext">Supported file formats: Doc, Pdf, Docx </p>
                    {imgList.length > 0 ? (
                        <ul className="filelistupload">
                            {imgList &&
                                imgList.map((list) => {
                                    return (
                                        <li key={list.id}>
                                            <p>{list.name}</p>
                                            <span
                                                className="closeImgbtn"
                                                onClick={() => onRemoveItem(list)}
                                            >
                                                <DeleteOutlineIcon />
                                            </span>
                                        </li>
                                    );
                                })}
                        </ul>
                    ) : (
                        ""
                    )}
                    <div style={{textAlign:"right",marginTop:"8%"}}>
                        <Button
                            variant="text"
                            type="button"
                            className="addApplicantcancelBtn"
                            onClick={() => {
                                props.onReceivechildProps(false, "close");
                            }}
                            size="small"
                        >
                           <Link href="#" style={{color:"red", fontSize:"14px"}} underline="always"> Cancel</Link>
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            size="small"
                            className="saveBtn"
                            onClick={(e) => UploadImgHandler(e, e.target.files)}
                            // disabled={imgList.length === 0 || sourced === "" ? true : false}
                        >
                            Save
                        </Button>
                    </div>
                    {/* <div className="rightimgBox">
                        <img src={`${process.env.PUBLIC_URL}/assests/img/AddDocumentsIcon.svg`} alt="" />
                    </div> */}
                    {isLoading === true ? <Loading /> : ''}
                </div> :
                ''
            }
        </>
    )
}