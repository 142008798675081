import React, { useEffect } from "react";
import "./PostingCreateTabs.css";
import PostingsCreate from "./PostingsCreate";
import { Button, FormControl, TextField } from "@mui/material";
import { useState } from "react";
import CreateDocuments from "../PostingsDocuments/CreateDocuments";
import JobPortal from "../PostingsJobPortal/JobPortal";
import Switch from "@mui/material/Switch";
import * as _ser from "../../../_redux/_services/JobPostingSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../../_redux/CognitoSlice";
import { useLocation, useNavigate,Link } from "react-router-dom";
import MessageBar from "../../../components/SnackBar/MessageBar";
import { updateSnackbar } from "../../../_redux/SnackbarSlice";
import { filterClientsAsync } from "../../../_redux/_services/ClientsSlice";
import PostingsPortals from "../PostingsPortals/PostingsPortals";
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";

const initalState = { data: null, message: "", isloader: true };

const PostingsCreateTabs = () => {
  const [isJobDetails, setIsJobDetails] = useState(false);
  const [isDocData, setIsDocData] = useState(false);
  const [isQuestionData, setIsQuestionData] = useState(false);

  const [getCreatedJob, setGetCreatedJob] = useState(null);
  const [activeComponent, setActiveComponent] = useState("");
  const [activeStep, setActiveStep] = useState([""]);
  const [jobDetailsID, setJobDetailsID] = useState(null);
  const [clientList, setClientList] = useState([]);

  const [listOfPF, setListOfPF] = useState(
    JSON.parse(JSON.stringify(listOfPlatform))
  );
  const [GetUserInfo, setGetUserInfo] = useState("");
  const [flatFee, setFlatFee] = useState('0');
  const [flatFeeCountryId, setFlatFeeCountryId] = useState('0');

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const Addjobpostingscode = useSelector((state) => state.Addjobpostings.data);
  const insertId = useSelector((state) => state.Addjobpostings.insertId);
  const showMessage = useSelector((state) => state.Snackbar.message);
  const showMsgType = useSelector((state) => state.Snackbar.type);
  const getDetails = useSelector((state) => state.Jobpostingsbyid.data);
  const updateCode = useSelector((state) => state.Editjobpostings.data);
  const clientVal = useSelector((state) => state.FilterClients.data);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (GetUserInfo && GetUserInfo.length > 0) {
      let userDetails = JSON.parse(JSON.stringify(GetUserInfo))[0];
      let val = userDetails.flatFee !== null && userDetails.flatFee !== 0 ? userDetails.flatFee : "";
      let CountryIdval = userDetails.flatFeeCountryId !== null && userDetails.flatFeeCountryId !== 0 ? userDetails.flatFeeCountryId : "";
      setFlatFee(val);
      setFlatFeeCountryId(CountryIdval);
    } else {
      setGetUserInfo(JSON.parse(decrypt("GetUserInfo")))
    }
  }, [GetUserInfo]);

  const updateHandler = (val) => {
    setFlatFee(val);
  }
  
  const updateCountryHandler = (val) => {
    setFlatFeeCountryId(val);
  }

  useEffect(() => {
    setIsJobDetails(false);
    setIsDocData(false);
    setIsQuestionData(false);
    const url = window.location.pathname;
    const path = url.substring(url.lastIndexOf("/") + 1);
    setActiveComponent(path);
    let Index = postingTabs.findIndex((x) => x.name === path);
    let array = [];
    for (var i = Index; i >= 0; i--) {
      array.push(postingTabs[i].name);
    }
    setActiveStep(array);
    if (location.state?.jobId) {
      sessionStorage.setItem("jobDetailsID", location.state.jobId);
      setJobDetailsID(location.state.jobId);
      if (path === "portals") {
        dispatch(_ser.jobpostingsbyidAsync(location.state.jobId));
      }
    } else {
      let ID = sessionStorage.getItem("jobDetailsID");
      setJobDetailsID(ID);
      if (path === "portals" || path === "questions") {
        dispatch(_ser.jobpostingsbyidAsync(ID));
      }
    }
  }, [location]);

  useEffect(() => {
    console.log("jobDetailsID", jobDetailsID);
  }, [jobDetailsID]);

  useEffect(() => {
    console.log(
      "isJobDetails, isDocData, isQuestionData",
      isJobDetails,
      isDocData,
      isQuestionData
    );
  }, [isJobDetails, isDocData, isQuestionData]);

  const onChangeHandler = (e, type) => {
    if (type === "jobdetails") {
      setIsJobDetails(true);
    } else if (type === "document") {
      setIsDocData(true);
    } else if (type === "questions") {
      setIsQuestionData(true);
    }
  };

  useEffect(() => {
    if (clientVal && clientVal.length > 0) {
      let clientValue = JSON.parse(JSON.stringify(clientVal));
      let ClientData = [];
      clientValue.forEach((e) => {
        if (e.status === "Active") {
          e.clientName = e.clientName;
          ClientData.push(e);
        }
      });
      setClientList(ClientData);
    } else {
      dispatch(filterClientsAsync(""));
    }
  }, [clientVal]);

  // useEffect(() => {
  //   if (clientList.length > 0) {
  //     let data = JSON.parse(JSON.stringify(getDetails));
  //     let clientValue = JSON.parse(JSON.stringify(clientList));
  //     let findClientIndex = clientValue.findIndex((x) => x.clientName === data.clientName);
  //     if (findClientIndex !== -1) {
  //       data["clientId"] = clientValue[findClientIndex].id.toString();
  //       setGetCreatedJob(data);
  //     }
  //   }
  // }, [clientList, getDetails]);

  const onReceiveDataHandler = (data, isData, type) => {
    if (type === "jobdetails") {
      saveJobDetails(data);
    } else if (type === "document") {
      if (data.length > 0) {
        saveJobDocument(data);
      } else {
        navigate("/ats/layout/jobpostings/create/questions");
      }
    } else if (type === "questions") {
      navigate("/ats/layout/jobpostings/create/portals");
    }
  };

  const saveJobDetails = async (data) => {
    let emails = data.listofRecruitersemails;
    let formData = new FormData();
    formData.append("jobTitle", data.jobTitle);
    formData.append("externalSystemID", data.externalSystemID);
    formData.append("workModel", data.workModel);
    formData.append("jobStatus", data.jobStatus);
    formData.append("jobType", data.jobType);
    formData.append("clientId", data.clientId);
    formData.append("clientBillRate", data.clientBillRate);
    formData.append("payBillRate", data.payBillRate);
    formData.append("location", data.location);
    formData.append("manager", data.manager);
    formData.append("teamLead", data.teamLead);
    formData.append("recruiters", data.recruiters);
    emails.forEach(item => {
      formData.append(Object.keys(item)[0], JSON.stringify(item[Object.keys(item)]));
    })
    formData.append("priority", data.priority);
    formData.append("jobDesc", data.jobDesc);
    formData.append("primarySkills", data.primarySkills);
    formData.append("secondarySkills", data.secondarySkills);
    formData.append("jobDescForSM", data.jobDescForSM);
    formData.append("customFields", data.customFields);
    formData.append("userId", data.userId);
    formData.append("userFirstName", data.userFirstName);
    formData.append("userLastName", data.userLastName);
    formData.append("customerId", data.customerId);
    formData.append("ATSPortal", data.ATSPortal);
    formData.append("VSPortal", data.VSPortal);
    formData.append("CSPortal", data.CSPortal);
    formData.append("EPortal", data.EPortal);
    formData.append("JBPortal", data.JBPortal);
    formData.append("source", data.source);
    dispatch(_ser.addjobpostingsAsync(formData, true));
  };

  useEffect(() => {
    if (
      Addjobpostingscode === 200 &&
      (isJobDetails === true || isJobDetails === null)
    ) {
      dispatch(_ser.addjobpostingsStartReducer(initalState));
      navigate("/ats/layout/jobpostings/create/document", {
        state: { jobId: insertId },
      });
      dispatch(_ser.jobpostingsbyidAsync(insertId));
    }
    console.log("Addjobpostingscode", Addjobpostingscode);
    console.log("isJobDetails", isJobDetails);
  }, [Addjobpostingscode]);

  useEffect(() => {
    if (getDetails !== null && getDetails !== undefined) {
      let postingData = JSON.parse(JSON.stringify(getDetails));
      postingData.jobType = postingData.jobType.split(",");
      setGetCreatedJob(postingData);
      dispatch(_ser.jobpostingsbyidStartReducer(initalState));
    }
  }, [getDetails]);

  const saveJobDocument = async (data) => {
    const userInfo = await getUserDetails();
    let formData = new FormData();
    let checkFileType = data.filter(
      (elem) =>
        elem.name.match(/jpg/) ||
        elem.name.match(/png/) ||
        elem.name.match(/jpeg/) ||
        elem.name.match(/JPEG/) ||
        elem.name.match(/SVG/) ||
        elem.name.match(/gif/)
    );
    if (checkFileType.length > 0) {
      console.log("jpg existssss");
      alert("file does not support");
    } else {
      for (let i = 0; i < data.length; i++) {
        // formData.append(`jobFiles[]`, data[i]);
      }
      formData.append(`jobFiles`, data[0]);
      formData.append("id", jobDetailsID);
      formData.append("userId", userInfo.userId);
      dispatch(_ser.uploadDocumentsAsync(jobDetailsID, formData, true))
        .then(() => {
          navigate("/ats/layout/jobpostings/create/questions");
        })
        .catch(() => {
          // dispatch(updateSnackbar({ type: 'error', message: "Documents not saved!!!" }));
        });
    }
  };

  const onChangePlatformHandler = (e, item) => {
    let data = JSON.parse(JSON.stringify(listOfPF));
    let Index = data.findIndex((x) => x.id === item.id);
    if (Index !== -1) {
      data[Index].checked = e.target.checked;
      data[Index].value = e.target.checked ? 1 : 0;
      setListOfPF(data);
    }
  };

  const onSavePortalHandler = async () => {
    if (getCreatedJob !== null) {
      let newObj = JSON.parse(JSON.stringify(getCreatedJob));
      newObj.CSPortal = listOfPF[0].value;
      newObj.VSPortal = listOfPF[1].value;
      newObj.EPortal = listOfPF[2].value;
      newObj.JBPortal = listOfPF[3].value;
      // newObj["clientId"] = await getClientId(newObj.clientName);
      newObj.flatFee = flatFee;
      newObj.flatFeeCountryId = flatFeeCountryId;
      dispatch(_ser.editjobpostingsAsync(newObj.id, newObj));
    }
  };

  const getClientId = (name) => {
    let findClientIndex = clientList.findIndex((x) => x.clientName === name);
    if (findClientIndex !== -1) {
      return clientList[findClientIndex].id;
    }
  };

  useEffect(() => {
    if (updateCode === 200) {
      dispatch(_ser.editjobpostingsStartReducer(initalState));
      navigate("/ats/layout/jobpostings/landing");
      sessionStorage.removeItem("jobDetailsID");
    }
  }, [updateCode, dispatch]);

  return (
    <>
      {showMessage !== "" ? (
        <MessageBar message={showMessage} type={showMsgType} />
      ) : (
        ""
      )}
      <div className="customSteps">
        <ul>
          {postingTabs.map((items, i) => {
            return (
              <li
                className={activeStep.includes(items.name) ? "active" : ""}
                id={activeComponent === items.name ? "active1" : ""}
              >
                <span>{items.id}</span>
                <span>{items.title}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="stepsSection" id="stepsContent">
        {activeComponent === "jobdetails" && (
          <div className="stepsInnerArea">
            <div className="stepsActionButtons">
              <Button
                variant="contained"
                className="onHover"
                size="medium"
                onClick={(e) => {
                  onChangeHandler(e, "jobdetails");
                }}
                style={{
                  backgroundColor: "#ebc700",
                  color: "#3b4046",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  fontSize:"16px",
                }}
              >
                {" "}
                Upload Details{" "}
              </Button>
            </div>
            <PostingsCreate
              isJobDetails={isJobDetails === true ? isJobDetails : isJobDetails}
              onResetValue={() => {
                setIsJobDetails(null);
              }}
              onReceiveData={onReceiveDataHandler}
            />
          </div>
        )}
        {activeComponent === "document" && (
          <div className="stepsInnerArea">
            <div className="stepsActionButtons" style={{ marginTop: "-76px" }}>
              <Button
                variant="contained"
                className="onHover"
                onClick={(e) => {
                  onChangeHandler(e, "document");
                }}
                style={{
                  backgroundColor: "#ebc700",
                  color: "#3b4046",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  fontSize:"16px",
                }}
              >
                {" "}
                Upload Documents{" "}
              </Button>
            </div>
            <CreateDocuments
              isDocData={isDocData ? isDocData : isDocData}
              onReceiveData={onReceiveDataHandler}
            />
          </div>
        )}
        {activeComponent === "questions" && (
          <div className="stepsInnerArea">
            <div className="stepsActionButtons" style={{ marginTop: "-75px" }}>
              <Button
                variant="contained"
                className="onHover"
                size="medium"
                onClick={(e) => {
                  onChangeHandler(e, "questions");
                }}
                style={{
                  backgroundColor: "#ebc700",
                  color: "#3b4046",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  fontSize:'16px',
                  width:"150px"

                }}
              >
                {" "}
                Add Questions{" "}
              </Button>
            </div>
            <h4 className="subHeading" style={{ paddingLeft: "35px" }}>
              Please add all the screening questions relevant to the Job Posting
            </h4>
            <JobPortal
              isQuestionData={isQuestionData ? isQuestionData : isQuestionData}
              jobDetailsID={jobDetailsID}
              onReceiveData={onReceiveDataHandler}
              getCreatedJob={getCreatedJob}
            />
          </div>
        )}
        {activeComponent === "portals" && (
          <div className="stepsInnerArea">
            <div className="stepsActionButtons" style={{ marginTop: "-72px" }}>
              <Button
                variant="text"
                className="buttonHover"
                size="small"
                onClick={() => navigate("/ats/layout/jobpostings/landing")}
                style={{
                  color: "red",
                  fontWeight: "bolder",
                  hover: "textbold",
                }}
              >
                {" "}
                <Link href="#" style={{color:"red", fontSize:"14px", width:"100px"}} underline="always"> Cancel</Link> {" "}
              </Button>
              <Button
                variant="contained"
                size="medium"
                className="onHover"
                onClick={onSavePortalHandler}
                style={{
                  backgroundColor: "#ebc700",
                  color: "#3b4046",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  width:"150px",
                  fontSize:"16px",
                  margin:"0px"

                }}
              >
                {" "}
                Create Job{" "}
              </Button>
            </div>
            <PostingsPortals componentType="Create" onReceiveHandler={onChangePlatformHandler} data={listOfPF} flatFee={flatFee} flatFeeCountryId={flatFeeCountryId} updateHandler={updateHandler} updateCountryHandler={updateCountryHandler} />
          </div>
        )}
      </div>
    </>
  );
};

export default PostingsCreateTabs;

const postingTabs = [
  { id: 1, name: "jobdetails", title: "Job Details" },
  { id: 2, name: "document", title: "Documents" },
  { id: 3, name: "questions", title: "Questions" },
  { id: 4, name: "portals", title: "Portals" },
];

const listOfPlatform = [
  {
    id: 3,
    name: "CSPortal",
    title: "Cloud Sourcing Platform",
    checked: false,
    value: 0,
  },
  {
    id: 2,
    name: "VSPortal",
    title: "Vendor Sourcing Platform",
    checked: false,
    value: 0,
  },
  {
    id: 4,
    name: "EPortal",
    title: "Employee Platform",
    checked: false,
    value: 0,
  },
  {
    id: 5,
    name: "JBPortal",
    title: "Job Board Platform",
    checked: false,
    value: 0,
  },
];

/// list of portals in DB  ///
//  1  ATS
//  2  Vendor Sourcing
//  3  Cloud Sourcing
//  4  Employee Portal
//  5  JobBoard Portal
