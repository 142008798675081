import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateSnackbar } from "../../_redux/SnackbarSlice";
import * as _ser from '../../_redux/_services/ClientsSlice';
import * as _postingser from "../../_redux/_services/JobPostingSlice";
import * as _props from '../../_redux/_services/JobPostingSlice';
import * as _userser from "../../_redux/_services/UserManagerSlice";
import { decrypt } from "../../_utilities/_encryptDecryptHelper";
import './GenerateTemplateStyles.css';


const initialSnackbar = { type: "", message: "" };

const GenerateTemplate = ({ isAssociatedProfile, applicantDetails, jobCode, tempApplicant, applicantCodes, onReceiveProps }) => {
  const [mail, setMail] = useState([]);
  const [mailTemplate, setMailTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [profileDetails, setProfileDetails] = useState({ jobCode: "", candidateName: "", email: "" });
  const [jobCustomFeilds, setJobCustomFeilds] = useState("");
  const [customFields, setCustomFields] = useState("");
  const [customJobKeys, setCustomJobKeys] = useState("");
  const [getClients, setGetClients] = useState("");
  const [customApplicantKeys, setCustomApplicantKeys] = useState("");
  const [isGenerate, setIsGenerate] = useState(true);
  const [jobpostings, setJobpostings] = useState(null);
  const [selectJobCode, setSelectJobCode] = useState({ id: "", jobCode: "" });
  const [jobDetails, setjobDetails] = useState(null);
  const [templateCustomKeys, setTemplateCustomKeys] = useState("");
  const [templateCustomValues, setTemplateCustomValues] = useState("");


  const dispatch = useDispatch();
  const initalState = { data: null, message: '', isloader: true }

  const listofTemplates = useSelector(state => state.GetEmailTemplates.data);
  const selectedTemplateResponse = useSelector(state => state.GetEmailTemplatebyId.data);
  const responseData = useSelector((state) => state.Listjobpostings.data);
  const customFieldsResponse = useSelector((state) => state.CustomFields.data);
  const getDetails = useSelector((state) => state.Jobpostingsbyid.data);
  const sendEmail = useSelector((state) => state.SendEmail.data);
  const ClientsData = useSelector(state => state.GetClients.data);
  //////////////////////////////// TO DO///////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (customFieldsResponse && customFieldsResponse.length > 0) {
      const customfielddata = JSON.parse(JSON.stringify(customFieldsResponse));
      let posingsObj = [];
      let applicantCustomObj = [];
      customfielddata.forEach((list, i) => {
        if (parseInt(list.moduleId) === 1) {
          if (list.fieldType === "custom") {
            let newObj = { id: i, name: list.fieldName }
            // console.log(list.fieldName);
            posingsObj.push(newObj);
          }
        } else {
          if (list.fieldType === "custom") {
            let newObj = { id: i, name: list.fieldName }
            applicantCustomObj.push(newObj)
          }
        }
      });
      setCustomJobKeys(posingsObj);
      setCustomApplicantKeys(applicantCustomObj);
    }
  }, [customFieldsResponse, listofTemplates])

  // console.log(customJobKeys);
  // console.log(customApplicantKeys);



  //////////////////////////////// TO DO///////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (getDetails !== null && getDetails !== undefined && getDetails !== "") {
      let postingData = JSON.parse(JSON.stringify(getDetails));
      postingData.primarySkills = postingData.primarySkills.replace(/\n/g, ',');
      postingData.secondarySkills = postingData.secondarySkills.replace(/\n/g, ',');
      postingData.jobDesc = postingData.jobDesc.replace(/\r\n\r\n,/g, ',');
      postingData.jobDescForSM = postingData.jobDescForSM.replace(/\n,/g, ',');
      setjobDetails(postingData);
      dispatch(_props.jobpostingsbyidStartReducer({}));
      let customFieldsList = JSON.parse(getDetails.customFields);
      if (customFieldsList && customFieldsList.length > 0) {
        let customFieldsArray = [];
        customFieldsList.forEach((list, index) => {
          customFieldsArray.push({ id: index, name: list.key, value: list.value });
        });
        setJobCustomFeilds(customFieldsArray);
      }
      dispatch(_ser.getClientsAsync(postingData.clientId));
    }

  }, [getDetails]);

  useEffect(() => {
    if (isAssociatedProfile === true) {
      const url = window.location.pathname;
      const path = url.substring(url.lastIndexOf("/") + 1);

      GetJobData(path)
    }
  }, [])


  const GetJobData = (id) => {
    dispatch(_props.jobpostingsbyidAsync(id));

  }

  const handleChange = (value) => {
    setTemplateCustomKeys([])
    setMailTemplate(value);
    dispatch(_userser.getEmailTemplatebyIdAsync(JSON.parse(decrypt('GetUserId')), value.id));
  };



  useEffect(() => {
    if (applicantDetails || tempApplicant) {
      let applicantObj = {}, customFieldsList = [];
      if (isAssociatedProfile === false) {
        console.log(applicantDetails);
        applicantObj = {
          // jobCode: "",
          candidateName: `${applicantDetails.Applicants.firstName} ${applicantDetails.Applicants.lastName}`,
          contactNumber: applicantDetails.Applicants.contactNumber ? applicantDetails.Applicants.contactNumber : "",
          applicantCode: applicantDetails.applicantCode ? applicantDetails.applicantCode : "",
          email: applicantDetails.Applicants.emailId ? applicantDetails.Applicants.emailId : "",
          candidateStatus: applicantDetails.Applicants.candidateStatus ? applicantDetails.Applicants.candidateStatus : "",
          candidateStatus: applicantDetails.candidateStatus ? applicantDetails.candidateStatus : "",
          source: applicantDetails.resumesource ? applicantDetails.resumesource : "",
          certification: applicantDetails.certification ? applicantDetails.certification.join(", ") : '',
          educationDetails: transformDataObjToArray(applicantDetails.educationDetails, "education"),
          skillDetails: applicantDetails.skillDetails ? applicantDetails.skillDetails.map(x => x.name).join(", ") : '',
          workExp: transformDataObjToArray(applicantDetails.workExp, "workExp"),
        }
        customFieldsList = JSON.parse(applicantDetails.customFields);
      }
      else {
        let splitCode = jobCode.split('(');
        let splitJobcode = splitCode[1].split(")");
        applicantObj = {
          jobCode: splitJobcode[0],
          email: tempApplicant.emailId ? tempApplicant.emailId : "",
          applicantCode: tempApplicant.applicantCode ? tempApplicant.applicantCode : "",
          candidateName: tempApplicant.fullName ? tempApplicant.fullName : tempApplicant.firstName + "" + tempApplicant.lastName,
          contactNumber: tempApplicant.contactNumber ? tempApplicant.contactNumber : "",
          // candidateStatus: tempApplicant.candidateStatus ? tempApplicant.candidateStatus : "",
          source: tempApplicant.resumesource ? tempApplicant.resumesource : "",
          certification: tempApplicant.certification ? JSON.parse(tempApplicant.certification).join(", ") : '',
          educationDetails: tempApplicant.educationDetails ? transformDataObjToArray(JSON.parse(tempApplicant.educationDetails), "education") : '',
          skillDetails: tempApplicant.skillDetails ? JSON.parse(tempApplicant.skillDetails).map(x => x.name).join(", ") : '',
          workExp: tempApplicant.workExp ? transformDataObjToArray(JSON.parse(tempApplicant.workExp), "workExp") : '',
        }
        if(tempApplicant){
          customFieldsList = JSON.parse(tempApplicant.customFields);
        }
      }
      setProfileDetails(applicantObj)

      if (customFieldsList) {
        let customFieldsArray = [];
        customFieldsList.forEach((list, index) => {
          customFieldsArray.push({ id: index, name: list.key, value: list.value });
        });
        setCustomFields(customFieldsArray);
      }
    }
  }, [applicantDetails, tempApplicant])


  useEffect(() => {
    if (ClientsData !== null && ClientsData.length > 0) {
      let ClientData = JSON.parse(JSON.stringify(ClientsData))
      setGetClients(ClientData[0]);
      dispatch(_ser.getClientsStartReducer(initalState));
    }
    console.log(getClients);
  }, [ClientsData])


  const transformDataObjToArray = (data, type) => {
    if (data.length > 0) {
      if (type === "education") {
        let educationArray = [];
        data.forEach(list => {
          let Degree = list.degree ? list.degree + "," : "";
          let Organization = list.organization ? list.organization + "," : "";
          let Duration = (list.startDate && list.endDate) ? list.startDate + " - " + list.endDate : list.startDate ? list.startDate : list.endDate ? list.endDate : '';
          let value = Degree + Organization + Duration;
          educationArray.push(`<p>${value}</p>`);
        })
        return educationArray.join(" ");
      }
      else if (type === "workExp") {
        let workExpArray = [];
        data.forEach(list => {
          let JobTitle = list.jobTitle ? list.jobTitle + "," : "";
          let Organization = list.organization ? list.organization + "," : "";
          let Duration = (list.startDate && list.endDate) ? list.startDate + " - " + list.endDate : list.startDate ? list.startDate : list.endDate ? list.endDate : '';
          let value = JobTitle + Organization + Duration;
          workExpArray.push(`<p>${value}</p>`);
        })
        return workExpArray.join(" ");
      }
      else {
        return ""
      }
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (isAssociatedProfile && jobCode) {
      let splitCode = jobCode.split('(');
      let splitJobcode = splitCode[1].split(")");
      setProfileDetails({ jobCode: splitJobcode[0], candidateName: profileDetails.candidateName, email: profileDetails.email });
    }
    let userId = JSON.parse(decrypt('GetUserId'))
    dispatch(_userser.customFieldsAsync(userId));

  }, [isAssociatedProfile, jobCode])

  useEffect(() => {
    dispatch(_userser.getEmailTemplatesAsync(JSON.parse(decrypt('GetUserId'))));
    let obj = { current: 1, limit: 10000, offset: 1 };
    dispatch(_postingser.listjobpostingsAsync(obj, "list"));
  }, [])

  useEffect(() => {
    if (listofTemplates && listofTemplates.length > 0) {
      let templatesData = JSON.parse(JSON.stringify(listofTemplates));
      let templatesArray = [];
      templatesData.forEach(list => {
        templatesArray.push({ id: list.id, name: list.name, value: '' });
      })
      setMail(templatesArray);

    } else {
      setMail(null)
    }
  }, [listofTemplates])

  useEffect(() => {
    if (selectedTemplateResponse && selectedTemplateResponse.length > 0) {
      let selectedTemplateData = JSON.parse(JSON.stringify(selectedTemplateResponse));
      setSelectedTemplate(selectedTemplateData);
      dispatch(_userser.getEmailTemplatebyIdStartReducer({}));
      let adminCustomList = JSON.parse(selectedTemplateData[0].customValues);
      if (adminCustomList && adminCustomList.length > 0) {
        let array = [];
        adminCustomList.map((list, i) => {
          let selectedTempBody = selectedTemplateData[0].emailBody;
          let isAdminList = selectedTempBody.includes(list);
          if (isAdminList) {
            array.push({ id: i + 1, name: list, value: "" })
          }
        })
        setTemplateCustomKeys(array)
        console.log(array);
      }
    }
  }, [selectedTemplateResponse])


  useEffect(() => {
    if (responseData && responseData?.rows?.length > 0) {
      setJobpostings([]);
      let myData = JSON.parse(JSON.stringify(responseData));
      setJobpostings(myData.rows);
      dispatch(_postingser.listjobpostingsStartReducer({}));
    }
    else if (responseData || responseData?.rows) {
      setJobpostings(null);
    }
  }, [responseData])

  const onActionTemplate = (type) => {
    if (type === "generate") {
        if (profileDetails.jobCode && profileDetails.jobCode !== "" &&  profileDetails.email !== "" && profileDetails.candidateName !== "") {
          if(templateCustomKeys.length === 0){
            setIsGenerate(false);
            updateEmailbody();
          }else if(templateCustomKeys.length > 0){
            if(templateCustomValues.length>0){
              let customValuesValidate = templateCustomValues.filter(list => list.value !== "")
              if(templateCustomKeys.length == customValuesValidate.length ){
                setIsGenerate(false);
                updateEmailbody();
              }else{
                dispatch(updateSnackbar({ type: "error", message: "All custom fields are required" }));
              }
            }else{
              dispatch(updateSnackbar({ type: "error", message: "All custom fields are required" }));
            }
          }
        } else if (profileDetails.email === "" ) {
          dispatch(updateSnackbar({ type: "error", message: "Please enter Email" }));
        }else if (profileDetails.candidateName === "" ) {
          dispatch(updateSnackbar({ type: "error", message: "Please enter Candidate name" }));
        } else  {
          dispatch(updateSnackbar({ type: "error", message: "Select JobCode" }));
        }
    } else if (type === "copy") {
      const clientId = getClients.id ? getClients.id : "0"
      let customerId = JSON.parse(decrypt('GetUserId'));
      if(applicantCodes && applicantCodes.length > 1) {
        let Array = [];
        let obj = {};
        if(templateCustomValues.length>0){

          templateCustomValues.forEach((item)=>{
            obj[item.name] = item.value
          })
          Array.push(obj);
        }
        let getUserDetails = JSON.parse(localStorage.getItem("getAttributes"));
       let data ={
        sender_mail :getUserDetails[0].email,  /// "tulasiram@techdoquest.com"
        jobId: jobDetails.id,
        
        applicantIds :(process.env.REACT_APP_ENVIRONMENT_MODE === "DEV") ? ["A00004","A00005","A00006","A00007","A00008","A00009","A00010"] : applicantCodes,
        // applicantIds: applicantCodes,             ///  TODO
        templateId: mailTemplate.id,
        clientId : clientId,
        usercustomfields :  Array,
        // jcustom : [test1],
        // acustom : [test11,test22]
        customerId:customerId,
        userId:getUserDetails[0].userId,
        firstName:getUserDetails[0].firstName,
        lastName:getUserDetails[0].lastName,
       }
       dispatch(_userser.BulkEmailAsync(data));
       setIsGenerate(true);
        onReceiveProps(false, "close");
      } else {
        setIsGenerate(true);
        onReceiveProps(false, "close");
        dispatch(updateSnackbar(initialSnackbar));
        let getUserDetails = JSON.parse(localStorage.getItem("getAttributes"));
        let emailId = isAssociatedProfile ? tempApplicant.emailId : applicantDetails.Applicants.emailId;
        let data = {
          sender_mail: getUserDetails[0].email,
          // receiver_mail :emailId,              //////TODO
          receiver_mail: (process.env.REACT_APP_ENVIRONMENT_MODE === "DEV") ? "hirewingtestemail@techdoquest.com" : emailId,
          subject: selectedTemplate[0].subject,
          mail_body: selectedTemplate[0].emailBody,
          userId:getUserDetails[0].userId,
          firstName:getUserDetails[0].firstName,
          lastName:getUserDetails[0].lastName,
          JobId:jobDetails.id,
          applicantIds:  applicantDetails.applicantCode ? applicantDetails.applicantCode :  tempApplicant.applicantCode  ,
          aId: applicantDetails.id ? applicantDetails.id :  ""
        }
        dispatch(_userser.SendEmailAsync(data));
        // dispatch(updateSnackbar({ type: "success", message: "Email template copied to clipboard" }));
      }
    }
  }
  const onCustomfieldHandler = (value, key) => {
    let modifyObject = [...templateCustomKeys];
    modifyObject.find(x => x.name === key.name).value = value;
    setTemplateCustomValues(modifyObject);
    console.log(modifyObject);
  }

  const updateEmailbody = () => {
    let modifyTemple = JSON.parse(JSON.stringify(selectedTemplate));
    let subject = modifyTemple[0].subject;
    let templeBody = modifyTemple[0].emailBody;
    var jobCode = /@{JobOpenings.jobCode}@/gi;
    var jobTitle = /@{JobOpenings.jobTitle}@/gi;
    var jobStatus = /@{JobOpenings.jobStatus}@/gi;
    var jobType = /@{JobOpenings.jobType}@/gi;
    var location = /@{JobOpenings.location}@/gi;
    var payBillRate = /@{JobOpenings.payBillRate}@/gi;
    var primarySkills = /@{JobOpenings.primarySkills}@/gi;
    var workModel = /@{JobOpenings.workModel}@/gi;
    var jobDescForSM = /@{JobOpenings.jobDescForSM}@/gi;
    var jobDesc = /@{JobOpenings.jobDesc}@/gi;
    var secondarySkills = /@{JobOpenings.secondarySkills}@/gi;
    var priority = /@{JobOpenings.priority}@/gi;
    var applicant = /@{Applicants.fullName}@/gi;
    var applicantCode = /@{Applicants.applicantCode}@/gi;
    var contactNumber = /@{Applicants.contactNumber}@/gi;
    var educationDetails = /@{Applicants.educationDetails}@/gi;
    var emailId = /@{Applicants.emailId}@/gi;
    var skillDetails = /@{Applicants.skillDetails}@/gi;
    var certification = /@{Applicants.certification}@/gi;
    var workExp = /@{Applicants.workExp}@/gi;
    var candidateStatus = /@{Applicants.candidateStatus}@/gi;
    var source = /@{Applicants.source}@/gi;
    var clientName = /@{Clients.clientName}@/gi;
    var region = /@{Clients.region}@/gi;
    var status = /@{Clients.status}@/gi;
    var managerEmail = /@{Clients.managerEmail}@/gi;

    let ApplicantKeys = [], JobKeys = [], finalCustomKeys = [],
        appendJobCustomFeilds = [], appendApplicantCustomFeilds = [], appendTemplateCustomValues = [], mappingFields = [];
    if (customJobKeys.length > 0) {
      customJobKeys.map((list, index) => {
        JobKeys.push(`@{JobOpenings.${list.name}}@`);
        appendJobCustomFeilds.push({ id: index, name: list.name, value: '' })
      });
      if(!jobCustomFeilds || jobCustomFeilds.length === 0){
        mappingFields = jobCustomFeilds ? jobCustomFeilds : appendJobCustomFeilds;
      }
      if (selectedTemplate !== "" && selectedTemplate) {
        JobKeys.forEach((list) => {
          if (templeBody.includes(list)) {

            let filterObj = mappingFields.filter(x => `@{JobOpenings.${x.name}}@` === list);
            if (filterObj.length > 0) {
              templeBody.replace(list, filterObj[0].value);
            } 
            else {
              templeBody.replace(new RegExp(list, "g"), "");
            }
          }
        })
      }
    }
    if (customApplicantKeys) {
      customApplicantKeys.map((list, index) => {
        ApplicantKeys.push(`@{Applicants.${list.name}}@`);
        appendApplicantCustomFeilds.push({ id: index, name: list.name, value: '' })
      })
      if(!customFields || customFields.length === 0){
        mappingFields = customFields ? customFields : appendApplicantCustomFeilds;
      }
      if (selectedTemplate !== "" && selectedTemplate) {
        ApplicantKeys.forEach((list) => {
          if (templeBody.includes(list)) {
            let filterObj = mappingFields.filter(x => `@{Applicants.${x.name}}@` === list);
            if (filterObj.length > 0) {
              templeBody.replace(list, filterObj[0].value ? filterObj[0].value : "");
            } else {
              templeBody.replace(new RegExp(list, "g"), "");
            }
          }
        })
      }
    }

    if (templateCustomKeys) {
      templateCustomKeys.map((list, index) => {
        finalCustomKeys.push(`@{Custom.${list.name}}@`);
        appendTemplateCustomValues.push({ id: index, name: list.name, value: '' })
      })
      if(!templateCustomValues || templateCustomValues.length === 0){
        mappingFields = templateCustomKeys ? templateCustomKeys : appendTemplateCustomValues;
      }
      if (selectedTemplate !== "" && selectedTemplate) {
        finalCustomKeys.forEach((list) => {
          if (templeBody.includes(list)) {
            let filterObj = mappingFields.filter(x => `@{Custom.${x.name}}@` === list);
            if (filterObj.length > 0) {
              templeBody.replace((list,"g"), filterObj[0].value ? filterObj[0].value :"");
            } else {
              templeBody.replace(new RegExp(list, "g"), "");
            }
          }
        })
      }
    }

    templeBody = templeBody.replace(applicant, profileDetails.candidateName).replace(contactNumber, profileDetails.contactNumber).replace(jobCode, jobDetails.jobCode).replace(jobTitle, jobDetails.jobTitle)
      .replace(educationDetails, profileDetails.educationDetails)
      .replace(applicantCode, profileDetails.applicantCode)
      .replace(emailId, profileDetails.email)
      .replace(skillDetails, profileDetails.skillDetails)
      .replace(certification, profileDetails.certification)
      .replace(workExp, profileDetails.workExp)
      .replace(source, profileDetails.source)
      .replace(candidateStatus, profileDetails.candidateStatus)
      .replace(jobStatus, jobDetails.jobStatus).replace(jobType, jobDetails.jobType)
      .replace(location, jobDetails.location).replace(payBillRate, jobDetails.payBillRate)
      .replace(primarySkills, jobDetails.primarySkills).replace(workModel, jobDetails.workModel)
      .replace(jobDescForSM, jobDetails.jobDescForSM).replace(jobDesc, jobDetails.jobDesc)
      .replace(secondarySkills, jobDetails.secondarySkills).replace(priority, jobDetails.priority)
      .replace(clientName, getClients.clientName ? getClients.clientName : "").replace(region, getClients.region ? getClients.region : "")
      .replace(status, getClients.status ? getClients.status === "1" ? "Active" : "Inactive" : "").replace(managerEmail, getClients.managerEmail ? getClients.managerEmail : "");
    if (customFields.length > 0 ) { //customFields
      customFields.forEach(list => {
        templeBody = templeBody.replace(`@{Applicants.${list.name}}@`, list.value ? list.value : "" );
      })
    }
     if(customApplicantKeys){
      customApplicantKeys.forEach(list => {
        templeBody = templeBody.replace(`@{Applicants.${list.name}}@`, list.value ? list.value : "" );
      })
    }


    if (jobCustomFeilds.length > 0) {   ///jobCustomFeilds
      jobCustomFeilds.forEach(list => {
        templeBody = templeBody.replace(`@{JobOpenings.${list.name}}@`, list.value ? list.value : "" );
      })
    }
     if(customJobKeys.length > 0){
        customJobKeys.forEach(list => {
        templeBody = templeBody.replace(`@{JobOpenings.${list.name}}@`, list.value ? list.value : "" );
      })
    }


    if (templateCustomKeys.length > 0) {
      templateCustomKeys.forEach(list => {
        templeBody = templeBody.replace(new RegExp(`@{Custom.${list.name}}@`,"g"), list.value ? list.value :"");
      })
    }
    if(templateCustomValues.length > 0){
      templateCustomValues.forEach(list => {
             templeBody = templeBody.replace(new RegExp( `@{Custom.${list.name}}@`,"g"), list.value ? list.value : "" );
           })}


    subject = subject.replace(jobTitle, jobDetails.jobTitle).replace(location, jobDetails.location);
    modifyTemple[0].emailBody = templeBody;
    modifyTemple[0].subject = subject;
    setSelectedTemplate(modifyTemple);
  }

  const onChangeHandler = (value, type) => {
    let modifyObject = JSON.parse(JSON.stringify(profileDetails));
    modifyObject[type] = value;
    if (!Boolean(isAssociatedProfile)) {
      let myJobData = jobpostings.filter(x => x.jobCode === value);
      if(myJobData.length>0){
        GetJobData(myJobData[0].id);
      }
      console.log(value);
    }
    setProfileDetails(modifyObject);
  }



  return (
    <>
      {Boolean(isGenerate) ?
        <>{mail && mail.length > 0 ?
          <div id="mailtemplatebody">
            <div style={{ width: "100%" }}>
              <FormControl fullWidth autoComplete="off">
                <InputLabel className="boldSelectlabel">Template</InputLabel>
                <Select
                  value={mailTemplate}
                  name="Template"
                  autoComplete="off"
                  label="Template*&nbsp;&nbsp;&nbsp;"
                  onChange={(e) => handleChange(e.target.value, "Template")}
                  style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                >
                  {mail && mail.map((list) => (
                    <MenuItem className="selectOptions" key={list.id} value={list}>
                      {list.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {mailTemplate !== null && mailTemplate !== undefined ? (
              <>
                <div style={{ minHeight: "calc(100vh - 400px)" }}>


                  <ul className="emailkeys" >
                    <li>Job Code :
                      {Boolean(isAssociatedProfile) ?
                        <TextField id="outlined-basic" disabled size="small"
                          variant="outlined" name="jobCode" value={profileDetails.jobCode}
                        />
                        : <div className="boldlabel jobcode_search" style={{ display: "flex", width: "60%" }}>
                          <Autocomplete
                            options={jobpostings}
                            autoHighlight
                            id="controlled-demo"
                            autoComplete="off"
                            clearOnEscape
                            value={selectJobCode}
                            getOptionLabel={(option) => option?.jobCode ? option?.jobCode : ''}
                            blurOnSelect={true}
                            onChange={(e, value) => {
                              setSelectJobCode(value);
                              onChangeHandler(value?.jobCode, 'jobCode');
                            }}
                            sx={{ width: 503 }}
                            renderInput={(params) => (
                              <TextField
                                {...params} size="small"
                                className="boldlabel"
                                autoComplete="off"
                                value={profileDetails.jobCode}
                                name="jobCode"
                              />
                            )}
                          />
                          <BsSearch style={{ position: "absolute", right: "11px", top: "11px" }} />
                        </div>}
                    </li>
                    <li>Cadidate Name :
                      <TextField id="outlined-basic" variant="outlined"
                        disabled={Boolean(isAssociatedProfile) ? false : true} size="small"
                        value={profileDetails.candidateName} name="candidateName"
                        onChange={(e) => { onChangeHandler(e.target.value, "candidateName") }}
                      />
                    </li>
                    <li>Email :
                      <TextField id="outlined-basic" variant="outlined"
                        disabled={Boolean(isAssociatedProfile) ? false : true} size="small"
                        value={profileDetails.email} name="email" onChange={(e) => { onChangeHandler(e.target.value, "email") }}
                      />
                    </li>
                  </ul>
                  {templateCustomKeys && templateCustomKeys.length > 0 && (
                    <p className="customheading">Custom Fields</p>
                  )}
                  <ul className="emailkeys" style={{ maxHeight: "250px", overflow: "auto" }}>
                    {templateCustomKeys && templateCustomKeys.map((list) => (
                       <tr >
                        {/* <td></td> */}
                      <li><span style={{wordBreak:"break-all",width:"120px"}}>{list.name}</span>
                      <td width="30px" align="center">:</td>
                      <td>
                        <TextField id="outlined-basic" size="small"
                          variant="outlined"
                          name={list.name}
                          value={list.value}
                          onChange={(e) => { onCustomfieldHandler(e.target.value, list) }}
                        />
                        </td>
                      </li>
                      </tr>
                    ))}
                  </ul>
                </div>

                <Button variant="contained" component="label"size="medium"
                  className="generatetemplate"
                  onClick={(e) => { onActionTemplate("generate") }}
                >
                  Generate Template
                </Button>
              </>
            ) : ""}
          </div> : <h1 style={{ fontWeight: 300, lineHeight: 1.2, textAlign: "center", margin: "60px 0", fontSize: "3rem" }}>No email templates found</h1>}
        </> :
        <>
          <div style={{ textAlign: "left", maxHeight: "calc(100vh - 331px)", overflow: "auto" }} id="copyTemplate">
            <table style={{ marginBottom: "20px" }}>
              <tr height="30px">
                <td>Job Code</td>
                <td width="30px" align="center">:</td>
                <td ><strong>{profileDetails.jobCode}</strong></td>
              </tr>
              <tr height="30px">
                <td>Cadidate Name</td>
                <td align="center">:</td>
                <td><strong>{profileDetails.candidateName}</strong></td>
              </tr>
              <tr height="30px">
                <td>Email</td>
                <td align="center">:</td>
                <td><strong>{profileDetails.email}  </strong></td>
              </tr>
            </table>
            <b>{selectedTemplate[0].subject}</b>
            {isGenerate === false && (
              <div dangerouslySetInnerHTML={{ __html: selectedTemplate[0].emailBody }}></div>
            )}
            {/* <table>
              {templateCustomDetails && templateCustomDetails.map(list => (
                <tr>
                  <td>{list.name}</td>
                  <td width="30px">:</td>
                  <td>{list.value}</td>
                </tr>
              ))}
            </table> */}
          </div>
          <Button variant="contained" component="label"
            className="copytemplate"
            onClick={(e) => { onActionTemplate("copy") }}
          >
            Send Mail
          </Button>
        </>
      }
    </>
  )
}

export default GenerateTemplate;