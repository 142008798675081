import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { platform } from '../../ringcentralConfig';
import { Box, Typography, CircularProgress } from '@mui/material';
import { fetchUserDetails } from '../../_utilities/ringCentralUtils';

function RingCentralRedirectionComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    platform().on(platform().events.loginSuccess, async () => {
      // Retrieve the previous route from localStorage
      const previousRoute = localStorage.getItem('previousRoute') || '/'; // Fallback to root

      // Clear the stored route after using it
      localStorage.removeItem('previousRoute');
      await fetchUserDetails();
      // Redirect dynamically to the previous route
      navigate(previousRoute);
    });

    platform().on(platform().events.logoutSuccess, (res) => {
      console.log('logout', res);
    });

    // Handle OAuth redirect
    if (window.location.href.includes('code=')) {
      platform().login({
        code: new URLSearchParams(window.location.search).get('code'),
      });
    }
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        textAlign: 'center',
        padding: 2,
      }}
    >
      <CircularProgress color="primary" size={60} />
      <Typography variant="h5" sx={{ mt: 3, mb: 1, color: '#333' }}>
        Generating token, please wait...
      </Typography>
      <Typography variant="body1" sx={{ color: '#666' }}>
        You will be automatically redirected to your previous page.
      </Typography>
    </Box>
  );
}

export default RingCentralRedirectionComponent;
