import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as _ser from '../../../_redux/_services/ApplicantsSlice';
import { updateSnackbar } from "../../../_redux/SnackbarSlice";
import { setBreadCrumb } from '../../../_utilities/breadCrumb';
import GetJobId from '../../../_utilities/GetJobId';
import GenerateTemplate from '../../../components/GenerateTemplate/GenerateTemplate';
import RightSideBar from '../../../components/Rightsidebar/RightSideBar';
import ApplicantsComponent from '../../Applicants/ApplicantsComponent';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import './ApplicantsView.css';
import CkEditor from "../../../components/CkEditor/CkEditor";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { checkRingCentralApp, formatPhoneNumber, isLoggedIntoRingCentral, redirectToLogin, triggerRingOut } from '../../../_utilities/ringCentralUtils';
import { decrypt } from '../../../_utilities/_encryptDecryptHelper';
import { integrationsMapping } from '../../../ringcentralConfig';
import { getUserDetails } from '../../../_redux/CognitoSlice';
import SendSms from '../../../components/SendSms/SendSms';
import { updateLoader } from "../../../_redux/LoaderSlice";
import { GetTimeZone } from "../../../_utilities/GetTimeZone";


const initalState = { data: null, message: '', isloader: true };


const ApplicantsView = (props) => {
  const [getDetails, setGetDetails] = useState(initialDetails);
  const [isGenerateTemplate, setIsGenerateTemplate] = useState(false);
  const [isCallTemplate, setIsCallTemplate] = useState(false);
  const [tabvalue, setTabValue] = useState("1");
  const [actionMenus, setActionMenus] = useState([]);
  const[layoutObj,setLayoutObj]=useState(CallOBJ);

  let getJobCode = props.getData ? null : GetJobId();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actionBtns = [{
    // variant: "contained", label: "Edit", type: "back", /// To DO
    style: { backgroundColor: "#ebc700 ", color: "black", borderRadius: "25px", width: "6rem", textTransform: "capitalize" },
    class: 'buttonHover1',
    navigateTo: `/ats/layout/applicants/edit/${getJobCode}`
  }]

  useEffect(() => {
    // Check integrations and set dynamic action menus
    const userDetails = JSON.parse(decrypt('GetUserInfo'));
    const integrations = userDetails?.[0]?.integrations ? JSON.parse(userDetails[0].integrations) : [];


    const availableMenus = [
      { id: 0, name: 'Edit', navigate: true, url: `/ats/layout/applicants/edit/${getJobCode}` },
      { id: 1, name: 'Send Mail', navigate: false, isTemplate: true },
    ];

    // Add Call and Send SMS option dynamically based on integrations
    integrations.forEach((integration) => {
      const integrationConfig = integrationsMapping[integration.attribute_5];
      if (integrationConfig && integration[integrationConfig.active] === "Yes" && integration[integrationConfig.features].includes("Calls")) {
        availableMenus.push({ id: 2, name: 'Call or Send SMS', navigate: false });
      }
      // if (integrationConfig && integration[integrationConfig.active] === "Yes" && integration[integrationConfig.features].includes("Messages")) {
      //   availableMenus.push({ id: 3, name: 'Send SMS', navigate: false });
      // }
    });

    setActionMenus(availableMenus);
  }, [getJobCode]);

  useEffect(() => {
    if (getJobCode !== null) {
      GetData();
    }
  }, [getJobCode])

  useEffect(() => {
    if (props.getData) {
      dispatch(_ser.applicantsByIdAsync(props.getData.applicantID)); //TODO
    }
  }, [props.getData])

  useEffect(() => {
  }, [getDetails])

  const onGetValidData = (data) => {
    console.log(data);
  }

  const ApplicantData = useSelector(state => state.ApplicantsById.data);

  const GetData = async () => {
    dispatch(_ser.applicantsByIdAsync(getJobCode));
  };

  useEffect(() => {
    if (ApplicantData && ApplicantData.length > 0) {
      let initobj = JSON.parse(JSON.stringify(initialDetails));
      let myData = JSON.parse(JSON.stringify(ApplicantData));
      let name = myData[0].firstName + "" + myData[0].lastName;
      initobj.Applicants.firstName = myData[0].firstName;
      initobj.Applicants.lastName = myData[0].lastName;
      initobj.Applicants.contactNumber = myData[0].contactNumber;
      initobj.Applicants.AlternateContactNumber = myData[0].AlternateContactNumber;
      initobj.Applicants.emailId = myData[0].emailId;
      initobj.Applicants.resumesourceId = myData[0].resumesourceId;
      initobj.Applicants.resumesource = myData[0].resumesource;
      initobj.candidateStatus = myData[0].candidateStatus;
      initobj.customFields = myData[0].customFields;
      initobj.source = myData[0].source;
      initobj.resumesource = myData[0].resumesource;
      initobj.applicantCode = myData[0].applicantCode;
      // initobj.csAssociatedJobs = JSON.parse(myData[0].csAssociatedJobs);   /// Removed in Backend ////
      // initobj.eAssociatedJobs = JSON.parse(myData[0].eAssociatedJobs);     /// Removed in Backend ////
      // initobj.vsAssociatedJobs = JSON.parse(myData[0].vsAssociatedJobs);   /// Removed in Backend ////
      let educationObj = JSON.parse(myData[0].educationDetails);
      if (educationObj !== null && educationObj.length > 0) {
        educationObj.forEach((item, i) => {
          item["id"] = i;
          item["isEdit"] = false;
          item['startDate'] = item?.startDate && item.startDate ? new Date(item?.startDate).toLocaleDateString() : '';
          item['endDate'] = item?.endDate && item.endDate ? new Date(item?.endDate).toLocaleDateString() : '';
          if (item?.education) { item['degree'] = item.education; }
        })
      }
      initobj.educationDetails = educationObj && educationObj.length > 0 ? educationObj : [];
      let workExpObj = JSON.parse(myData[0].workExp);
      if (workExpObj !== null && workExpObj.length > 0) {
        workExpObj.forEach((item, i) => {
          item["id"] = i;
          item["isEdit"] = false;
          item['locationFormated'] = item?.location?.formatted;
          item['startDate'] = item?.startDate && item.startDate ? new Date(item?.startDate).toLocaleDateString() : '';
          item['endDate'] = item?.endDate && item.endDate ? new Date(item?.endDate).toLocaleDateString() : '';
        })
      }
      initobj.workExp = workExpObj && workExpObj.length > 0 ? workExpObj : [];
      initobj.skillDetails = myData[0].skillDetails ? JSON.parse(myData[0].skillDetails) : [];
      initobj.certification = myData[0].certification && myData[0].certification !== "undefined" ? JSON.parse(myData[0].certification) : [];
      initobj.id = myData[0].id;
      setGetDetails(initobj)
      dispatch(_ser.applicantsByIdStartReducer(initalState))
      if( myData[0].firstName){
        setBreadCrumb(myData[0].id, myData[0]?.fullName ? myData[0]?.fullName : name, "Applicant Code")
      }
    }
  }, [ApplicantData])

  const onSelectActionHandler = (value) => {
    if (actionMenus[value].name === "Edit") {
      navigate(actionMenus[value].url);
    } else if ((actionMenus[value].name === 'Call or Send SMS' && getDetails.Applicants.contactNumber && getDetails.Applicants.contactNumber !== "")) {
      setIsCallTemplate(true);
    } else {
      if ((getDetails.Applicants.emailId !== "" && getDetails.Applicants.emailId) || (getDetails.emailId !== "" && getDetails.emailId)) {
        setIsGenerateTemplate(true)
      } else {
        dispatch(updateSnackbar({ type: "error", message: "EmailId Doesn't Exists" }));
      }

    }
  }

  const onReceivePropsHandler = (pannel, load) => {
    if (load === "close") {
      setIsGenerateTemplate(pannel);
      setIsCallTemplate(pannel)
    } else if (load === "1" || load === "2") {
      setTabValue(load);
    }else if(load === "isImageFalse"){
      setLayoutObj(CallConnectedOBJ)
    }
  };
 

  
  return (
    <>
      <div className="action-btn-dropdown">
        <FormControl style={{ textDecorationColor: "black", width: "32%" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="actionDropdown"
            value="Action"
            style={{ paddingLeft: "20px", width: "180px" }}
            onChange={(e) => onSelectActionHandler(e.target.value)}
          >
            {actionMenus.map(list => (
              <MenuItem
                style={{
                  display: "grid",
                  justifyContent: "left",
                  padding: "6px 30px",
                  cursor: "pointer",
                  width: "180px",
                }}
                key={list.id}
                value={list.id}
              >
                {list.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ padding: "0 10px" }} id="applicantsView">
        {/* {getDetails.Applicants.firstName !== '' ?
          <ApplicantsComponent componentType="view" input={getDetails} iniValue={initialDetails}
            actions={actionBtns} onValidData={onGetValidData} />
          : <div className="nodatasection">
            <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noData" />
            {"No data found"}
          </div>
          } */}
           <ApplicantsComponent componentType="view" input={getDetails} iniValue={initialDetails}
            actions={actionBtns} onValidData={onGetValidData} />
      </div>
      {Boolean(isGenerateTemplate) && (
        <RightSideBar
          componentData={<GenerateTemplate isAssociatedProfile={false} applicantDetails={getDetails} jobCode={null} onReceiveProps={onReceivePropsHandler} />}
          componentLayout={MailOBJ}
          onReceiveProps={onReceivePropsHandler}
        />
      )}

      {Boolean(isCallTemplate) && (
        <RightSideBar
          componentData={<ComponentRenderData applicantDetails={getDetails} onReceiveProps={onReceivePropsHandler} />}
          componentLayout={tabvalue === "1" ? layoutObj : smsOBJ}
          onReceiveProps={onReceivePropsHandler}
        />
      )}
    </>
  )
}

export default ApplicantsView;

const initialDetails = {
  Applicants: { firstName: '', lastName: '', contactNumber: '', AlternateContactNumber: '', emailId: '' },
  educationDetails: [{ id: '', education: '', startDate: null, endDate: null, location: '' }],
  workExp: [{ id: '', organization: '', jobTitle: '', industry: '', startDate: null, endDate: null, isCurrent: '', location: '', jobDescription: '' }],
  skillDetails: [],
  certification: [],
  totalExperience: null,
  customFields: '',
  id: 0,
  userId: '',
  firstName: '',
  lastName: '',
  identifier: '',
  source: 1,
  resumesourceId: '',
  candidateStatus: '',
  csAssociatedJobs: "",
  eAssociatedJobs: "",
  vsAssociatedJobs: "",
}

const ComponentRenderData = ({ applicantDetails, onReceiveProps }) => {
  const [activeTab, setActiveTab] = useState("1"); // Tab state: "1" for Call, "2" for SMS
  const [callState, setCallState] = useState("idle"); // "idle", "calling", "connected"
  const [errorMessage, setErrorMessage] = useState("");
  const isLoggedIn = isLoggedIntoRingCentral();
  const [callNotes, setCallNotes] = useState("");
  const [connectedTime, setConnectedTime] = useState("");
  const dispatch = useDispatch();
  const jobId = GetJobId(); // Get job ID from the utility function

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    onReceiveProps(true, newValue);
  };

  const handleCallInitiate = async () => {
    dispatch(updateLoader(true));

    try {
      setErrorMessage(""); // Clear any existing errors
      const formattedNumber = formatPhoneNumber(applicantDetails.Applicants.contactNumber);

      // Check if the RingCentral app is installed
      const appExists = await checkRingCentralApp(formattedNumber);
      console.log("App exists:", appExists);

      if (appExists) {
        dispatch(updateLoader(false));

        // If the app exists, show the message after the user interacts with the app
        setCallState("connected");
        setErrorMessage("We found the RingCentral app. Initiating the call through the app.");
        return;
      }

      // If the app is not found, check if the user is logged in
      if (!isLoggedIn) {
        dispatch(updateLoader(false));
        setErrorMessage("Please login to RingCentral to make a call.");
        return;
      }

      // Proceed with RingOut if logged in but the app is not found
      setCallState("calling");
      setErrorMessage("We don't see the RingCentral app installed. Making the call through RingOut...");
      // Fetch the stored main number and extension
      const companyMainNumber = localStorage.getItem("ringCentralMainNumber");
      const userExtension = localStorage.getItem("ringCentralUserExtension");

      if (!companyMainNumber || !userExtension) {
        dispatch(updateLoader(false));
        setErrorMessage("Unable to retrieve RingCentral user details. Please try logging in again.");
        return;
      }

      const fromNumber = `${companyMainNumber}*${userExtension}`;
      const ringOutResponse = await triggerRingOut(formattedNumber, fromNumber); // Replace with your verified number
      console.log("RingOut response:", ringOutResponse);

      dispatch(updateLoader(false));
      if (ringOutResponse && ringOutResponse.status.callStatus === "InProgress") {

        setCallState("connected");
        setErrorMessage("We have successfully made the call via RingOut.");
        onReceiveProps(false,"isImageFalse")
      } else {
        throw new Error("Failed to initiate the call through RingOut.");
      }
    } catch (error) {
      console.error("Call initiation failed:", error);
      setCallState("idle");
      setErrorMessage("An error occurred while trying to make the call. Please try again.");
    }
  let date = new Date()
  setConnectedTime(date)

  };

  const handleLogin = () => {
    redirectToLogin();
  };

  const handleSaveNotes = async (event) => {
    event.preventDefault();

    if (!callNotes || callNotes.trim().length === 0) {
      dispatch(updateSnackbar({ type: "error", message: "Please add notes before saving." }));
      return;
    }

    try {
      const userInfo = await getUserDetails();

      const notePayload = {
        notes: callNotes,
        noteType: "Call",
        moduleType: "applicants",
        moduleId: jobId,
        userId: userInfo.userId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      };

      await dispatch(_ser.addNotesAsync(notePayload)); // Dispatch add notes action
      dispatch(updateSnackbar({ type: "success", message: "Call notes saved successfully!" }));
      setCallNotes(""); // Clear notes input
      setCallState("idle"); // Reset state
      onReceiveProps(false,"close");
      setErrorMessage("");

    } catch (error) {
      console.error("Error saving call notes:", error);
      dispatch(updateSnackbar({ type: "error", message: "Failed to save call notes. Please try again." }));
    }
  };

  const handleCancelCall = () => {
    setCallState("idle");
    setErrorMessage("");
  };

  const onReceivePropsHandler = (pannel, load) => {
    if (load === "close") {
      onReceiveProps(false,"close");
    } 
  };


  return (
    <div className="tabs">
      <Box sx={{ width: "100%", typography: "body1", color: "#3B4046", marginTop: "-35px" }}>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="Call or SMS Tabs" className="tabsvalue">
              <Tab label="Call" value="1" />
              <Tab label="Send SMS" value="2" />
            </TabList>
          </Box>

          {/* Call Tab */}
          <TabPanel value="1">
            <div className="applicant-details">
              <table>
                <tbody>
                  <tr>
                    <td>Applicant Name</td>
                    <td>:</td>
                    <td>
                      {applicantDetails.Applicants.firstName} {applicantDetails.Applicants.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td>Contact Number</td>
                    <td>:</td>
                    <td>{applicantDetails.Applicants.contactNumber}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Error or Login Prompt */}
            {errorMessage && (
              <div>
                <p style={{ color: callState === "connected" ? "green" : "red" }} className='text-left '>{errorMessage}</p>
                {!isLoggedIn && callState === "idle" && (
                  <Button variant="contained"
                    style={{
                      backgroundColor: '#FFD800',
                      borderRadius: '10px',
                      color: '#3B4046'
                    }}
                    onClick={handleLogin}>
                    Login to RingCentral
                  </Button>
                )}
              </div>
            )}

            {/* Call Button */}
            {!errorMessage && callState === "idle" && (
              <div className="call-button" >
                <img
                  src={process.env.PUBLIC_URL + "/assests/img/Notescall.svg"}
                  alt="Call Icon"
                  onClick={handleCallInitiate}
                  className="call-icon"
                />
                <p>Call Applicant</p>
              </div>
            )}

            {/* Calling State */}
            {callState === "calling" && (
              <div className="content-center">
                <div className="pulse">
                  <img
                    src={process.env.PUBLIC_URL + "/assests/img/Notescall.svg"}
                    alt="Calling Icon"
                    className="calling-icon"
                  />
                </div>
                <p className='callingtext'>Calling Applicant...</p>
              </div>
            )}

            {/* Connected State */}
            {callState === "connected" && (
              <div>
                <div className="call-connected">
                <img src={process.env.PUBLIC_URL + "/assests/img/phone.svg"} alt="" style={{height:"23px",width:"23px"}} className='phone-icon'/>
                  <p className='sucesses-text'>Successfully Connected With Applicant</p>
                </div>
                <p className='text-left '>Add Notes here</p>
                <CkEditor
                  data={callNotes}
                  name="callNotes"
                  autoComplete="off"
                  onUpdatehandler={(update) => setCallNotes(update.data)}
                />
                 <div className="call-initiated-text">
                  <p className='sucesses-text'>Call Initiated with  {applicantDetails.Applicants.firstName} {GetTimeZone(connectedTime)}</p>
                </div>
                <div className="cancelsavebutton" style={{ marginTop: "20px" }}>
                  <Stack spacing={2} direction="row" >
                    <Button
                      variant="text"
                      sx={{ color: "#FF0000" }}
                      onClick={handleCancelCall}
                      size='small'
                    > 
                    <Link href="#" className='cancel-btn' style={{color:"red"}}  underline="always">Cancel</Link>    
                    </Button>
                    <Button
                      className="savebtn"
                      variant="contained"
                      onClick={handleSaveNotes}
                      size='small'
                      style={{
                        backgroundColor: '#FFD800',
                        borderRadius: '10px',
                        color: '#3B4046'
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </div>
              </div>
            )}
          </TabPanel>

          {/* Send SMS Tab */}
          <TabPanel value="2">
            {/* <p>Send SMS functionality is under construction...</p> */}
            <SendSms
                data={applicantDetails}
                onReceivechildProps={onReceivePropsHandler}
             />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};


const MailOBJ = {
  title: "Email",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/emailbackground.svg`,
};

const CallOBJ = {
  title: "Call or Send SMS",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/CallBackground.png`,
};
const CallConnectedOBJ = {
  title: "Call or Send SMS",
  description: "",
  bgImage: ``,
};

const smsOBJ = {
  title: "Call or Send SMS",
  description: "",
  bgImage: `${process.env.PUBLIC_URL}/assests/img/emailbackground.svg`,
};