import React, { useState } from "react";
import { Card, CardContent, Switch } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import "../ApplicantsNewDashboard.css";
import CardTitle from "../CardTitle/CardTitle";

const PieChartWidgetCard = ({
  card,
  showToggle = false,
  switchChange,
  editMode,
  handleSaveCardTitle,
  handleDeleteCard,
  handleEditCard,
  handleCardTitle,
  cardTitleChangeId,
}) => {
  const data = card.data;
  const [toggle, switchToggle] = useState(false);

  return (
    <Card className="dashboard__card">
      <CardTitle
        card={card}
        editMode={editMode}
        handleSaveCardTitle={handleSaveCardTitle}
        handleDeleteCard={handleDeleteCard}
        handleEditCard={handleEditCard}
        handleCardTitle={handleCardTitle}
        cardTitleChangeId={cardTitleChangeId}
      />
      <CardContent style={{ position: "relative" }}>
        {showToggle && (
          <div style={{ textAlign: "right" }}>
            <div className="switch-container">
              <span className="text">Individual</span>
              <Switch
                checked={toggle}
                name="pieChartIndividualORTeam"
                onChange={() => {
                  const value = !toggle;
                  switchToggle(value);
                  switchChange(value);
                }}
              />
              <span className="text">Team</span>
            </div>
          </div>
        )}
        {data?.length > 0 ? (
          <PieChart
            height={310}
            colors={[
              "#9c9494",
              "#8ba3bc",
              "#9fbb7d",
              "#dcc882",
              "#e76f51",
              "#2a9d8f",
              "#c88d68",
              "#6e6a52",
              "#5c6c74",
              "#6c5444",
              "#c9c9c9",
            ]}
            series={[
              {
                data: data || [],
                innerRadius: 2.5,
                outerRadius: 150,
                paddingAngle: 0.5,
                cornerRadius: 2,
                startAngle: -180,
                endAngle: 180,
                cx: 150,
                cy: 150,
              },
            ]}
          />
        ) : (
          <div className="dashboard__card-no-data-container">
            No data available
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default React.memo(PieChartWidgetCard);
