// ringCentralUtils.js

import { platform } from "../ringcentralConfig"; // Import RingCentral SDK config

/**
 * Format a phone number to remove unwanted characters.
 * @param {string} phoneNumber - The raw phone number.
 * @returns {string} - The formatted phone number.
 */
export const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/[^0-9+]/g, ""); // Removes all non-numeric characters except '+'
};

/**
 * Check if the RingCentral app is installed and attempt to open it for a call.
 * @param {string} phoneNumber - The phone number to call.
 * @returns {Promise<boolean>} - Resolves to true if the app opens, false otherwise.
 */
export const checkRingCentralApp = (phoneNumber) => {
    return new Promise((resolve) => {
        const deepLink = `rcapp://r/call?number=${encodeURIComponent(phoneNumber)}`;
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = deepLink;
        document.body.appendChild(iframe);

        let resolved = false;

        const cleanUp = () => {
            if (iframe.parentNode) {
                iframe.parentNode.removeChild(iframe); // Safely remove the iframe if it exists
            }
            window.removeEventListener("blur", handleBlur);
        };

        const timeout = setTimeout(() => {
            if (!resolved) {
                resolved = true;
                cleanUp();
                resolve(false); // App not found
            }
        }, 2000); // Wait at least 2 seconds before deciding

        const handleBlur = () => {
            if (!resolved) {
                resolved = true;
                clearTimeout(timeout); // Prevent timeout from firing
                cleanUp();
                setTimeout(() => {
                    resolve(true); // App likely opened successfully
                }, 2000); // Minimum delay of 2 seconds before resolving true
            }
        };

        window.addEventListener("blur", handleBlur);
    });
};


/**
 * Trigger RingOut functionality for a call via the RingCentral API.
 * @param {string} toNumber - The recipient's phone number.
 * @param {string} fromNumber - Your verified RingCentral number.
 * @returns {Promise<object>} - Resolves with the RingOut API response.
 */
export const triggerRingOut = async (toNumber, fromNumber) => {
    try {
        const response = await platform().post("/restapi/v1.0/account/~/extension/~/ring-out", {
            to: { phoneNumber: toNumber },
            from: { phoneNumber: fromNumber },
            playPrompt: true,
        });
        console.log("RingOut initiated successfully:", response);
        if (!response.ok) {
            console.log(`Failed to initiate RingOut call. Status: ${response.status}`);
        }


        // Parse the JSON response
        const responseData = await response.json();
        return responseData; // Return the actual RingOut response
    } catch (error) {
        console.error("Error initiating RingOut call:", error);
        throw error;
    }
};


/**
 * Send an SMS via the RingCentral API.
 * @param {string} toNumber - The recipient's phone number.
 * @param {string} fromNumber - Your verified RingCentral number.
 * @param {string} message - The SMS message content.
 * @returns {Promise<void>} - Resolves when the SMS is sent successfully.
 */
export const sendSMS = async (toNumber, fromNumber, message) => {
    const response = await platform().post("/restapi/v1.0/account/~/extension/~/sms", {
        to: [{ phoneNumber: toNumber }],
        from: { phoneNumber: fromNumber },
        text: message,
    });

    if (!response.ok) {
        throw new Error("Failed to send SMS.");
    }
     // Parse the JSON response
     const responseData = await response.json();
     return responseData; // Return the actual RingOut response
};

/**
 * Redirect the user to the RingCentral login page.
 * @returns {void}
 */
export const redirectToLogin = () => {
    platform(); // Ensure SDK is initialized
    const currentRoute = window.location.pathname + window.location.search; // Get the current route including query params
    localStorage.setItem("previousRoute", currentRoute); // Store the current route in local storage

    const loginUrl = platform().loginUrl();
    window.location.href = loginUrl; // Redirect to RingCentral login page
};
/**
 * Check if the user is logged in to RingCentral by verifying localStorage token.
 * @returns {boolean} - True if logged in, false otherwise.
 */
export const isLoggedIntoRingCentral = () => {
    return Boolean(localStorage.getItem("rc-platform")); // Check for the presence of the token
};


export const fetchUserDetails = async () => {
    try {
        const accountResponse = await platform().get("/restapi/v1.0/account/~/");
        const userResponse = await platform().get("/restapi/v1.0/account/~/extension/~");
        const phoneNumbersResponse = await platform().get("/restapi/v1.0/account/~/extension/~/phone-number");

        const accountData = await accountResponse.json();
        const userData = await userResponse.json();
        const phoneNumbersData = await phoneNumbersResponse.json();

        console.log({ accountData });
        console.log({ userData });
        console.log({ phoneNumbersData });
        const companyMainNumber = accountData.mainNumber; // Extract company main number
        const userExtension = userData.extensionNumber; // Extract user extension
        const userDirectNumber = phoneNumbersData.records.find(
            (number) => number.usageType === "DirectNumber"
        )?.phoneNumber;
        // Store in localStorage for later use
        localStorage.setItem("ringCentralMainNumber", companyMainNumber);
        localStorage.setItem("ringCentralUserExtension", userExtension);
        localStorage.setItem("ringCentralUserDirectNumber", userDirectNumber);
    } catch (error) {
        console.error("Error fetching user details from RingCentral:", error);
    }
};

// const handleSendMessage = async (event, row) => {
//     const recipientNumber = formatPhoneNumber(row.contactNumber);
//     if (!recipientNumber) {
//         alert("recipient number is empty");
//         return;
//     }

//     try {
//         const response = await platform.post("/restapi/v1.0/account/~/extension/~/sms", {
//             to: [{ phoneNumber: recipientNumber }],
//             from: { phoneNumber: "+14372949889" }, // Replace with your verified RingCentral number
//             text: "Hello, this is a test message.",
//         });

//         const result = await response.json();
//         console.log("Message sent successfully:", result);
//     } catch (error) {
//         console.error("Error sending message:", error);
//     }
// };